
import { ThemeProvider } from '@mui/material';
import React from 'react'
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faExclamation } from '@fortawesome/free-solid-svg-icons';

export default function LimitedResourcesMessage({customTheme, togglePopUp, popupClose, id,  endPoint, setRowData,}) {
  const {removeUserApi} = useApi();
  const {t, i18n} = useTranslation();

  return (
    <>
    <div  className='containerBox' onClick={(e) =>  {popupClose(e, "limited-resources"); }}>
      <div className='lightContainer' >
        <ThemeProvider theme={customTheme} >
          <div className='p-2 text-center'>
            <div>
              <FontAwesomeIcon className='fs-1 mb-3 text-danger' icon={faCircleExclamation} />
            </div>
            <h5 className='text-center lh-base'>{t("You have reached the user limit! For quick support and to add more users to your account, please contact us at")} <a target='_blank' href="mailto:support@egarhub.com" >support@egarhub.com</a> {t("or reach out to your account manager.")}</h5>
            <h5 className='text-center'>{t("Thank you for using EgarHub.")}</h5>
          </div>
        </ThemeProvider>
      </div>
    </div>
    </>
  )
}
