import { faCircleCheck, faPenToSquare, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider} from '@mui/material';
import Button from '@mui/material/Button';
import AddOwner from '../AddOwner/AddOwner';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import ConfirmedMessage from '../ConfirmedMessage/ConfirmedMessage';
import { useApi } from '../Store/ApiContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';

export default function Owners({theme, togglePopUp, popupClose,tableContainerStyle}) {

  const {getData, confirmRemove, showSuccessRemove, setShowSuccessRemove, successMessageRemove, showErrorRemove, setShowErrorRemove, errorMessageRemove} = useApi()
  const {t, i18n} = useTranslation()

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const filterTextBox = useRef(); //  input text box

  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [numberOfRows, setNumberOfRows] = useState(20);
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('');
  const [idUserRow, setIdUserRow] = useState()
  const [userDataRow, setUserDataRow] = useState(''); // inputs owner for edit

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {headerName : "", width: "20px",
    cellRenderer: function(params) {
      ////(params.data?.main);
      if (params.data?.main) {
        return <span> 
          <FontAwesomeIcon icon={faCircleCheck} style={{color: "#3fa937",}} />
        </span>
      }
   }},
    {field: 'ownerName', filter: true, headerName : t("Owner's Name"), width:200},
    {field: 'ownerPhone', filter: true, headerName : t("Phone Number"), width:200,
    cellRenderer: function(params) {
      return <a dir="ltr" href={'tel:' + params.data.ownerPhone}>{params.data.ownerPhone}</a>
      }
    },
    {field: 'numUnites', filter: true, headerName : t("Units No."), width:150},
    {field: 'paragraph', headerName : t("Additional Information"), width:300},
    {field: 'settings', headerName : t("Settings"), width:100, 
     cellRenderer: function(params) {
      return <span> 
        <FontAwesomeIcon className='mx-2 icon-settings-table cursor-pointer fs-6' onClick={() => {togglePopUp("add-popup"); onSelectionChanged(); window.history.replaceState("","Title", "#edit-owner");}} icon={faPenToSquare} />
        <FontAwesomeIcon className='icon-settings-table cursor-pointer fs-6' icon={faTrashCan} onClick={() => confirmRemove(params.data._id, setIdUserRow)} />
      </span>
    }},
  ]);

    // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    // flex: 1,
    // minWidth: 100,
    sortable: true,
    resizable: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
    ////('cellClicked', event);
    
  }, []);

  // filter table
  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(
      e.target.value
    );
  }, []);

  // selection rows
  const onSelectionChanged = useCallback(() => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    ////(selectedRows);
    setUserDataRow(selectedRows[0])
  }, []);

   // Example load data from server
  useEffect(() => {
    // add title page
    document.title = t("Owners");
    // get data for table
    getData(setRowData, "owner");
  }, []);
  
  const handleChange = (event) => {
    setNumberOfRows(event.target.value);
  };

  
  // function confirmRemove(id) {
  //   togglePopUp('add-confirm');
  //   setIdUserRow(id)
  // }
  // async function removeUserApi(id) {
  //   const {data} = await axios({
  //     method : "patch",
  //     url : `${baseUrl}owner/edit?id=${id}`,
  //     headers : {
  //       'Content-Type': 'application/json', 
  //       "authorization" : `Bearer ${token}`,
  //     } ,
  //   })
  //   ////(data);
  //   togglePopUp('add-confirm');
  //   getData()
  // }


  return (
    <>
    <section className='bg-babyblue-50 big-card container-fluid'>
      <div className="header-card d-flex justify-content-between align-items-center">
        <div className="header-title">
          <h2 className='fw-bolder'>{t("Owners")}</h2>
        </div>
        <div onClick={() => {togglePopUp("add-popup"); window.history.replaceState("","Title", "#add-owner");}}>
          <Button variant="contained" className='btn-request'><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>{t("Add Owner")}</Button>
        </div>
      </div>
      {rowData ? <div className='table-unites pt-4 pb-3 px-2 mx-3'>

                  {/* Search table and inputs number of rows */}
        <div className="header-table mb-3 d-flex justify-content-between align-items-center">
          <ThemeProvider theme={theme}>
            <div className="inputs-number-rows">
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Entries No.")}</InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numberOfRows}
                  label={t("Entries No.")}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                  <MenuItem value={80}>80</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='header-table-search'>
              <TextField id="filter-text-box" label={t("Search...")} variant="standard" ref={filterTextBox} onInput={onFilterTextBoxChanged}  />
            </div>
          </ThemeProvider>
        </div>

        {/* Example using Grid's API */}

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={tableContainerStyle}>
          <ThemeProvider theme={theme}>

              <AgGridReact
                enableRtl={i18n.language === 'ar' ? true : false}
                ref={gridRef} // Ref for accessing Grid's API

                rowData={rowData} // Row Data for Rows

                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties

                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='multiple' // Options - allows click selection of rows

                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                paginationPageSize={numberOfRows}
                pagination={true}
                rowHeight={30}
              />
            </ThemeProvider>
         </div>

      </div> : 
        <div className='pt-4 pb-3 px-2 mx-3'>
          <div className='d-flex justify-content-between mb-2'>
            <Skeleton variant="rounded" width={"150px"} height={"50px"} style={{borderRadius : "10px"}} />
            <Skeleton variant="rounded" width={"200px"} height={"50px"} style={{borderRadius : "10px"}}/>
          </div>
          <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
        </div>}


    </section>
    {/* popup */}
    <div className='add-popup'>
      <AddOwner customTheme={theme} togglePopUp={togglePopUp} popupClose={popupClose} dataRow={userDataRow} setRowData={setRowData} setDataRow={setUserDataRow} setSuccessMessage={setSuccessMessage} setShowSuccess={setShowSuccess} />
    </div>

        {/* Success Message */}
    {/* Error Message */}
    <div>
    <ThemeProvider theme={theme}>
      <SuccessMessage message={successMessageRemove} showSuccess={showSuccessRemove} setShowSuccess={setShowSuccessRemove} />
      <ErrorMessage message={errorMessageRemove} showError={showErrorRemove} setShowError={setShowErrorRemove}/>
    </ThemeProvider>
    </div>

    <div className='add-confirm'>
      <ConfirmedMessage customTheme={theme} togglePopUp={togglePopUp} popupClose={popupClose} id={idUserRow} endPoint={"owner"} setRowData={setRowData}/>
    </div>
  
  </>
  )
}
