import { ThemeProvider, Box, Chip, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback,  useEffect, useMemo, useRef, useState } from 'react'
import $ from 'jquery';
import moment from 'moment';
import Button from '@mui/material/Button';
import joi from 'joi';
import { useApi, } from '../Store/ApiContext';
import { enqueueSnackbar } from 'notistack';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, percentageValidationMessage, validationErrorMessage } from '../../constants/global';
import { useTranslation } from 'react-i18next';


export default function AddCost({customTheme, togglePopUp, popupClose, costDataRow, setCostDataRow, setSuccessMessage, setShowSuccess, setRowData}) {

  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();

  const [costItem, setCostItem] = useState('');
  const [transaction, setTransaction] = useState('');
  
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // const filterTextBox = useRef(); //  input text box
  const [rowDataUnitAndCosts, setRowDataUnitAndCosts] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [unitResponse, setUnitResponse] = useState([]);
  const [units, setUnits] = useState([]);
  const [date, setDate] = useState(moment().utc(true).set({hour:0,minute:0,second:0,millisecond:0}));
  const [costItems, setCostItems] = useState([]);
  const [newCostItem, setNewCostItem] = useState(false)
  const [costData, setCostData] = useState({
    transDate : date.format(),
    transType : "",
    transItem : '',
    transTotalCost : "",
    loadedUnits : [],
  });


  // const handleDateChange = (date) => {   // react dates
  //   setDate(date);
  // };

  const handleChangeSet = (event, set) => {
    set(event.target.value);
  };

  const numberParser = (params) => {
    return Number(params.newValue);
  };

  // select chip dropdown checkboxes

  const [unitsName, setUnitsName] = useState([]);
  const [data, setData] = useState([])

  const handleChangeChip = (event) => {
    const {
      target: { value },
    } = event;
    setUnitsName(
      // On autofill we get a stringified value.
      value
    );
  };

  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    disableScrollLock: true
  };

  // function getStyles(name, unitsName, theme) {
  //   return {
  //     fontWeight:
  //       unitsName.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium,
  //   };
  // }

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {field: 'label', width: 250, headerName : t("Unit Name")},
    {field: 'percentage', headerName : t("Percentage"), editable: true, width: 100, valueParser : numberParser,},
  ]);

    // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    // innerWidth : "50",
    // outerWidth : "50",
    sortable: true,
    // filter: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
    ////('cellClicked', event);
    ////(gridRef);
  }, []);

  function tableUnitesAndCosts() {
    if (unitsName.length) {
      ////(unitsName);
      let strData = {}
      setData([])
      units.forEach(ele1 => {
        unitsName.forEach((ele2) => {
          if (ele1.label === ele2) {
            strData = {
              label : ele2,
              percentage : (100 / unitsName.length).toFixed(2),
              unit : ele1.id,
            }
            data.push(strData)
          }
        })
      })
      ////(data);
      setRowDataUnitAndCosts(data)
      $(".table-pre").show()
    }else {
      $(".table-pre").hide()
    }
  }
    
  //  handle data for grid array
  useEffect(() => {
    if (unitsName) {
      tableUnitesAndCosts()
    }
  }, [unitsName]);

  function handleChange(e, id, newValue) {
    // deep copy
    let myData;
    costDataRow ? myData = {...costDataRow} : myData = {...costData};
           // update data
      if (id === "transDate") {
        myData[id] = newValue.utc(true).set({hour:0,minute:0,second:0,millisecond:0}).format();
      }else if (id === "loadedUnits") {
        myData[id] = e.map((ele) => { return {unit  : ele.unit, percentage : ele.percentage, label : ele.label }});
      } else if (id === "transType" || id === "transItem") {
        myData[id] = e.target.value;
      } else {
        myData[e.target.id] = e.target.value;
      }
      console.log(myData);
      // set new data
      costDataRow ? setCostDataRow(myData) : setCostData(myData);
  }

  function getOptions(myData, setData) {
    const options = myData.map((ele) => {
      return (
        {
          label : ele.unitCode + " - " + ele.unitName ,
          id : ele._id,
        }
      ) 
    })
    setData(options);
  };
  
  function clearInputsDataRow() {
    if (costDataRow) {
      setCostDataRow('');
    }else {
      setCostData({
        transDate : date.format(),
        transType : "",
        transItem : '',
        transTotalCost : '',
        loadedUnits : [],
      })
      setUnitsName([]);
      setRowDataUnitAndCosts('')
    }
  }

  async function addNewCostItem() {
    const myData = {
      costItemName : costDataRow ? costDataRow.transItem : costData.transItem
    }
    const res = await responseApi("post", myData, "costItem");
    console.log(res.message);
    return res.message;
  }
  
  function validationReqData(newData) {
    const schema = joi.object().required().keys({
      transDate : joi.date().required(),
      transType : joi.string().required(),
      transItem : joi.string().min(2).required(),
      transTotalCost : joi.number().min(1).required(),
      loadedUnits :joi.array().min(1).required(),
    });
    return  schema.validate(newData, {abortEarly : false})
  }
  
  async function addCostData() {
    const validationData = validationReqData(costData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      let totalPercentage = 0
      costData.loadedUnits.forEach(ele => {
        totalPercentage += parseFloat(ele.percentage);
      });
      if (Math.round(totalPercentage) !== 100) {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{percentageValidationMessage}</>, {variant : "error"});
        return 0;
      }
      if (newCostItem) {
        const resAddNewCostItem = await addNewCostItem() 
        if (resAddNewCostItem !== "success") {
          enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{resAddNewCostItem}</>, {variant : "error"});
          return 0
        }else {
          getData(setCostItems, "costItem");
        }
      }
      const res = await responseApi('post', costData, "cost", costDataRow);
      console.log(res);
      if (res.message === "success") {
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "cost");
        clearInputsDataRow();
        setNewCostItem(false);
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details);
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([]);
  }
  async function editCostData() {
    const newData = {
      transDate : costDataRow.transDate,
      transType : costDataRow.transType,
      transItem : costDataRow.transItem,
      transTotalCost : costDataRow.transTotalCost,
      loadedUnits : costDataRow.loadedUnits,
    }
    const validationData = validationReqData(newData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      let totalPercentage = 0
      costDataRow.loadedUnits.forEach(ele => {
        totalPercentage += parseFloat(ele.percentage);
      });
      if (Math.round(totalPercentage) !== 100) {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{percentageValidationMessage}</>, {variant : "error"});
        return 0;
      }
      if (newCostItem) {
        const resAddNewCostItem = await addNewCostItem()
        if (resAddNewCostItem !== "success") {
          enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{resAddNewCostItem}</>, {variant : "error"});
          return 0
        }else {
          getData(setCostItems, "costItem");
        }
      }
      const res = await responseApi('put', newData, "cost", costDataRow._id );
      if (res.message === "success") {
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "cost");
        clearInputsDataRow();
        setNewCostItem(false);
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }

  function cellValueChanged() {
    let allRowData = []
    if (unitsName.length || !costDataRow?.loadedUnits?.length === 0) {
      gridRef?.current?.api?.forEachNode(node => allRowData.push(node.data));
    }
    ////(allRowData);
    handleChange(allRowData, "loadedUnits")
   }

  useEffect(() => {
    getData(setUnitResponse, "unit");
    getData(setCostItems, "costItem");
  }, []);
  

  useEffect(() => {
    if (unitResponse) {
      getOptions(unitResponse, setUnits)
    }
  }, [unitResponse]);

   useEffect(() => {
      cellValueChanged()
   }, [rowDataUnitAndCosts, unitsName])

   useEffect(() => {
    if (costDataRow) {
      $(".table-pre").show()
    }
   }, [costDataRow])
   
   
  
  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
          $(".table-pre").hide();
          setNewCostItem(false);
        }
      }}>
      <ThemeProvider theme={customTheme} >
        {units ? <div className='lightContainer scrollbar-popup-style' >
          <ul className='row'>
              <li className="col-md-6">
                <div className='p-2 w-100' dir={t("dir")}>
                
                  {/* <SingleDatePicker
                    isOutsideRange={() => {}}
                    isRTL={i18n.language === "ar" ? true : false }
                    placeholder="تاريخ التنفيذ"
                    
                    date={date} 
                    onDateChange={date => handleDateChange(date)}
                    focused={focusedInputValue}
                    onFocusChange={(focusedInput) => setFocusedInputValue(focusedInput.focused)}
                    hideKeyboardShortcutsPanel
                    showClearDate
                    numberOfMonths={1}
                    showDefaultInputIcon
                    inputIconPosition="after"
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            id : "transDate",
                            label: t("Applied Date"),
                            variant: "standard",
                            style : {
                              width : "100%",
                            },
                          },
                        }}
                        format='DD/MM/YYYY'
                        value={costDataRow ? moment(costDataRow.transDate) : date}
                        onChange={(newValue) => {setDate(newValue); handleChange('', "transDate", newValue) }}
                        dayOfWeekFormatter={(day) => day}
                      />
                  </LocalizationProvider>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the cost applied date.")}</div>
              </li>
              <li className="col-md-6">
                <div className='p-2 d-flex '>
                  <FormControl  className='textfield-input' variant="standard" >
                    <InputLabel id="demo-simple-select-standard-label">{t("Transaction Type")}</InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-standard-label"
                      id="transType"
                      value={costDataRow ? costDataRow?.transType : costData.transType}
                      onChange={(e) =>{ handleChangeSet(e, setTransaction); handleChange(e, "transType")}}
                    >
                      <MenuItem value={"Inputs"}>{t("Inputs")}</MenuItem>
                      <MenuItem value={"Expenses"}>{t("Expenses")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the transaction type.")}</div>
              </li>
            {newCostItem ? <>
              <li className="col-12">
                <div className='p-2'>
                  <TextField id="transItem" className='textfield-input' label={t("New Item Name")} onChange={(e) => handleChange(e, "transItem")} value={costDataRow ? costDataRow?.transItem : costData.transItem} variant="standard"  type="text" />
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the cost item.")}</div>
              </li>
          </> : <>
          <li className="col-12">
                <div className='p-2 d-flex '>
                  <FormControl className='textfield-input' variant="standard" >
                    <InputLabel id="demo-simple-select-standard-label">{t("Cost Item")}</InputLabel>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      labelId="demo-simple-select-standard-label"
                      id="transItem"
                      value={costDataRow ? costDataRow?.transItem : costData.transItem }
                      onChange={(e) => {handleChangeSet(e, setCostItem); handleChange(e, "transItem")}}
                    >
                      <MenuItem value={"كهرباء"}>كهرباء</MenuItem>
                      <MenuItem value={"مياه"}>مياه</MenuItem>
                      <MenuItem value={"إيجار"}>إيجار</MenuItem>
                      <MenuItem value={"مشتريات"}>مشتريات</MenuItem>
                      <MenuItem value={"اجور"}>اجور</MenuItem>
                      <MenuItem value={"اخرى"}>اخرى</MenuItem>
                      {costItems?.map((ele) => <MenuItem value={ele.costItemName}>{ele.costItemName}</MenuItem>)}
                      <MenuItem  onClick={() => setNewCostItem(true)}>{t("New Item")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the cost item.")}</div>
              </li></>}
            <li className="col-12">
              <div className='p-2'>
                {/* <TextField className='textfield-input' label="القيمة الإجمالية" variant="standard"  type="text" /> */}
                <FormControl fullWidth  variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">{t("Total Cost")}</InputLabel>
                  <Input
                    type='number'
                    inputProps={{ min : 0 }}
                    id="transTotalCost"
                    value={costDataRow?.transTotalCost ? costDataRow.transTotalCost : costData.transTotalCost}
                    onChange={handleChange}
                    endAdornment={<InputAdornment position="end"> {t("EGP")} </InputAdornment>}
                  />
                </FormControl>
              </div> 
              <div className="text-helper-invalid d-none">{t("Please enter the total cost.")}</div> 
            </li>
            <li className="col-12">
              {!costDataRow ?<div className='p-2 w-100'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">{t("Units")}</InputLabel>
                    <Select
                      label={t("Units")}
                      MenuProps={MenuProps}
                      labelId="demo-multiple-chip-label"
                      id="loadedUnits"
                      multiple
                      value={unitsName}
                      onChange={(e) => {handleChangeChip(e);}}
                      input={<Input id="select-multiple-chip"  />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width : "100%", top : "611px" }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value}  style={{top : "611px"}}/>
                          ))}
                        </Box>
                      )}
                    >
                      {units?.map((ele) => (
                        <MenuItem
                          key={ele.id}
                          value={ele.label}
                          // style={getStyles(ele.label, unitsName,customTheme)}
                        >
                          {ele.label}
                        </MenuItem>
                      ))}
                    </Select>
                    

                  </FormControl>
              </div> : <></>}
              <div className="text-helper-invalid d-none">{t("Please select at least one unit.")}</div> 
            </li>
            <li className="col-12 mt-3 table-pre">
             {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
              <div className="ag-theme-alpine-Addcost ag-root-wrapper-Addcost" style={{width: "350px", height: "25vh", margin : "auto",}}>

                <AgGridReact
                    enableRtl={i18n.language === 'ar' ? true : false}
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={costDataRow?.loadedUnits ? costDataRow?.loadedUnits : rowDataUnitAndCosts} // Row Data for Rows

                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties

                    // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    onCellValueChanged={cellValueChanged}

                    />
                </div>
                
            </li>       
          </ul>
         
          <div className='text-start'>
            <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() => {togglePopUp("add-popup"); clearInputsDataRow(); showInvalidFeedback([]); $(".table-pre").hide(); setNewCostItem(false);}}>{t("Back")}</Button>
            <Button variant="contained" className='btn-myPrimary' onClick={costDataRow ? editCostData : addCostData}>{t("Confirm")}</Button>
          </div>
         

      </div> : <div className='lightContainer' style={{backgroundColor : "transparent"}}>
            <Skeleton variant="rounded" width={"100%"} height={"400px"} style={{borderRadius : "10px"}}/>
          </div>}
    </ThemeProvider>
  </div>
    </>
  )
}
