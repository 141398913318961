import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Skeleton,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useApi } from "../Store/ApiContext";
import moment from "moment";
import axios from "axios";
import ConvertToPDF from "../ConvertToPDF/ConvertToPDF";
import ConvertToXLSX from "../ConvertToXLSX/ConvertToXLSX";
import { useTranslation } from "react-i18next";

export default function ReportUnitCost({
  baseUrl,
  token,
  theme,
  tableContainerStyle,
}) {
  const {
    getData,
  } = useApi();
  const {t, i18n} = useTranslation();


  const targetRef = useRef();

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const filterTextBox = useRef(); //  input text box

  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [numberOfRows, setNumberOfRows] = useState(20);
  const [costsItem, setCostsItem] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [units, setUnits] = useState([]);
  const [unitResponse, setUnitResponse] = useState(null);
  const [unitId, setUnitId] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [unitInputValue, setUnitInputValue] = useState("");
  const [reloadResponse, setReloadResponse] = useState(true)
  const [downloadPDF, setDownloadPDF] = useState(false)

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "transCode",
      headerName: t("Trans Number"),
      width: 150,
    },
    {
      field: "transType",
      headerName: t("Trans Nature"),
      width: 150,
      cellRenderer: function (params) {
        return params.data.transType;
      },
    },
    {
      field: "transItem",
      headerName: t("Trans Type"),
      width: 150,
    },
    {
      field: "transTotalCost",
      headerName: t("Trans Value"),
      width: 150,
    },
    {
      field: "transDate",
      headerName: t("Execution Date"),
      width: 150,
    },
    {
      field: "createdAt",
      headerName: t("Date Added"),
      width: 150,
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    minWidth: 100,
    sortable: true,
    // filter: true,
    resizable: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    ////('cellClicked', event);
  }, []);

  // selection rows
  // const onSelectionChanged = useCallback(() => {
  //   let selectedRows = gridRef.current.api.getSelectedRows();
  //   ////(gridRef);
  //   // ////(selectedRows);
  //   // setCostDataRow(selectedRows[0])
  // }, []);

  // filter table
  // const onFilterTextBoxChanged = useCallback((e) => {
  //   gridRef.current.api.setQuickFilter(e.target.value);
  // }, []);

  // const handleChange = (event) => {
  //   setNumberOfRows(event.target.value);
  // };

  function getOptionUnits(myData, setData) {
    // const data = getData(setClientData, "client");
    // console.log(myData);
    const options = myData.map((ele) => {
      return {
        label: ele.unitCode + " - " + ele.unitName,
        id: ele._id,
      };
    });
    // console.log(options);
    setData(options);
  }

  function getCostsItem(data, totalRequestsCost, totalTasksCost) {
    let itemsArray = [];

    // if (totalRequestsCost) {
    //   itemsArray.push({
    //     transItem: "الإيجارات",
    //     costIn: totalRequestsCost,
    //     costOut: 0,
    //   });
    // }
    // if (totalTasksCost) {
    //   itemsArray.push({
    //     transItem: "المهام",
    //     costIn: 0,
    //     costOut: -totalTasksCost,
    //   });
    // }
    ////(data);

    data.costs.forEach((cost) => {
      // console.log(cost);
      let item = itemsArray.find(
        (t) => t.transItem === cost.transItem && t.transType === cost.transType
      );
      let unitInfo = cost.loadedUnits?.find((t) => t.unit === unitId);
      // console.log(unitInfo?.percentage);
      if (!item) {
        if (cost.transType === "Inputs") {
          itemsArray.push({
            transItem: cost.transItem,
            transType: "Inputs",
            cost: (cost.transTotalCost * unitInfo?.percentage) / 100,
          });
        } else {
          itemsArray.push({
            transItem: cost.transItem,
            transType: "Expenses",
            cost: (cost.transTotalCost * unitInfo?.percentage) / -100,
          });
        }
      } else {
        if (cost.transType === "Expenses") {
          item.cost -= (cost.transTotalCost * unitInfo?.percentage) / 100;
        } else if (cost.transType === "Inputs") {
          item.cost += (cost.transTotalCost * unitInfo?.percentage) / 100;
        }
      }
    });

    if (data?.requests) {
      itemsArray.sort(function (a, b) {
        return a.transItem.localeCompare(b.transItem);
      })
      var requestTotalCost = 0;
      data.requests.forEach((ele) => {
        requestTotalCost += ele.reqTotalCost;
      });
      itemsArray.unshift({
        transItem: t("Orders"),
        transType: "Inputs",
        cost: requestTotalCost,
      });
    }

    let totalCost = 0;
    itemsArray.forEach((cost) => {
      if (cost.transType === "Expenses") {
        totalCost += cost.cost;
      } else if (cost.transType === "Inputs") {
        totalCost += cost.cost;
      }
    });

    setCostsItem(itemsArray);
    setTotalCost(totalCost);
    // console.log(itemsArray);
    
  }

  function getPercentageUnitCost(costs) {
    // console.log(costs);
    // console.log(unitId);
    costs.forEach(cost => {
      let unit = cost.loadedUnits.find(
        (t) => t.unit === unitId);
      // console.log(unit);
      cost.percentage = unit.percentage
    });
    // console.log(costs);
    return costs;
  }

  async function getResponse() {
    const { data } = await axios({
      method: "get",
      url: `${baseUrl}cost/${unitId}`,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const allData = mergeCostsAndRequests(data.data.costs, data.data.requests)
    setRowData(generateArrayOfDataToDisplay(allData));
    getCostsItem(data.data);
    setReloadResponse(false)
  }

  function mergeCostsAndRequests(allCosts, requests) {
    const costs = getPercentageUnitCost(allCosts)
    return [...costs, ...requests].sort(function (a, b) {
      return a.createdAt < b.createdAt ? -1 : 1;
    });
  }

  function generateArrayOfDataToDisplay(data) {
    let myArray = []
    data.forEach((ele) => {
      myArray.push(
        {
          transCode : ele.transCode ? ele.transCode : ele.reqCode,
          transType : ele.transType ? t(ele.transType) : t("Inputs"),
          transItem : ele.transItem ? ele.transItem : t("Request") + " - " + ele.reqNights + t("Days"),
          transTotalCost : (ele.transTotalCost ? (ele.transTotalCost * ele.percentage / 100).toFixed(2) : ele.reqTotalCost).toString(),
          transDate : ele.transDate ? moment(ele.transDate).format("L")  : moment(ele.reqFrom).format("L"),
          createdAt :  moment(ele.createdAt).format("L")
        }
      )
    })
    // console.log(myArray);
    return myArray
  }


  useEffect(() => {
    // add title page
    document.title = t("Financial Units Report");
    // unit list
    getData(setUnitResponse, "unit");
  }, []);

  useEffect(() => {
    if (unitResponse) {
      getOptionUnits(unitResponse, setUnits);
    }
  }, [unitResponse]);

  useEffect(() => {
    if (unitId) {
      setReloadResponse(true)
      // get data for table
      getResponse();
      
      // rowData()
    }
  }, [unitId]);

  // useEffect(() => {
  //   if (rowData) {
      // console.log(rowData);
  //     getCostsItem(rowData)
  //   }
  // }, [rowData])

  return (
    <>
      <section  className="bg-babyblue-50 big-card container-fluid">
        <div className="header-card d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h2 className="fw-bolder">{t("Financial Units Report")}</h2>
          </div>
          {/* <div onClick={() => {togglePopUp("add-popup"); window.history.replaceState("","Title", "#add-cost");}}>
          <Button variant="contained" className='btn-request' ><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>اضافة تكلفة</Button>
        </div> */}
        </div>
        <div className="unit-list row mx-3 ">
          <div className="col-md-6 col-lg-7">
            {unitResponse ? (
              <div className="px-2 pt-2">
                <Autocomplete
                  value={unitValue}
                  onChange={(event, newValue) => {
                    setUnitValue(newValue);
                    setUnitId(newValue.id);
                    // handleChange(event, "unit", newValue);
                    console.log(newValue);
                    // setInfoUnit(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.label === value?.label
                  }
                  inputValue={unitInputValue}
                  onInputChange={(event, newInputValue) => {
                    setUnitInputValue(newInputValue);
                  }}
                  id="unit"
                  disableClearable
                  options={units}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      label={t("Unit")}
                      variant="standard"
                      className="textfield-input"
                      {...params}
                    />
                  )}
                />
                <div className="text-helper-invalid d-none">
                  {t("Please select the desired unit.")}
                </div>
              </div>
            ) : (
              <>
                <div className="pt-4 pb-3 px-2 mx-3">
                  <div className="d-flex justify-content-between mb-2">
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"60px"}
                      style={{ borderRadius: "20px" }}
                    />
                    {/* <Skeleton variant="rounded" width={"200px"} height={"50px"} style={{borderRadius : "10px"}}/> */}
                  </div>
                  {/* <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/> */}
                </div>
              </>
            )}
          </div>
          <div className="col-md-6 col-lg-5  mt-auto d-flex justify-content-end pt-2 ">
            {rowData?.length ? <>
              <ConvertToPDF rowData={rowData} unit={unitValue.label} costsItem={costsItem} totalCost={totalCost}/>
              <ConvertToXLSX rowData={rowData}/>
            </> : <></> }
            
          </div>
        </div>
        {rowData && !reloadResponse ?  (
          <div  className="table-unites pt-4 pb-3 px-2 mx-3">
            {/* Search table and inputs number of rows */}
            <div ref={targetRef} className="row g-4 flex-md-row flex-column-reverse">
              <div className="col-md-8">
                {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
                <div className="ag-theme-alpine" style={{width: "100%",height: downloadPDF ? (30 * rowData.length) + 66 : "65vh" ,borderRadius : "5px",}}>
                  <AgGridReact
                    enableRtl={i18n.language === 'ar' ? true : false}
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                    onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    // onSelectionChanged={onSelectionChanged} // get row selection
                    paginationPageSize={numberOfRows}
                    // pagination={true}
                    rowHeight={30}
                  />
                </div>
              </div>
              <div className="report-cost-card col-md-4">
                <div className="p-3">
                  <ul>
                    {costsItem
                      .map((ele, i) => (
                        <>
                          <li
                            key={i}
                            className={ele.transType === "Expenses" ? "d-flex justify-content-between my-2 negative-color" : "d-flex justify-content-between my-2 positive-color"}
                          >
                            <p>
                              <span className={ele.transType === "Expenses" ? "negative-color" : "positive-color"}>{ele.transItem}</span> - {t(ele.transType)}
                            </p>
                            <p dir="ltr">
                              {ele.transType === "Expenses" ? '' : "+"}
                              {parseFloat(ele.cost.toFixed(2)).toLocaleString()}
                            </p>
                          </li>
                        </>
                      ))}
                    <hr className="line-report my-3" />
                    <li className="d-flex justify-content-between my-2 fs-18">
                      <p className="fw-semibold">{t("Total Cost")}</p>
                      <p dir={"ltr"} className={ totalCost < -1 ? "negative-color fw-semibold " : "positive-color fw-semibold"}>
                        {parseFloat(totalCost.toFixed(2)).toLocaleString()}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : !unitId ? <></> : (
          <>
            <div className="table-unites pt-4 pb-3 px-2 mx-3">
              {/* Search table and inputs number of rows */}
              <div className="header-table mb-3 d-flex justify-content-between align-items-center"></div>
              <div className="row g-4">
                <div className="col-md-8">
                   <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
                </div>
                <div className="col-md-4">
                  <div className="">
                     <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!unitId && !rowData ? (
          <>
            <div className="h-100 text-center mt-5">
             {t("Select a unit to display associated costs")}
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}

//<div className='pt-4 pb-3 px-2 mx-3'>
//<div className='d-flex justify-content-between mb-2'>
//<Skeleton variant="rounded" width={"150px"} height={"50px"} style={{borderRadius : "10px"}} />
// {/* <Skeleton variant="rounded" width={"200px"} height={"50px"} style={{borderRadius : "10px"}}/> */}
//</div>
//<Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
//</div>
