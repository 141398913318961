import { faCalendar, faFileCode, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBuildingUn, faCaretDown,faGaugeHigh, faChartLine, faCodePullRequest, faEllipsisVertical, faListCheck, faMoneyBill, faMoneyBillTransfer, faMoneyBills, faSliders,faBuildingUser ,faUserGear,faUserCheck,faPowerOff} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import { Link, Nav, NavLink, NavLinkLinkNavLink, useNavigate, } from 'react-router-dom';
import logo from '../../img/logo.png';
import NavBar from '../NavBar/NavBar';
import {useTranslation} from 'react-i18next';



export default function  MainMenu({lastPathname, decodeToken, setToken, organizationAuthorization, userAuthorization}) {

  const {t, i18n} = useTranslation()

  const [userName, setUserName] = useState('');

  const navigate = useNavigate();

  function ProtectedRouteRolesAndPlans(props) { // shield for all components
    if (localStorage.getItem("token")) {
      // const decoded = decodeToken();
      if (props.plans?.filter((ele) => ele === organizationAuthorization.plan.planName ).length || organizationAuthorization.plan.planName === "pro") {
        for (let i = 0; i < userAuthorization?.role?.length; i++) {
          if (props.roles.filter((ele) => ele === userAuthorization.role[i] ).length) {
            return props.children 
          }
        }
        return <></>
      }
    }
  } 

  function dropdownList() {
    $(".dropdown-list").toggle();
    // $(".arrowListReports").css("transform", "rotate(180deg)");
  }

  function hideMainMenu(e) {
    // $(".main-menu-mobile").removeClass(" animate__faster d-inline-block " + i18n.language === "en" ? "animate__slideInLeft" : "animate__slideInRight");
    // $(".main-menu-mobile").addClass(" animate__faster d-inline-block " + i18n.language === "en" ? "animate__slideOutLeft" : "animate__slideOutRight");
    $(".main-menu-mobile").removeClass(i18n.language === "ar" ? " animate__animated animate__faster animate__slideInRight d-inline-block " : " animate__animated animate__faster animate__slideInLeft d-inline-block ");
    $(".main-menu-mobile").addClass( i18n.language === "ar" ? " animate__animated animate__faster animate__slideOutRight d-inline-block " : " animate__animated animate__faster animate__slideOutLeft d-inline-block ");
    $(".layer-mobile").addClass("d-none");

  }



  const mainMenuData = <div className='title-list scrollbar-style'>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin',]}><NavLink  data-name-route="dashboard" className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={'/dashboard'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faGaugeHigh} />{t("Home")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><NavLink data-name-route='calender' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={'/renting-timeline'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu'  icon={faCalendar} />{t("Renting Timeline")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Unit Manager"]}><NavLink data-name-route='unites' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/units"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faBuildingUn} />{t("Units")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Follow-up Manager"]}><NavLink data-name-route='requests' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/requests"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faCodePullRequest} />{t("Requests")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Cost Manager"]}><NavLink data-name-route='costs' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"}  to={"/costs"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faMoneyBill} />{t("Cost")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Task Manager"]}><NavLink data-name-route='tasks' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/tasks"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faListCheck} />{t("Tasks")}</NavLink></ProtectedRouteRolesAndPlans>
    <li className='report'> 
      <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}>
        <div data-name-route="report" className='report dropdown-btn d-flex justify-content-between title-main-menu' onClick={dropdownList}>
          <div>
            <FontAwesomeIcon className='icon-main-menu' icon={faFileCode} />
            {t("Reports")}  
          </div>

          <div className=' me-3' >
            <FontAwesomeIcon className='arrowListReports' icon={faCaretDown} />
          </div>
        </div>
      </ProtectedRouteRolesAndPlans>
      <div className='dropdown-list mb-4'>
        <NavLink to={"/report/unit-cost"} className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBills} />{t("Financial Units Report")}</NavLink>
      {/* <NavLink to={"/report/all-cost"} className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBillTransfer} />تقرير التكلفة المجمع</NavLink> */}
      </div>
    </li>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><NavLink data-name-route='owners' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/owners"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faBuildingUser} />{t("Owners")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><NavLink data-name-route='clients' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/clients"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faUserCheck} />{t('Customers')}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><NavLink data-name-route='users' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/users"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faUserGear} />{t("Users")}</NavLink></ProtectedRouteRolesAndPlans>
    <ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><NavLink data-name-route='settings' className={({ isActive, isPending }) => isActive ? "active-title-main-menu title-main-menu" : "title-main-menu"} to={"/settings"} onClick={hideMainMenu}><FontAwesomeIcon  className='icon-main-menu' icon={faSliders} />{t("Settings")}</NavLink></ProtectedRouteRolesAndPlans>

  </div>;



//   <NavNavLink to="/" style={({ isActive }) => ({ 
//     color: isActive ? 'greenyellow' : 'white' })}>
//     Home
// </NavNavLink>

// const mainMenuData =       <ul className='title-list mt-3'>
// <li  className='dashboard title-main-menu '><NavLink data-name-route="dashboard" to={'/dashboard'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faChartLine} />t(الرئيسية)</NavLink></li>
// <li  className='calender title-main-menu '><NavLink data-name-route='calender' to={'/calender'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu'  icon={faCalendar} />تقويم الإيجارات</NavLink></li>
// <li  className='unites title-main-menu '><NavLink data-name-route='unites' to={"/units"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faBuildingUn} />الوحدات</NavLink></li>
// <li  className='requests title-main-menu '><NavLink data-name-route='requests' to={"/requests"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faCodePullRequest} />الطلبات</NavLink></li>
// <li  className='costs title-main-menu '><NavLink data-name-route='costs'  to={"/costs"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faMoneyBill} />التكاليف</NavLink></li>
// <li  className='tasks title-main-menu '><NavLink data-name-route='tasks' to={"/tasks"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faListCheck} />المهام</NavLink></li>
// <li className='report'> 
  
  
//     <div data-name-route="report" className='report dropdown-btn d-flex justify-content-between title-main-menu' onClick={dropdownList}>
//       <div>
//         <FontAwesomeIcon className='icon-main-menu' icon={faFileCode} />
//         التقارير  
//       </div>
      
//       <div className=' me-3' >
//         <FontAwesomeIcon icon={faCaretDown} />
//       </div>
//     </div>
  
//   <ul className='dropdown-list mb-4'>
//     <li  className='unit-cost sub-title-main-menu '><NavLink to={"/report/unit-cost"}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBills} />تقرير تكلفة الوحدة</NavLink></li>
//   <li  className='all-cost sub-title-main-menu '><NavLink to={"/report/all-cost"}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBillTransfer} />تقرير التكلفة المجمع</NavLink></li>
//   </ul>
// </li>
// <li className='owners title-main-menu '> <NavLink data-name-route='owners' to={"/owners"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />الملاك</NavLink></li>
// <li className='clients title-main-menu '> <NavLink data-name-route='clients' to={"/clients"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />العملاء</NavLink></li>
// <li className='users title-main-menu '> <NavLink data-name-route='users' to={"/users"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />المستخدمين</NavLink></li>
// <li className='settings title-main-menu '> <NavLink data-name-route='settings' to={"/settings"} onClick={hideMainMenu}><FontAwesomeIcon  className='icon-main-menu' icon={faSliders} />الإعدادات</NavLink></li>

// </ul>;

    // function locationUrl() {
    //   // ////(location.pathname);
    //   let pathnameUrl = location.pathname.split('/')
    //   let fullPathname = ""
      
    //   if (pathnameUrl.length > 2) {
    //       fullPathname = pathnameUrl[pathnameUrl.length - 1];
    //       ////(fullPathname); 
    //     $(`#${fullPathname}`).addClass('active-title-main-menu');
    //     lastPathname ? $(`#${lastPathname}`).removeClass('active-title-main-menu') : <></>
  
    //   }else if(pathnameUrl.length === 2) {
    //     fullPathname = pathnameUrl[1] ? pathnameUrl[1] : "dashboard"
    //     $(`#${fullPathname}`).addClass('active-title-main-menu');
    //     lastPathname ? $(`#${lastPathname}`).removeClass('active-title-main-menu') : <></>
    //   }
    //   setLastPathname(fullPathname)    
    // }

    function logout() {
      localStorage.clear()
      navigate("/login")
      setToken("");
    }

    useEffect(() => {
      $(".dropdown-list").hide();
      ////(localStorage.getItem('userName'));
      setUserName(localStorage.getItem('userName'))
    }, [])
    
  return (
    <>
    <></>
    {localStorage.getItem("token") ? <><section className='layer'>
      <div className='main-menu'>
        <div className='logo-main-menu d-none d-xl-flex justify-content-center'>
          <Link to={"/dashboard"}>
            <img className='w-100 px-3' src={logo} alt="logo" />
          </Link>
        </div>
        {/* <div className='logo-main-menu d-flex justify-content-center d-xl-none' >
          <img className='w-75 h-75' src={logo} alt="logo" />
          <div className='my-auto ms-3 menu-icon '>
            <FontAwesomeIcon className='text-mySecondry fs-5' icon={faEllipsisVertical} />
          </div>
        </div> */}
        {mainMenuData}
        <hr className='m-2'/>
        <div className='footer-logout d-flex justify-content-between my-1'>
          {/* <div className=''>
            <FontAwesomeIcon icon={faUser} />
          </div> */}
          <div >
            <FontAwesomeIcon className='icon-main-menu' icon={faUser} />
            {userName?.length > 22 ? userName?.slice(0,22) : userName}
            </div>
          <div className='ms-2 cursor-pointer logout-icon' onClick={logout}>
            <FontAwesomeIcon icon={faPowerOff} />
          </div>
        </div>
      </div>
    </section>

    <section className='layer'>
      <div className='main-menu main-menu-mobile d-xl-none'>
        <div className='logo-main-menu d-flex justify-content-center' onClick={hideMainMenu}>
          <Link to={"/dashboard"}>
            <img className='w-100 px-3' src={logo} alt="logo" />
          </Link>
          <div className='my-auto ms-3 menu-icon '>
            <FontAwesomeIcon className='text-mySecondry fs-5' icon={faEllipsisVertical} />
          </div>
        </div>
        {mainMenuData}
          <hr className='m-2'/>
        <div className='footer-logout d-flex justify-content-between my-1'>
          {/* <div className=''>
            <FontAwesomeIcon icon={faUser} />
          </div> */}
          <div >
            <FontAwesomeIcon className='icon-main-menu' icon={faUser} />
            {userName?.length > 22 ? userName?.slice(0,22) : userName}
            </div>
          <div className='ms-2 cursor-pointer logout-icon' onClick={logout}>
            <FontAwesomeIcon icon={faPowerOff} />
          </div>
        </div>
        {/* <ul className='title-list mt-3'>
              <li  className='dashboard title-main-menu '><ProtectedRouteRolesAndPlans roles={['General Manager','System Admin',]}><NavLink data-name-route="dashboard" to={'/dashboard'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faChartLine} />t(الرئيسية)</NavLink></ProtectedRouteRolesAndPlans></li>
              <li  className='calender title-main-menu '><ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><NavLink data-name-route='calender' to={'/calender'} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu'  icon={faCalendar} />تقويم الإيجارات</NavLink></ProtectedRouteRolesAndPlans></li>
              <li  className='unites title-main-menu '><ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Unit Manager"]}><NavLink data-name-route='unites' to={"/units"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faBuildingUn} />الوحدات</NavLink></ProtectedRouteRolesAndPlans></li>
              <li  className='requests title-main-menu '><ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><NavLink data-name-route='requests' to={"/requests"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faCodePullRequest} />الطلبات</NavLink></ProtectedRouteRolesAndPlans></li>
              <li  className='costs title-main-menu '><ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><NavLink data-name-route='costs'  to={"/costs"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faMoneyBill} />التكاليف</NavLink></ProtectedRouteRolesAndPlans></li>
              <li  className='tasks title-main-menu '><NavLink data-name-route='tasks' to={"/tasks"} onClick={hideMainMenu}><FontAwesomeIcon className='icon-main-menu' icon={faListCheck} />المهام</NavLink></li>
              <li className='report'> 
                
                <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}>
                  <div data-name-route="report" className='report dropdown-btn d-flex justify-content-between title-main-menu' onClick={dropdownList}>
                    <div>
                      <FontAwesomeIcon className='icon-main-menu' icon={faFileCode} />
                      التقارير  
                    </div>
                    
                    <div className=' me-3' >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                </ProtectedRouteRolesAndPlans>
                <ul className='dropdown-list mb-4'>
                  <li  className='unit-cost sub-title-main-menu '><NavLink to={"/report/unit-cost"}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBills} />تقرير تكلفة الوحدة</NavLink></li>
                <li  className='all-cost sub-title-main-menu '><NavLink to={"/report/all-cost"}><FontAwesomeIcon className='icon-sub-main-menu' icon={faMoneyBillTransfer} />تقرير التكلفة المجمع</NavLink></li>
                </ul>
              </li>
              <li className='owners title-main-menu '> <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin']}><NavLink data-name-route='owners' to={"/owners"}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />الملاك</NavLink></ProtectedRouteRolesAndPlans></li>
              <li className='clients title-main-menu '> <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin']}><NavLink data-name-route='clients' to={"/clients"}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />العملاء</NavLink></ProtectedRouteRolesAndPlans></li>
              <li className='users title-main-menu '> <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin']}><NavLink data-name-route='users' to={"/users"}><FontAwesomeIcon className='icon-main-menu' icon={faUser} />المستخدمين</NavLink></ProtectedRouteRolesAndPlans></li>
              <li className='settings title-main-menu '> <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin']}><NavLink data-name-route='settings' to={"/settings"}><FontAwesomeIcon  className='icon-main-menu' icon={faSliders} />الإعدادات</NavLink></ProtectedRouteRolesAndPlans></li>

            </ul>; */}
      </div>
      <div className='layer-mobile d-none' onClick={() => {hideMainMenu();}}>
      </div>
    </section>
    
    </> : <></>}
    
    

    </>

    
  )
}