import React from 'react';
import moment from 'moment';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
 
const Presets = (props) => {
  const {t, i18n} = useTranslation()
  const { startDate, endDate, displayFormat, handlePresets } = props;
 
  const today = moment().utc(true).set({hour:0,minute:0,second:0,millisecond:0});
  const presets = [
    {
      text: t("Tomorrow"),
      start: today,
      end: moment().add(1, 'day').utc(true).set({hour:0,minute:0,second:0,millisecond:0}).add(moment().utcOffset(), "minute"),
    },
    {
      text: t("Next 7 Days"),
      start : today,
      end: moment().add(1, 'week').utc(true).set({hour:0,minute:0,second:0,millisecond:0}).add(moment().utcOffset(), "minute"),
      
    },
    {
      text: t("Next Month"),
      start: today,
      end: moment().add(1, 'month').utc(true).set({hour:0,minute:0,second:0,millisecond:0}).add(moment().utcOffset(), "minute"),
    },
    // {
    //   text: 'اخر 3 شهور',
    //   end: today,
    //   start: moment().subtract(3, 'month'),
    // },
  ];
 
  return (
    <div className="p-3 text-end">
      {presets.map(({ text, start, end }) => {
        const isSelected = moment(start).format(displayFormat) === moment(startDate).format(displayFormat) && moment(end).format(displayFormat) === moment(endDate).format(displayFormat);
        return (
          <Button 
            key={text} 
            type="button" 
            className={`btn btn-sm text-white ${isSelected ? 'btn-myPrimaryLight' : 'btn-myPrimary'}`} 
            style={{ marginRight: 10, marginBlock : 5 }} 
            onClick={() => handlePresets(start, end)}
          >
            {text}
          </Button>
        );
      })}
    </div>
  );
}
 
export default Presets;
