  import { Autocomplete, Box, Checkbox, Chip, FormControl, FormControlLabel, Icon, Input, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, TextField, ThemeProvider } from '@mui/material'
import React, { useState,  useEffect } from 'react';
import ImageUploader from "react-images-upload";
import $ from 'jquery';

import CurrentLocationMap from '../CurrentLocationMap/CurrentLocationMap';
import { faChevronDown, faChevronUp, faPlus, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import joi from 'joi';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ErrorMessageServer from '../ErrorMessageServer/ErrorMessageServer';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import AddOwner from '../AddOwner/AddOwner';
import Reload from '../Reload/Reload';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, successMessage, validationErrorMessage } from '../../constants/global';
import { enqueueSnackbar } from 'notistack';

//Import the necessary Firebase modules in your React component where you want to handle the media upload.
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import UploadImages from '../UploadImages/UploadImages';
import { useTranslation } from 'react-i18next';

export default function AddUnit({theme, togglePopUp, popupClose, baseUrl, token, setRowData, decodeToken}) {
        
  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate()

  const [dataRow, setDataRow] = useState('') // unit inputs for edit

          // inputs
  const country = [
    { "label": t("North Coast"), "id": "North Coast" },
    { "label": t("Alexandria"), "id": "Alexandria" },
    { "label": t("Cairo"), "id": "Cairo" },
    { "label": t("Giza"), "id": "Giza" },
    { "label": t("Luxor"), "id": "Luxor" },
    { "label": t("Aswan"), "id": "Aswan" },
    { "label": t("Red Sea"), "id": "Red Sea" },
    { "label": t("Matruh"), "id": "Matruh" },
    { "label": t("Suez"), "id": "Suez" },
    { "label": t("Ismailia"), "id": "Ismailia" },
    { "label": t("Qena"), "id": "Qena" },
    { "label": t("Assiut"), "id": "Assiut" },
    { "label": t("Minya"), "id": "Minya" },
    { "label": t("New Valley"), "id": "New Valley" },
    { "label": t("Beni Suef"), "id": "Beni Suef" },
    { "label": t("Beheira"), "id": "Beheira" },
    { "label": t("Sharqia"), "id": "Sharqia" },
    { "label": t("Damietta"), "id": "Damietta" },
    { "label": t("Kafr El Sheikh"), "id": "Kafr El Sheikh" },
    { "label": t("Port Said"), "id": "Port Said" },
    { "label": t("North Sinai"), "id": "North Sinai" },
    { "label": t("South Sinai"), "id": "South Sinai" },
    { "label": t("Qalyubia"), "id": "Qalyubia" },
    { "label": t("Menofia"), "id": "Menofia" },
    { "label": t("Dakahlia"), "id": "Dakahlia" },
    { "label": t("El Fayoum"), "id": "El Fayoum" },
    { "label": t("Monufia"), "id": "Monufia" },
    { "label": t("Sohag"), "id": "Sohag" },
    { "label": t("New Administrative Capital"), "id": "New Administrative Capital" },
  ];
  const Unites = [ {label : t("Villa"), id : "Villa"} , {label : t("Chalet"), id : "Chalet"},  {label : t("Tourist Apartment"), id : "Tourist Apartment"} , {label : t("Residential Apartment"), id : "Residential Apartment"}];
  const [costInterval, setCostInterval] = useState('');
  const [countryValue, setCountryValue] = useState(country[0] || null);
  const [countryInputValue, setCountryInputValue] = useState('');
  const [uniteValue, setUnitesValue] = useState(Unites[0] || null);
  const [unitesInputValue, setUnitesInputValue] = useState('');
  const [pictures, setPictures] = useState([]);
  const [remainingChar, setRemainingChar] = useState(0);

  // select chip dropdown checkboxes

  const [service, setService] = useState([]);
    //  api
  // const [showError, setShowError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [showErrorServer, setShowErrorServer] = useState(false);
  // const [errorServerMessage, setErrorServerMessage] = useState('');
  // const [showSuccess, setShowSuccess] = useState(false)
  // const [successMessage, setSuccessMessage] = useState('');
  const [tourism, setTourism] = useState(false);
  const [ownerResponse, setOwnerResponse] = useState([])
  const [owner, setOwner] = useState('')
  const [ownerValue, setOwnerValue] = useState(null);
  const [ownerInputValue, setOwnerInputValue] = useState('');
  const [unitData, setUnitData] = useState({
    unitName : '',
    unitCode : '',
    country : countryValue?.id,
    costInterval : costInterval,
    cost : '',
    unitType : uniteValue?.id,
    unitCompound : "",
    bedrooms : '',
    baths : '',
    isListingActive : false,
    description : '',
    services : service,
    isCheckin : false,
    isCheckout : false,
    cancelation : false,
    location : '',
    media : [],
    owner : "",
  })
  const costWay = ["night", "month"]


  const handleChangeChip = (event, setChange) => {
    const {
      target: { value },
    } = event;
    setChange(
      // On autofill we get a stringified value.
      value
    );
  };

  const onDrop = picture => {
    setPictures([...pictures, picture]);
  };
  function limitDesc(e) {
    const valueChar = e.target.value
    ////(valueChar.length);
    let resetChar = valueChar.length
    setRemainingChar(resetChar)
  }
  const handleChangeSelect = (event, setChangeSelect) => {
    setChangeSelect(event.target.value);
  };

  function toggleCard(target) {
    ////($(target).next());
    $(`.${target}`).slideToggle('slow');
    $(`.${target}`).prev().children().eq(1).toggleClass("d-none")
    $(`.${target}`).prev().children().eq(2).toggleClass("d-none") 
  }

  // function addInputInfoOwner(e) {
  //   ////(e.target.checked);
  //   if (e.target.checked) {
      
  //   }
  // }


  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    disableScrollLock: true,
  };

  const services = [
    {label : t("Beach view"), id : "Beach view"},
    {label : t("Mountain view"), id : "Mountain view"},
    {label : t("Lake view"), id : "Lake view"},
    {label : t("Sea View"), id : "Sea View"},
    {label : t("Overlooking pool"), id : "Overlooking pool"},
    {label : t("office"), id : "office"},
    {label : t("Garage"), id : "Garage"},
    {label : t("stove"), id : "stove"},
    {label : t("heater"), id : "heater"},
  ];

    // function getStyles(name, service, theme) {
    //   return {
    //     fontWeight:
    //       service.indexOf(name) === -1
    //         ? theme.typography.fontWeightRegular
    //         : theme.typography.fontWeightMedium,
    //   };
    // }

    //upload images
    // const [images, setImages] = useState([]);
    // const handleImageUpload = async (uploadedImages) => {
    //   try {
    //     const storage = getStorage();
    //     const storageRef = ref(storage);
  
    //     for (let i = 0; i < uploadedImages.length; i++) {
    //       const image = uploadedImages[i];
    //       const imageRef = ref(storageRef, image.name);
    //       await uploadBytes(imageRef, image);
  
    //       const downloadURL = await getDownloadURL(imageRef);
    //       // Store the downloadURL in your database
    //       console.log("Download URL:", downloadURL);
    //     }
    //   } catch (error) {
    //     console.error("Error uploading images:", error);
    //   }
    // };
    ///////////

  function handleChange(e, id, newValue) {
    console.log(e, id, newValue);
    let myData = {}
    dataRow ?  myData = {...dataRow} : myData = {...unitData}
    if (id === "costInterval" || id === "services") {
      myData[id] = e.target.value
    }else if( id === "cancelation") {
      myData[id] = (e.target.value === 'true')
    }else if ((id === "country" || id === "unitType" || id === "owner") && newValue) {
      myData[id] = newValue
    } else if (id === "media") {
      myData[id] = newValue
      console.log(newValue);
    }else if (id === "location") {
      myData[id] = newValue
      localStorage.removeItem('address');
    }else if (e.target.id === "isListingActive" || e.target.id === "isCheckin" || e.target.id === "isCheckout") {
      myData[e.target.id] = e.target.checked
    }  else {
      myData[e.target.id] = e.target.value;
    }
    dataRow ? setDataRow(myData) : setUnitData(myData)
    // setDataRow(myData)
    console.log(myData);

  }
  // function setLocationAndSetMedia() {
  //   const myData = {...UnitData}
  //   myData.location = localStorage.getItem('address');
  //   localStorage.removeItem('address');
  //   myData.media = pictures
  //   setUnitData(myData)
  // }

  function getOptions(myData, setData,) {
    // const data = getData(setOwnerData, "owner");
    ////(myData);
    const options = myData.map((ele) => {
      return (
        {
          label : ele.ownerName + " - " + ele.ownerPhone.slice(1) ,
          id : ele._id
        }
      ) 
    })
    setData(options)
    ////(options);
  }

  function invalidMediaAndLocation(array) {
    // $(".media-invalid").addClass("d-none")
    array?.forEach(ele => {
      if (ele.context.key === "media") {
        ////("what");
        $(".media-invalid").removeClass("d-none");
      }
      if (ele.context.key === "location") {
        $(".location-invalid").removeClass("d-none");
      }
    });
  }


  function clearInputsDataRow() {
    if (dataRow) {
      localStorage.removeItem("data")
      setDataRow('');
    }else {
      setUnitData({
        unitName : "",
        unitCode : "",
        country : "",
        costInterval : "",
        cost : "",
        unitType : "",
        unitCompound : "",
        bedrooms : "",
        baths : "",
        isListingActive : "",
        description : "",
        services : "",
        conditions : "",
        cancelation : "",
        location : "",
        media : "",
        owner : "",
      })
    }
  }

  function validationUnitData(newData) {
    let minimumList = 0
    if (dataRow?.isListingActive || unitData.isListingActive ) {
      minimumList = 5
    }
    const schema = joi.object().required().keys({
      unitName : joi.string().required(),
      unitCode : joi.string().max(20).required(),
      country : joi.string().max(20).required(),
      costInterval : joi.string().max(10).required(),
      cost : joi.number().required(),
      unitType : joi.string().required(),
      unitCompound : newData.unitType === "Chalet" || newData.unitType === "Villa" ? joi.string().max(20).required() : joi.string().max(20).allow("") ,
      bedrooms : joi.number().required(),
      baths : joi.number().required(),
      isListingActive : joi.boolean().required(),
      description : joi.string().max(500).required(),
      services : joi.array().min(1).required(),
      isCheckin : joi.boolean().required(),
      isCheckout : joi.boolean().required(),
      cancelation : joi.boolean().required(),
      location : joi.string().required(),
      media : joi.array().min(minimumList).required(),
      owner : joi.string().length(24).required(),
    });
    return  schema.validate(newData, {abortEarly : false})
  }

  async function addUnitData() {
    const validationData = validationUnitData(unitData);
    console.log(validationData);
    
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      invalidMediaAndLocation(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('post', unitData, "unit", dataRow);
      ////(res);
      if (res.message === "success") {
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData)
        navigate('/units')
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
        invalidMediaAndLocation(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});

      }
    }
    clearInputsDataRow()
    showInvalidFeedback([])
  }
  async function editUnitData() {
    const newData = {
      unitName : dataRow.unitName,
      unitCode : dataRow.unitCode,
      country : dataRow.country,
      costInterval : dataRow.costInterval,
      cost : dataRow.cost,
      unitType : dataRow.unitType,
      unitCompound : dataRow.unitCompound,
      bedrooms : dataRow.bedrooms,
      baths : dataRow.baths,
      isListingActive : dataRow.isListingActive,
      description : dataRow.description,
      services : dataRow.services,
      isCheckin : dataRow.isCheckin,
      isCheckout : dataRow.isCheckout,
      cancelation : dataRow.cancelation,
      location : dataRow.location,
      media : dataRow.media,
      owner : typeof(dataRow.owner) == "string" ? dataRow.owner : dataRow.owner._id
    }
    const validationData = validationUnitData(newData);
    ////(validationData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      invalidMediaAndLocation(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('put', newData, "unit", dataRow._id);
      ////(res);
      if (res.message === "success") {
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData)
        navigate('/units')
      }else if(res.message === "validation error") {

        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
        invalidMediaAndLocation(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
      ////(res);
    }
   
    clearInputsDataRow()
    showInvalidFeedback([])
  }

  function handleDelete(e) {
    console.log(e);
  }

  useEffect(() => {
    if (localStorage.getItem("data")) {
      ////(JSON.parse(localStorage.getItem("data")));
      setDataRow(JSON.parse(localStorage.getItem("data")));
    }
    getData(setOwnerResponse, "owner");
  }, [])

  useEffect(() => {
    return () => {
      if (dataRow) {
        localStorage.removeItem("data")
      }
    }
  }, [dataRow])
  
  useEffect(() => {
    if (localStorage.getItem("data")) {
      ////(JSON.parse(localStorage.getItem("data")));
      setDataRow(JSON.parse(localStorage.getItem("data")));
    }
    getData(setOwnerResponse, "owner");
  }, [])

  useEffect(() => {
    if (ownerResponse) {
      getOptions(ownerResponse, setOwner);
    }
  }, [ownerResponse])

  useEffect(() => {
    if (!dataRow?.owner?.ownerName && owner.length) {
      console.log(owner);
      ////('owner');
      setOwnerInputValue(owner[0].label);
      handleChange('', "owner",owner[0]?.id);
      setOwnerValue(dataRow?.owner?.ownerName ? dataRow?.owner?.ownerName + " - " + dataRow?.owner?.ownerPhone  : owner[0]);
    }
  }, [owner])
  
  return ( <>
  
  <section className='bg-babyblue-50 big-card container-fluid'>
        <div className="header-card d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h2 className='fw-bolder'>{t("Units")}</h2>
          </div>
        </div>
        {owner ? <div className='px-3'>
            <div className="container-fluid ">
            <ThemeProvider theme={theme}>
              <ul className=' info-cards-Unites row'>
                <li className="col-md-6">
                    <div className='p-2'>
                      <TextField id='unitName' className='textfield-input' label={t("Unit Name")} onChange={handleChange} value={dataRow?.unitName ? dataRow?.unitName : unitData.unitName } variant="standard"  type="text" />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the unit name.")}</div>
                </li>
                <li className="col-md-6">
                    <div className='p-2'>
                      <TextField id='unitCode' className='textfield-input' label={t("Unit Code")} onChange={handleChange} value={dataRow?.unitCode ? dataRow?.unitCode : unitData.unitCode } variant="standard"  type="text" />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the unit code.")}</div>
                </li>
                {/* <li className="col-lg-3 col-sm-4 ">
                    <div className='mt-3'>
                      <FormControlLabel id='isListingActive' control={<Checkbox checked={dataRow?.isListingActive ?  true : false} />} onClick={addInputInfoOwner} label="لست مالك الوحدة" />
                    </div>
                </li> */}
                <li className="col-md-6">
                    <div className='p-2'>
                    <Autocomplete
                    
                      value={dataRow?.country ? t(dataRow?.country) : countryValue}
                      onChange={(event, newValue) => {
                        setCountryValue(newValue);
                        handleChange(event, "country", newValue?.id)
                      }}
                      inputValue={countryInputValue}
                      onInputChange={(event, newInputValue) => {
                        setCountryInputValue(newInputValue);
                      }}
                      id="country"
                      options={country}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <TextField label={t("Governorate")}  placeholder={t("Governorate")}
                      variant="standard"  className='textfield-input'   {...params}/>}
                    />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please choose the governorate.")}</div>
                </li>
                
                <li className="col-md-3">
                  <div className='p-2'>
                    <FormControl  className='textfield-input' variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">{t("Cost / Night - / Month")}</InputLabel>
                      <Select
                        MenuProps={{ disableScrollLock: true }}
                        labelId="demo-simple-select-standard-label"
                        id="costInterval"
                        value={dataRow?.costInterval ? dataRow?.costInterval : costInterval}
                        onChange={ (e) => {handleChangeSelect(e, setCostInterval); handleChange(e, "costInterval");}}
                      >
                        <MenuItem value={"night"}>{t("Cost / Night")}</MenuItem>
                        <MenuItem value={"month"}>{t("Cost / Month")}</MenuItem>
                        
                      </Select>
                    </FormControl>
                    {costInterval === "month" ? <div className='text-helper'>{t("The month will be calculated as 30.5 days.")}</div> : <></> }
                  </div>
                  <div className='text-helper-invalid d-none'>{t("Please select the appropriate cost rate.")}</div>
                </li>
                <li className="col-md-3">
                    <div className='p-2'>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">{t("Cost")}</InputLabel>
                        <Input
                          type='number'
                          inputProps={{ min : 0 }}
                          id="cost"
                          onChange={handleChange}
                          value={dataRow?.cost ? dataRow?.cost : unitData.cost}
                          endAdornment={<InputAdornment position="end"> {t("EGP")} </InputAdornment>}
                          // dir='rtl'
                        />
                      </FormControl>
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the cost.")}</div>
                </li>
                <li className={`${tourism ? 'col-md-3' : "col-md-6"}`}>
                  <div className='p-2'>
                    <Autocomplete
                      value={dataRow?.unitType ? t(dataRow?.unitType) : uniteValue}
                      onChange={(event, newValue) => {
                        setUnitesValue(newValue);
                        handleChange(event, "unitType", newValue?.id);
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option === value;
                      }}
                      inputValue={unitesInputValue}
                      onInputChange={(event, newInputValue, ) => {
                        setUnitesInputValue(newInputValue);
                        console.log(newInputValue);
                        newInputValue === t("Villa") || newInputValue === t("Chalet") ? setTourism(true) : setTourism(false)
                      }}
                      id="unitType"
                      options={Unites}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <TextField className='textfield-input' label={t("Unit Type")} variant="standard" {...params}/>}
                    />
                  </div>
                  <div className='text-helper-invalid d-none'>{t("Please select the unit type")}</div>
                </li>
                {tourism && <li className="col-md-3">
                    <div className='p-2'>
                      <TextField id='unitCompound' className='textfield-input' label={t("Village / Compound Name")} onChange={handleChange} value={dataRow?.unitCompound ? dataRow?.unitCompound : unitData.unitCompound}   variant="standard"  type="text" />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the village/compound name.")}</div>
                </li>}
                <li className="col-md-4 col-sm-8">
                  <div className='p-2'>
                    <Autocomplete
                      value={dataRow?.owner?.ownerName ? {id : dataRow?.owner?._id, label : dataRow?.owner?.ownerName + " - " + dataRow?.owner?.ownerPhone} : ownerValue}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setOwnerValue(newValue);
                        handleChange(event, "owner", newValue?.id)
                      }}
                      isOptionEqualToValue={(option, value) => 
                        option.label === value.label
                      }
                      inputValue={ownerInputValue}
                      onInputChange={(event, newInputValue) => {
                        ////(newInputValue);
                        setOwnerInputValue(newInputValue);
                      
                      }}
                      id="owner"
                      options={owner}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <TextField label={t("Owner's Name")}   variant="standard"  className='textfield-input'   {...params}/>}
                    />
                    <div className='text-helper-invalid d-none'>{t("Please enter the owner's name.")}</div>
                  </div>
                  
                </li>
                <li className="col-md-2 col-sm-4 d-flex justify-content-center">
                    <div className='pt-2'>
                      <div className=' mt-2' onClick={() => togglePopUp("add-popup")}>
                        <Button variant="contained" className='btn-small-request my-auto'><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>{t("Add Owner")}</Button>
                      </div>
                    </div>
                </li>
                <li className="col-md-3">
                    <div className='p-2'>
                      <TextField id='bedrooms' type="number" className='textfield-input' label={t("Number of Bedrooms")} onChange={handleChange} value={dataRow?.bedrooms ? dataRow?.bedrooms : unitData.bedrooms} variant="standard" />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the number of bedrooms in the unit.")}</div>
                </li>
                <li className="col-md-3">
                    <div className='p-2'>
                      <TextField id='baths' className='textfield-input' label={t("Number of Bathrooms")} onChange={handleChange} value={dataRow?.baths ? dataRow?.baths : unitData.baths} variant="standard"  type="number" />
                    </div>
                    <div className='text-helper-invalid d-none'>{t("Please enter the number of bathrooms in the unit.")}</div>
                </li>
                {/* <li className="col-md-6">
                    <div className='px-2 d-flex '>
                      
                      <label className='align-self-end label-input'>عدد السراير : </label>
                      <div className='px-2'>
                        <TextField className='textfield-input' label placeholder='0' variant="standard"  type="text" />
                      </div>
                      <div className='px-2'>
                        <TextField className='textfield-input' label placeholder='0' variant="standard"  type="text" />
                      </div>
                      <div className='px-2'>
                        <TextField className='textfield-input' label placeholder='0' variant="standard"  type="text" />
                      </div>
                    </div>
                </li> */}
                <li className="col-md-6">
                    <div className='mt-3 px2'>
                      <FormControlLabel control={<Checkbox id='isListingActive' onChange={handleChange} checked={dataRow?.isListingActive && dataRow?.isListingActive } />} label={t("List in the unit browsing and booking." )} />
                    </div>
                </li>
              </ul>
              <ul className=' info-cards-Unites row p-3'>
                <div className="col">
                  <div className='p-2 desc-input'>
                    <TextField id='description' value={dataRow?.description ? dataRow?.description : unitData.description} multiline rows={3}  inputProps={{ maxLength: 500 }} onChange={(e) => {limitDesc(e); handleChange(e)}}   className='textfield-input input-des ' label={t("Description")} variant="standard"  type="text" />
                    <div className="remaining-char">
                      {remainingChar} / 500
                    </div>
                  </div>
                  <div className='text-helper-invalid d-none'>{t("Please enter a detailed description of the unit.")}</div>

                </div>
              </ul>
              <div>
              <div className='  info-cards-Unites row '>
                <div className='d-flex justify-content-between align-items-center cursor-pointer' onClick={(e) => toggleCard("toggle-card-services")}>
                    <h4 className='fs-5 '>{t("Services")} : </h4>
                    <FontAwesomeIcon className='arrow-card-icon d-none' icon={faChevronUp} />
                    <FontAwesomeIcon className='arrow-card-icon ' icon={faChevronDown} />
                </div>
                <div className='toggle-card-services'>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-multiple-chip-label"></InputLabel> */}
                    <Select
                      label=""
                      MenuProps={MenuProps}
                      labelId="demo-multiple-chip-label"
                      id="services"
                      multiple
                      value={dataRow?.services ? dataRow?.services : unitData.services}
                      onChange={(e) => {handleChangeChip(e, setService); handleChange(e, "services");}}
                      input={<Input id="select-multiple-chip"  />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width : "100%", top : "611px" }}>
                          {selected.map((value) => (
                            <Chip key={value} label={t(value)}  style={{top : "611px"}}/>
                          ))}
                        </Box>
                      )}
                      
                    >
                      {services.map((service) => (
                        <MenuItem
                          key={service.id}
                          value={service.id}
                          // style={getStyles(service, service, theme)}
                        >
                          {service.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='text-helper-invalid d-none'>{t("Please select at least one service from the unit's services.")}</div>

                  
              </div>
            </div>

            
              <ul className='row policy-cards-Unites gx-5'>
                <li className=" col-md-6 ">
                  <div className='  info-cards-Unites row'>
                    <div className='d-flex justify-content-between align-items-center cursor-pointer' onClick={(e) => toggleCard("toggle-card-rules")}>
                      <h4 className='fs-5 '>{t("Terms")} : </h4>
                      <FontAwesomeIcon className='arrow-card-icon d-none' icon={faChevronUp} />
                      <FontAwesomeIcon className='arrow-card-icon ' icon={faChevronDown} />
                    </div>
                    <ul className='row toggle-card-rules'>
                      <li className='col-12'>
                        <div className=''>
                          <FormControlLabel control={<Checkbox id='isCheckin'  checked={dataRow ? dataRow?.isCheckin : unitData.isCheckin} />} onChange={handleChange} label={t("Check-in from 12 - 2 PM")} />
                        </div>
                      </li>
                      <li className='col-12'>
                        <div className=''>
                          <FormControlLabel control={<Checkbox id='isCheckout' checked={dataRow ? dataRow?.isCheckout : unitData.isCheckout} />} onChange={handleChange} label={t("Check-out from 10 - 12 AM")} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="col-md-6  ">
                  <ul className='  info-cards-Unites row d-flex '>
                    <div className='d-flex justify-content-between align-items-center cursor-pointer' onClick={(e) => toggleCard("toggle-card-policy")}>
                      <h4 className='fs-5 '>{t("Cancellation Policy")} : </h4>
                      <FontAwesomeIcon className='arrow-card-icon d-none' icon={faChevronUp} />
                      <FontAwesomeIcon className='arrow-card-icon ' icon={faChevronDown} />
                    </div>

                    <div className='toggle-card-policy'>
                      <FormControl>
                        <RadioGroup
                          id='cancelation'
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={dataRow?.cancelation ? dataRow?.cancelation : unitData.cancelation}
                          onChange={(e) => handleChange(e, "cancelation")}
                        >
                          <FormControlLabel value={false} control={<Radio/>} label={t("Non-refundable")} />
                          <FormControlLabel value={true} control={<Radio/>} label={t("Refundable within a maximum of two days from booking")} />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {/* <div className='toggle-card-policy'>
                      <FormControl  className='textfield-input' variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                        <Select
                          MenuProps={{ disableScrollLock: true }}
                          labelId="demo-simple-select-standard-label"
                          placeholder='سياسة الألغاء'
                          id="cancelation"
                          value={dataRow?.cancelation ? dataRow?.cancelation : cancelation}
                          onChange={ (e) => {handleChangeSelect(e, setCancelation); handleChange(e, "cancelation");}}
                        >
                          <MenuItem value={"غير قابل للاسترداد"}>غير قابل للاسترداد</MenuItem>
                          <MenuItem value={"قابل للأسترداد بحد اقصي يومين من الحجز"}>قابل للأسترداد بحد اقصي يومين من الحجز</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                {/* <div>يرجى إدخال اسم الوحدة</div> */}
                    
                  </ul>
                </li>
              </ul>
            </ThemeProvider>

            </div>

            {/* S T A R T   U P L O A D I N G   I M A G E  */}

            <div className='info-cards-Unites'>
              <div className=' px-3'>
                <div  className='d-flex justify-content-between align-items-center cursor-pointer' onClick={(e) => toggleCard("toggle-card-upload")}>
                  <h4 className='pb-3 fs-5'>{t("Photos and Videos")}</h4>
                  <FontAwesomeIcon className='arrow-card-icon d-none' icon={faChevronUp} />
                  <FontAwesomeIcon className='arrow-card-icon ' icon={faChevronDown} />
                </div>
                <div className='toggle-card-upload'>
                {dataRow?.unitCode ? dataRow?.unitCode ? <UploadImages unitCode={dataRow?.unitCode} data={dataRow} setData={setDataRow} /> : <></> : unitData?.unitCode ? <UploadImages unitCode={unitData.unitCode} data={unitData} setData={setUnitData} /> : <></> }
                  {/* {dataRow?.unitCode ? dataRow?.unitCode ? <></> : <div className='text-danger'>add unit Code</div> : unitData?.unitCode ? <></> : <div className='text-danger'>add unit Code</div> } */}
                  {/* <ImageUploader
                    withIcon={false}
                    onChange={(pic) => {onDrop(pic); handleChange(pic, "media");}}
                    // onChange={(uploadedImages) => {
                    //   onDrop(uploadedImages, []);
                    //   handleChange(uploadedImages, "media");
                    //   setImages(uploadedImages);
                    // }}
                    withPreview={true}
                    accept='video/*, image/*'
                    imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".mp4"]}
                    maxFileSize={5242880}
                    label='الحد الأقصى لحجم الملف: 5 ميغا بايت ، مقبول: jpg | jpeg | png | mp4'
                    buttonText={<FontAwesomeIcon className='icon-upload-image' icon={faUpload} />}
                    buttonClassName="btn-upload-files"
                  /> */}
                </div>
                <div  className='text-helper-invalid media-invalid d-none'>{t("Please upload at least 5 photos or videos of the unit.")}</div>
                

              </div>
            </div>
                {/* E N D  U P L O A D I N G   I M A G E  */}
                
                {/* S T A R T   M A P   L O A C A T I O N */}

            <div className='info-cards-Unites'>
              <div className=' px-3'>
                <div className='d-flex justify-content-between align-items-center cursor-pointer' onClick={(e) => toggleCard("toggle-card-map")}>
                  <h4 className='pb-3 fs-5'>{t("Unit Location on the Map")}</h4>
                  <FontAwesomeIcon className='arrow-card-icon d-none' icon={faChevronUp} />
                  <FontAwesomeIcon className='arrow-card-icon ' icon={faChevronDown} />
                </div>
                <div className='toggle-card-map'>
                  <CurrentLocationMap handleChange={handleChange} dataRow={dataRow}/>
                </div>
              </div>
              <div className='text-helper-invalid location-invalid d-none'>{t("Please specify the unit's location on the map.")}</div>
            </div>
                {/* E N D  M A P   L O A C A T I O N */}


        
        <div className='text-start pt-4 pb-3'>
          <Link to={"/units"}><Button variant="contained" className='btn-myPrimaryLight m-3' onClick={clearInputsDataRow}>{t("Cancel")}</Button></Link>
          {dataRow ? <Button variant="contained" className='btn-myPrimary' onClick={editUnitData} >{t("Confirm")}</Button> : <Button variant="contained" className='btn-myPrimary' onClick={addUnitData}>{t("Confirm")}</Button>}
        </div>
        </div>  :  
          <div className='mt-3 px-3'>
            <div className="container-fluid ">
            <ThemeProvider theme={theme}>

              <Skeleton variant="rounded" width={"100%"} height={"380px"} style={{borderRadius : "20px"}}/>
              
              <ul className=' mt-3 '>
                <Skeleton variant="rounded" width={"100%"} height={"140px"} style={{borderRadius : "20px"}}/>
              </ul>
              <div className='  mt-3 '>
                <Skeleton variant="rounded" width={"100%"} height={"88px"} style={{borderRadius : "20px"}}/>
              </div>            
              <ul className='row mt-3 '>
                <li className=" col-md-6 container-fluid  ">
                  <Skeleton variant="rounded" width={"100%"} height={"140px"} style={{borderRadius : "20px"}}/>
                </li>
                <li className=" col-md-6  container-fluid pe-md-5">
                  <Skeleton variant="rounded" width={"100%"} height={"140px"} style={{borderRadius : "20px"}}/>
                </li>
              </ul>
            </ThemeProvider>

            </div>

            {/* S T A R T   U P L O A D I N G   I M A G E  */}

            <div className='mt-3'>
              <Skeleton variant="rounded" width={"100%"} height={"190px"} style={{borderRadius : "20px"}}/>
            </div>
                {/* E N D  U P L O A D I N G   I M A G E  */}
                
                {/* S T A R T   M A P   L O A C A T I O N */}

            <div className='mt-3'>
            <Skeleton variant="rounded" width={"100%"} height={"578px"} style={{borderRadius : "20px"}}/>
            </div>
                {/* E N D  M A P   L O A C A T I O N */}
          </div>}
  </section>

    {/* popup */}
    <div className='add-popup'>
      <AddOwner customTheme={theme} togglePopUp={togglePopUp}  setRowData={setRowData}  baseUrl={baseUrl} token={token} popupClose={popupClose}/>
    </div>

      {/* <ThemeProvider theme={theme}>
        <ErrorMessage message={errorMessage} showError={showError} setShowError={setShowError}/>
        <ErrorMessageServer message={errorServerMessage} showErrorServer={showErrorServer} setShowErrorServer={setShowErrorServer}/>
        <SuccessMessage message={successMessage} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      </ThemeProvider> */}

  
  
  </>
  )
}