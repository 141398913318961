import 'react-dates/initialize';
import moment from 'moment';
import React, { useEffect, useState } from "react";
// import {DateRangePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
// import Presets from '../Prestes/Prestes';
import CountUp from 'react-countup';
import axios from 'axios';
// import CostsAnalysis from './analysis/UnitsCostsAnalysis';
// import TotalCostsAnalysis from './analysis/TotalCostsAnalysisData';
import TotalCostsAnalysis from './analysis/TotalCostsAnalysis';
import RentalUnitsAnalysis from './analysis/RentalUnitsAnalysis';
import RentalActivityAnalysis from './analysis/RentalActivityAnalysis';
import CostsItemAnalysis from './analysis/CostsItemAnalysis';
// import RequestsDashboardTable from '../DashboardTable/RequestsDashboardTable';
// import TasksDashboardTable from '../DashboardTable/TasksDashboardTable';
// import { Button } from '@mui/material';
import UnitsCostsAnalysis from './analysis/UnitsCostsAnalysis';
import { Link } from 'react-router-dom';
import { Skeleton, Tooltip } from '@mui/material';
import {useTranslation} from 'react-i18next';


export default function Dashboard({theme, baseUrl, token}) {

  const { t, i18n } = useTranslation()

  // const tablesDashboardContainerStyle = {
  //   width: "100%",
  //   height: "300px",
  //   borderRadius : "5px"
  // }
  const chartsDashContainerStyles = {
    width: "100%",
    height : "250px"
  }
  const [tailsData, setTailsData] = useState({
    unitsCount : 0,
    requestsCount : 0,
    tasksCount : 0
  });
  const [revenue, setRevenue] = useState(0);
  const [unitsCosts, setUnitsCosts] = useState([]);
  const [totalCosts, setTotalCosts] = useState([]);
  const [totalCostsDate, setTotalCostsDate] = useState({
    totalCostsIn : 0,
    totalCostsOut : 0,
    percentCostIn : 0,
    percentCostOut : 0,
  });

  const [rentalUnits, setRentalUnits] = useState([]);
  const [rentalUnitsData, setRentalUnitsData] = useState({
    vacantUnitsCount : 0,
    emptyUnitsCount : 0,
    percentVacantUnits :  0,
    percentEmptyUnitsCount : 0,
  })
  const [rentalActivity, setRentalActivity] = useState([]);
  const [costsItem, setCostsItem] = useState([]);
  // const [rowData, setRowData] = useState([]);
  const [userName, setUserName] = useState('');



        // date Picker
  // const [dateRange, setDateRange] = useState({
  //   startDate: moment().subtract(6, 'day').utc(true).set({hour:0,minute:0,second:0,millisecond:0}),
  //   endDate:  moment().utc(true).set({hour:0,minute:0,second:0,millisecond:0}),
  // });
  // const [focusedInput, setFocusedInput] = useState(null);

  // const handleDateChange = ({ startDate, endDate }) => {
  //   setDateRange({
  //     startDate : startDate?.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}),
  //     endDate : endDate?.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}) });
  // };
  // const isOutsideRange = day => {
  //   ////(day.isAfter(moment().endOf("day")));
  //   return day.isAfter(moment().endOf("day"));
  // }


                  // get data dashboard
  async function getDashboardApi(endPoint) {
    const {data} = await  axios({
      method : "get",
      url : `${baseUrl}${endPoint}`,
      headers : {
      'Content-Type': 'application/json', 
      "authorization" : `Bearer ${token}`,
      } ,
    })
    return data
  }
  async function getRevenue() {
    const {data} = await getDashboardApi("dashboard/kpi");
    // ** get revenue
    // const {data} = await getDashboardApi("dashboard/tailsApi");
      ////(data);
      // // calc total requests
      // const totalRequest = calcTotalRequests(data)
      // ////(totalRequest);
      // // calc total costs in && out
      // const costDif = calcCostDif(data);
      // ////(costDif);
      // // calc total costs 
      // const totalTasks = calcTotalTasks(data);
      // ////(totalTasks);
      // calc revenue
      const revenue = data.reqCosts + data.costDiff - data.taskCosts;
      // save tails data
      let myTails = {...tailsData}
      myTails.unitsCount = data.unitsCount
      myTails.requestsCount = data.requestsCount
      myTails.tasksCount = data.tasksCount
      setTailsData(myTails);
      setRevenue(Math.round(revenue));
      // setRevenue(1000000000);
  }

  // function calcCostDif(data) {
  //   let totalCostsIn = 0
  //   let totalCostsOut = 0
  //   data.costs.forEach((ele) => {
  //     if (ele.transType === "Expenses") {
  //       totalCostsOut += ele.transTotalCost
  //     } else if(ele.transType === "Inputs") {
  //       totalCostsIn += ele.transTotalCost
  //     }
  //   })
  //   return totalCostsIn - totalCostsOut;
  // }
  // function calcTotalRequests(data) {
  //   let reqCosts = 0
  //   data.requests.forEach((ele) => {
  //     if (ele.reqStatus === "Accepted") {
  //       reqCosts += ele.reqTotalCost
  //     }
  //   })
  //   return reqCosts
  // }
  // function calcTotalTasks(data) {
  //   let totalTask = 0
  //   data.tasks.forEach((ele) => {
  //     if () {
  //       totalTask += ele.taskTotalCost
  //     }
  //   })
  //   return totalTask
  // }

  function getRequestsCostByUnits(data, unitsArray) {
    data.requests.forEach((request) => {
      let RequestCost = 0
      let totalCostRequest = 0
      if (request.reqStatus === "Finished") {
        RequestCost += request.reqTotalCost;
      }else if (request.reqStatus === "Started") {
        const start = moment(request.reqFrom).utc(false);
        const end = moment().utc(true);
        const difference = end.diff(start,"day");
        totalCostRequest = difference * (request.reqTotalCost / request.reqNights);
        RequestCost += totalCostRequest
      }
      const unit = unitsArray.find(req => req.unitCode === request.unit.unitCode)
      if (!unit && (request.reqStatus === "Finished" || request.reqStatus === "Started"  ) ) {
        unitsArray.push({
          unitCode: request.unit.unitCode,
          costIn: RequestCost,
          costOut : 0
        });
      } else if (unit && (request.reqStatus === "Finished" || request.reqStatus === "Started"  ) ) {
        unit.costIn += RequestCost;
      }
    })
  }

  function getTasksCostByUnits(data, unitsArray) {
    data.tasks.forEach((task) => {
      const unit = unitsArray.find(t => t.unitCode === task.unit.unitCode)
      if (!unit) {
        unitsArray.push({
          unitCode: task.unit.unitCode,
          costIn: 0,
          costOut : task.taskTotalCost
        });
      } else if (unit) {
        unit.costOut += task.taskTotalCost;
      }
    })
  }

  function getCostsCostByUnits(data, unitsArray) {
    data?.costs?.forEach((cost) => {
      cost.loadedUnits.forEach((loadedUnit) => {
        let unit = unitsArray.find(u => u.unitCode === loadedUnit.unit.unitCode);
        if (!unit) {
          unitsArray.push({
            unitCode: loadedUnit.unit.unitCode,
            costIn: cost.transType === "Inputs" ? (loadedUnit.percentage / 100) * cost.transTotalCost : 0,
            costOut: cost.transType === "Expenses" ? (loadedUnit.percentage / 100) * cost.transTotalCost : 0,
          });
        } else {
          if (cost.transType === "Expenses") {
            unit.costOut += (loadedUnit.percentage / 100) * cost.transTotalCost;
          } else if (cost.transType === "Inputs") {
            unit.costIn += (loadedUnit.percentage / 100) * cost.transTotalCost;
          }
        }
      });
    });
  }

      // get costs units
  function getCostsUnits(data) {
    let unitsArray = [];

      //  add total cost requests by units
      getRequestsCostByUnits(data, unitsArray);
      // add tasks by units
      getTasksCostByUnits(data, unitsArray);
      // add costs by units
      getCostsCostByUnits(data, unitsArray);
      
    console.log(unitsArray);
    setUnitsCosts(unitsArray.length ? unitsArray : [{unitCode: '', costIn:  0, costOut: 0,}] )
  }

      // get costs item
  function getCostsItem(data, totalRequestsCost, totalTasksCost) {
    let itemsArray = [];

    if (totalRequestsCost) {
      itemsArray.push({
        transItem: t("Rentals"),
        costIn: totalRequestsCost,
        costOut: 0,
      });
    }
    if (totalTasksCost) {
      itemsArray.push({
        transItem: t("Tasks"),
        costIn: 0,
        costOut: -totalTasksCost,
      });
    }
    console.log(totalTasksCost)
    data.costs.forEach((cost) => {
      ////(cost);
      let item = itemsArray.find(t => t.transItem === cost.transItem);
      if (!item) {
        ////(cost.transItem, "in");
        itemsArray.push({
          transItem: cost.transItem,
          costIn: cost.transType === "Inputs" ? cost.transTotalCost : 0,
          costOut: cost.transType === "Expenses" ? cost.transTotalCost * -1 : 0,
        });
      } else {
        ////(cost.transItem, "out");
        if (cost.transType === "Expenses") {
          item.costOut -= cost.transTotalCost;
        } else if (cost.transType === "Inputs") {
          item.costIn += cost.transTotalCost;
        }
        
      }
    });
    ////(itemsArray);
    setCostsItem(itemsArray.length ? itemsArray : [{ transItem: "",costIn:  0,costOut:  0}]);
  }
  
      // get total costs type
  function getTotalCostsType(data, totalRequestsCost, totalTasksCost) {
    let totalCostsIn = totalRequestsCost
    let totalCostsOut = totalTasksCost
    // console.log(totalRequestsCost, totalTasksCost);
    data.costs.forEach((ele) => {
      if (ele.transType === "Expenses") {
        totalCostsOut +=  ele.transTotalCost
      } else if(ele.transType === "Inputs") {
        totalCostsIn += ele.transTotalCost
      }
    })
    const totalCosts = totalCostsIn + totalCostsOut;
    const percentCostIn = ((totalCosts - totalCostsOut) / totalCosts) * 100 || 0
    ////(percentCostIn);
    const percentCostOut = ((totalCosts - totalCostsIn) / totalCosts) * 100 || 0
    ////(percentCostOut);

    const costTypeArray = [
      {
        transType : "Expenses",
        totalCosts : totalCostsOut
      },
      {
        transType : "Inputs",
        totalCosts : totalCostsIn
      },
    ]
    ////(costTypeArray);
    setTotalCosts(costTypeArray);
    setTotalCostsDate({
      totalCostsIn,
      totalCostsOut,
      percentCostIn,
      percentCostOut,
    })
  }

   // get rental 
  function getRentalUnits(data) {
    ////(tailsData?.unitsCount);
    let unitArray = []
    data.requests.forEach((request) => {
      if (request.reqStatus === "Started") {
        let unit = unitArray.find(u => u === request.unit._id);
        if (!unit) {
          unitArray.push(request.unit._id)
        }
      }
    })
    const vacantUnitsCount = unitArray.length;
    const emptyUnitsCount = tailsData.unitsCount - unitArray.length;
    const percentVacantUnits = ((tailsData.unitsCount - emptyUnitsCount) / tailsData.unitsCount) * 100 || 0
    const percentEmptyUnitsCount = ((tailsData.unitsCount - vacantUnitsCount) / tailsData.unitsCount) * 100 || 0
    ////(percentVacantUnits);
    ////(percentEmptyUnitsCount);


    const rentalArray = [
      {
        rental : "شاغرة",
        unitsCount : vacantUnitsCount
      },
      {
        rental : "فارغة",
        unitsCount : emptyUnitsCount
      },
    ]
    setRentalUnitsData({
      vacantUnitsCount,
      emptyUnitsCount,
      percentVacantUnits,
      percentEmptyUnitsCount,
    })
    setRentalUnits(rentalArray);
  }

  async function getRentalActivity() {
    const {data} = await getDashboardApi("dashboard/rentalActivity");
    let rentalActivityArray = [];
    const endDate = moment().add(7, 'day').utc(true).set({hour:0,minute:0,second:0,millisecond:0});
    const startDate = moment().utc(true).set({hour:0,minute:0,second:0,millisecond:0});
    const diffDays = endDate?.diff(startDate, 'days')
    if (diffDays <= 7) {
      for (let i = 0; i < diffDays; i++) {
        rentalActivityArray.push({
          date : moment(startDate).add(i, 'days').format('DD/MM - ddd'),
          in : 0,
          out : 0,
          unitCodeIn : [],
          unitCodeOut : [],
        })
      }
      data.requestArray.forEach((req) => {
        const request = rentalActivityArray.find(rental => rental.date === moment(req.date).format('DD/MM - ddd'))
        ////(request);
        if (request) {
          if (req.in) {
            request.in += 1
            request.unitCodeIn.push(req.unitCode);
          } else {
            request.out += 1
            request.unitCodeOut.push(req.unitCode);
          }
        }
      })
    }else {
      for (let i = 0; i < diffDays + 1; i++) {
        rentalActivityArray.push({
          date : moment(startDate).add(i, 'days').format('DD/MM'),
          in : 0,
          out : 0,
          unitCodeIn : [],
          unitCodeOut : [],
        })
      }
      data.requestArray.forEach((req) => {
        const request = rentalActivityArray.find(rental => rental.date === moment(req.date).format('DD/MM'))
        ////(request);
        if (request) {
          if (req.in) {
            request.in += 1
            request.unitCodeIn.push(req.unitCode);
          } else {
            request.out += 1
            request.unitCodeOut.push(req.unitCode);
          }
        }
      })
    }
    setRentalActivity(rentalActivityArray)
  }
  function getTotalTasksAndTotalRequests(data) {
    let totalRequestsCost = 0
    if (data.requests) {
      data.requests.forEach((ele) => {
        if (ele.reqStatus === "Finished") {
          totalRequestsCost += ele.reqTotalCost;
        }else if (ele.reqStatus === "Started") {
          const start = moment(ele.reqFrom).utc(false);
          const end = moment().utc(true);
          const difference = end.diff(start, "day");
          const costRequest = difference * (ele.reqTotalCost / ele.reqNights);
          totalRequestsCost += costRequest
        }
      })
    }
    let totalTasksCost = 0
    if (data.tasks) {
      
      data.tasks.forEach((ele) => {
        if (ele.taskStatus === "Completed") {
          totalTasksCost += ele.taskTotalCost;
        };
      });
    }
    return {totalRequestsCost, totalTasksCost}
  }
  async function getDashboard() {
    const {data} = await getDashboardApi("dashboard");
    // get revenue
    // getRevenue(data)

    //getTotalTasksAndTotalRequests
    const {totalRequestsCost, totalTasksCost} = getTotalTasksAndTotalRequests(data)
    // get costs units
    getCostsUnits(data)

    // get costs item
    getCostsItem(data, totalRequestsCost, totalTasksCost)

    // get total costs type
    getTotalCostsType(data, totalRequestsCost, totalTasksCost);

    // get rental 
    getRentalUnits(data, totalRequestsCost, totalTasksCost);

    // get leasing activity
    // getRentalUnits(data, totalRequestsCost, totalTasksCost);

    // set request for request table
    // setRowData(data)
  }

  useEffect(() => {
      getRevenue()
      getRentalActivity();
  }, []);

  useEffect(() => {
    if (tailsData.unitsCount !==0) {
      getDashboard();
    }
  }, [tailsData]);

  useEffect(() => {
    // add title page
    document.title = t("Home");
    // set userName from localstorage
    setUserName(localStorage.getItem("userName"));
  }, []);
  
  return (
    <>
    <section className='bg-babyblue-50 big-card container-fluid '>
      <div className='header-card text-center d-flex  justify-content-center justify-content-sm-between align-items-center flex-wrap '>
        <div className='header-title'>
          <h1 className='fw-bolder'>{t("Welcome,")} <span>{userName?.split(' ')[0].toUpperCase()}</span></h1>
        </div>
        {/* <div dir='ltr'  className=' ' >
          <DateRangePicker
            isRTL={i18n.language === "ar" ? true : false }
            startDate={dateRange?.startDate}
            endDate={dateRange?.endDate}
            startDateId='startDate'
            endDateId='endDate'
            onDatesChange={handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => focusedInput === "startDate" || focusedInput === "endDate" ?  setFocusedInput(focusedInput) : {}}
            startDatePlaceholderText={t("From")}
            endDatePlaceholderText={t("To")}
            displayFormat="YYYY/MM/DD"
            customArrowIcon={"-"}
            isOutsideRange={isOutsideRange}     
            numberOfMonths={window.innerWidth > 700 ? 2 : 1}
            hideKeyboardShortcutsPanel
            showClearDates
            reopenPickerOnClearDates
            renderCalendarInfo={() =><> <Presets
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              displayFormat={"YYYY/MM/DD"}
              handlePresets={(start, end) => {
                setDateRange({ startDate : start, endDate : end });
              }}
              />
              <Button className='btn-myPrimary mb-3 ms-3' onClick={() => {dateRange.startDate && dateRange.endDate ? setFocusedInput(false) : <></>}}>{t("Confirm")}</Button>
            </>}
          />
      
        </div> */}
      </div>
      
      <div className='parent-tails my-3 text-center d-flex justify-content-center align-items-center row g-4 mx-sm-2'>
      <div className="tails col-6 col-sm-6 col-md-3">
        <Link to={"/units"}>
          <div className='tails-shape-1'>
            <div className='text-tails'>
              <p className='fs-number-tails fs-number-tails-1'><CountUp end={tailsData.unitsCount} duration={tailsData.unitsCount / 10} /></p>
              <p className='title-tail'>{t("Units No.")}</p>
            </div>
          </div>
        </Link>        </div>
        <div className="tails col-6 col-sm-6 col-md-3">
          <Link to={"/requests"}>
            <div className='tails-shape-2'>
              <div className='text-tails'>
                <p className='fs-number-tails fs-number-tails-2'><CountUp end={tailsData.requestsCount} duration={tailsData.requestsCount / 15} /></p>
                <p className='title-tail'>{t("Requests No.")}</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="tails col-6 col-sm-6 col-md-3">
          <Link to={"/tasks"}>
            <div className='tails-shape-3'>
              <div className='text-tails'>
                <p className='fs-number-tails fs-number-tails-3 '><CountUp end={tailsData.tasksCount} duration={tailsData.tasksCount / 10} /></p>
                <p className='title-tail'>{t("Tasks No.")}</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="tails col-6 col-sm-6 col-md-3" style={{cursor : "context-menu"}}>
          <div className='tails-shape-4' style={revenue < 0 ? {backgroundColor : "#CC3F0C" } : {}}>
            <div className='text-tails'>
              <div className='d-flex justify-content-center'>
                <Tooltip disableHoverListener={revenue.toString().length >= 7 ? false : true} title={revenue.toLocaleString()} placement="top">
                  {/* <p className='fs-number-tails fs-number-tails-4'><CountUp end={revenue.toString().length >= 7 ? revenue.toString().length >= 10 ? revenue / 1000000000 : revenue / 1000000 : revenue} duration={20 / 10} /></p> */}
                  {<p className='fs-number-tails fs-number-tails-4'>{revenue.toString().length < 7 ? <CountUp end={Math.abs(revenue)} duration={20 / 10} /> : revenue.toString().length >= 7 && revenue.toString().length <= 9 ? (Math.abs(revenue) / 1000000).toFixed(2) : (Math.abs(revenue) / 1000000000).toFixed(2) }</p> }
                </Tooltip>
                <p className='currency-tail my-auto text-center'>{revenue.toString().length >= 7 ? revenue.toString().length >= 7 && revenue.toString().length <= 9 ? t("Million") : t("Billion") : <></>}</p>
              </div>
              
              <p className='title-tail'>{revenue >= 0  ? t("Profits") : t("Losses")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cards-dashboard row g-4  pt-3 mx-sm-2" >
          <div className="col-lg-6 px-2">
            {rentalUnits.length ? <div className="card-dashboard">
              <div>
                <Link to={"/requests"}><h5 className='fw-bolder'>{t("Rent Rate")}</h5></Link>
                <hr className='line-card' />
              </div>
              <div className='row align-items-center'>
                <div className='col-6'>
                  <p className='polets-primary'>{t("Vacant Units")}</p>
                  <ul className='info-card'>
                    <li><p>{t("Percentage")} : <span className='text-myPrimary'>{rentalUnitsData.percentVacantUnits.toFixed(2)}%</span></p> </li>
                    <li><p>{t("Units No.")} : <span className='text-myPrimary'>{rentalUnitsData.vacantUnitsCount}</span></p> </li>
                  </ul>
                  <p className='polets-secondry'>{t("Empty Units")}</p>
                  <ul className='info-card'>
                    <li><p>{t("Percentage")} : <span className='text-mySecondry'>{rentalUnitsData.percentEmptyUnitsCount.toFixed(2)}%</span></p> </li>
                    <li><p>{t("Units No.")} : <span className='text-mySecondry'>{rentalUnitsData.emptyUnitsCount}</span></p></li>
                  </ul>
                </div>   
                
                <div className='col-6'>
                  <RentalUnitsAnalysis data={rentalUnits} percentVacant={rentalUnitsData.percentVacantUnits} chartsDashContainerStyles={chartsDashContainerStyles} />
                </div>
              </div>
            </div> : <Skeleton variant="rounded" width={"100%"} height={"290px"} style={{borderRadius : "20px"}}/>}
          </div>
          <div className="col-lg-6 px-2">
            {unitsCosts.length ? <div className="card-dashboard">
              <Link to={"/costs"}><h5 className='fw-bolder'>{t("Unit Costs")}</h5></Link>
              
              <hr className='line-card' />
              <UnitsCostsAnalysis data={unitsCosts} chartsDashContainerStyles={chartsDashContainerStyles}/>
            </div> : <Skeleton variant="rounded" width={"100%"} height={"290px"} style={{borderRadius : "20px"}}/>}
          </div>
          <div className="col-lg-6 px-2">
            {costsItem.length ? <div className="card-dashboard">
              <Link to={"/costs"}><h5 className='fw-bolder'>{t("Cost Analysis")}</h5></Link>
              <hr className='line-card' />
              <div>
              <CostsItemAnalysis data={costsItem} chartsDashContainerStyles={chartsDashContainerStyles}/>
              </div>
            </div> : <Skeleton variant="rounded" width={"100%"} height={"290px"} style={{borderRadius : "20px"}}/>}
          </div>
          <div className="col-lg-6 px-2">
            {totalCosts.length ?<div className="card-dashboard">
              <div>
                <Link to={"/costs"}><h5 className='fw-bolder'>{t("Total Costs")}</h5></Link>
                <hr className='line-card' />
              </div>
              <div className='row align-items-center'>
                <div className='col-6 '>
                  <p className='polets-primary'>{t("Income")}</p>
                  <ul className='info-card'>
                    <li><p>{t("Percentage")} : <span className='text-myPrimary'>{totalCostsDate.percentCostIn.toFixed(2)}%</span></p> </li>
                    <li><p>{t("Total")} : <span className='text-myPrimary'>{totalCostsDate.totalCostsIn.toFixed(2)}{t("EGP")}</span></p> </li>
                  </ul>
                  <p className='polets-secondry'>{t("Expenses-Dashboard")}</p>
                  <ul className='info-card'>
                    <li><p>{t("Percentage")} : <span className='text-mySecondry'>{totalCostsDate.percentCostOut.toFixed(2)}%</span></p> </li>
                    <li><p>{t("Total")} : <span className='text-mySecondry'>{totalCostsDate.totalCostsOut.toFixed(2)}{t("EGP")}</span></p> </li>
                  </ul>
                </div>
                <div className='col-6'>
                  <TotalCostsAnalysis data={totalCosts} percentCostIn={totalCostsDate.percentCostIn} chartsDashContainerStyles={chartsDashContainerStyles} />
                </div>
              </div>
            </div> : <Skeleton variant="rounded" width={"100%"} height={"290px"} style={{borderRadius : "20px"}}/>}
          </div>
          <div className="col-12">
            {rentalActivity.length &&  rentalUnits.length?<div className="card-dashboard">
              <Link to={"/requests"}><h5 className='fw-bolder'>{t("Leasing Activity")}</h5></Link>
              <hr className='line-card' />
              <div>
              <RentalActivityAnalysis data={rentalActivity} chartsDashContainerStyles={chartsDashContainerStyles}/>
              </div>
            </div> : <Skeleton variant="rounded" width={"100%"} height={"290px"} style={{borderRadius : "20px"}}/>}
          </div>
          {/* <div className="col-12">
            <div className="card-dashboard">
              <Link to={"/requests"}><h5 className='fw-bolder'>أخر الطلبات</h5></Link>
              <hr  className='line-card' />
              <div>
              {rowData ? <RequestsDashboardTable theme={theme}  data={rowData.requests?.reverse()} tablesDashboardContainerStyle={tablesDashboardContainerStyle}/> : <></>}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card-dashboard">
              <Link><h5 className='fw-bolder'>أخر المهام</h5></Link>
              <hr  className='line-card' />
              <div>
              {rowData ? <TasksDashboardTable theme={theme} baseUrl={baseUrl} data={rowData.tasks?.reverse()} tablesDashboardContainerStyle={tablesDashboardContainerStyle}/> : <></>}
              </div>
            </div>
          </div> */}
          
        </div>
    </section>
      
    </>
    
  )
}
