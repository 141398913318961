import React, { useEffect, useState, useTransition } from "react";
import LogoSmallWhite from "../../img/LogoSmallWhite.png";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import joi from "joi";
import axios from "axios";
import Reload from "../Reload/Reload";
import { enqueueSnackbar } from "notistack";
import {
  constantsErrorMessage,
  constantsErrorSupportMessage,
  validationErrorMessage,
} from "../../constants/global";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function Login({ theme, baseUrl, getToken, setToken }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [userData, setUserData] = useState({
    userEmail: "",
    userPassword: "",
  });
  const [response, setResponse] = useState("pending");
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function getUserData(e) {
    // copy data
    const data = { ...userData };

    // update data
    data[e.target.id] = e.target.value;
    ////(data);

    //set data
    setUserData(data);
  }
  function validationUser() {
    const schema = joi
      .object()
      .required()
      .keys({
        userEmail: joi
          .string()
          .email({ tlds: false, minDomainSegments: 2 })
          .required(),
        userPassword: joi
          .string()
          .min(8)
          .required(),
      });
    return schema.validate(userData, { abortEarly: false });
  }
  async function responseLoginApi() {
    setResponse("pending");
    setIsLoading(true)
    const { data } = await axios({
      url: `${baseUrl}login`,
      method: "post",
      data: userData,
    });
    ////(data);
    if (data?.token && data.message === "success login") {
      const token = data.token;
      localStorage.setItem("token", token);
      const decoded = jwt_decode(token);
      localStorage.setItem("userName", decoded.userName);
      setToken(token);
      decoded.role.forEach((role) => {
        switch (role) {
          case "General Manager":
            navigate("/dashboard");
            break;
          case "System Admin":
            navigate("/dashboard");
            break;
          case "Follow-up Manager":
            navigate("/calender");
            break;
          case "Unit Manager":
            navigate("/units");
            break;
          case "Task Manager":
            navigate("/tasks");
            break;
          case "Cost Manager":
            navigate("/costs");
            break;
          default:
            break;
        }
      });
    } else if (data.message === "validation error") {
      enqueueSnackbar(
        <>
          {t(constantsErrorMessage)}
          <br />
          {t(validationErrorMessage)}
        </>,
        { variant: "error" }
      );
    } else if (
      data.message === "The required company account is not active." ||
      data.message === "The required company account does not exist."
    ) {
      enqueueSnackbar(
        <>
          {t(constantsErrorMessage)}
          <br />
          {t(data.message)}
          <br />
          {t(constantsErrorSupportMessage)}
        </>,
        { variant: "error" }
      );
    } else {
      enqueueSnackbar(
        <>
          {t(constantsErrorMessage)}
          <br />
          {t(data.message)}
        </>,
        { variant: "error" }
      );
    }
    setIsLoading(false);
  }
  function sendData(e) {
    e.preventDefault();
    const errorValidation = validationUser();
    if (errorValidation.error) {
      enqueueSnackbar(
        <>
          {t(constantsErrorMessage)}
          <br />
          {t(validationErrorMessage)}
        </>,
        { variant: "error" }
      );
      return 0;
    } else {
      responseLoginApi();
    }
  }

  return (
    <>
      <section className="d-none d-lg-flex justify-content-center align-items-center">
        <div className="parent-login w-100">
          <ThemeProvider theme={theme}>
            <form>
              <div className="d-flex parent-card-login justify-content-center">
                <div className="info-card-login">
                  <h2 className="fw-bolder fs-4 title-login">{t("Log In")}</h2>
                  <hr className="line-card w-100" />
                  <div className="my-3">
                    <div>
                      <TextField
                        id="userEmail"
                        className="textfield-input"
                        style={{ width: "85%", }}
                        onChange={getUserData}
                        label={t("Email")}
                        variant="standard"
                        type="text"
                      />
                    </div>
                    <div className="text-helper-invalid d-none">
                      {t("Please enter the email address.")}
                    </div>
                  </div>
                  <div className="my-3">
                    <FormControl sx={{ m: 0, width: "85%" }} variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">
                        {t("Password")}
                      </InputLabel>
                      <Input
                        id="userPassword"
                        type={showPassword ? "text" : "password"}
                        onChange={getUserData}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="text-start pt-2">
                    <Button
                      variant="contained"
                      className="btn-myPrimary-login "
                      type="submit"
                      onClick={sendData}
                      disabled={isLoading}
                    >
                      {!isLoading ? t("Log In") : <>
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress color="inherit" size={"24px"}/>
                      </Box>
                    </> }
                    </Button> 
                    
                  </div>
                </div>
                <div className="w-100">
                  <div className="logo-login w-100 h-100">
                    <img className="w-100 mt-5" src={LogoSmallWhite} alt="" />
                  </div>
                </div>
              </div>
            </form>

          </ThemeProvider>
        </div>
      </section>

      <section className="d-lg-none">
        <div className="parent-login-mobile">
          <ThemeProvider theme={theme}>
            <form>
              <div className="login-mobile-1 ">
                <div className="logo-login-mobile d-flex align-items-center justify-content-center my-4">
                  <img className=" logo-mobile" src={LogoSmallWhite} alt="" />
                </div>
              </div>
              <div className="login-mobile-2 ">
                <div className="info-card-login-mobile">
                  <h2 className="fw-bolder fs-1 title-login">{t("Log In")}</h2>
                  <hr className="line-card w-100" />
                  <div className="my-3 px-2">
                    <TextField
                      id="userEmail"
                      className="textfield-input"
                      style={{ width: "100%" }}
                      label={t("Email")}
                      onChange={getUserData}
                      variant="standard"
                      type="text"
                    />
                  </div>
                  <div className="my-3 px-2">
                    <FormControl sx={{ m: 0, width: "100%" }} variant="standard">
                      <InputLabel htmlFor="userPassword">
                        {t("Password")}
                      </InputLabel>
                      <Input
                        id="userPassword"
                        type={showPassword ? "text" : "password"}
                        onChange={getUserData}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="text-start pt-3">
                      <Button
                        variant="contained"
                        className="btn-myPrimary-login"
                        onClick={sendData}
                        type="submit"
                        disabled={isLoading}
                      >
                        {!isLoading ? t("Log In") : <>
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress color="inherit" size={"24px"}/>
                      </Box>
                    </> }
                      </Button>
                  </div>
                </div>
              </div>
            </form>

          </ThemeProvider>
        </div>
      </section>
    </>
  );
}
