import {faCalendarDays, faPenToSquare, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { Link } from 'react-router-dom';

import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import AddOwner from '../AddOwner/AddOwner';
import ConfirmedMessage from '../ConfirmedMessage/ConfirmedMessage';
import { useApi } from '../Store/ApiContext';
import { useTranslation } from 'react-i18next';

export default function Unites({theme, togglePopUp, popupClose,  dataRow, setDataRow, tableContainerStyle}) {

  const {confirmRemove, getData, quickEditTable} = useApi();
  const {t, i18n} = useTranslation();

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const filterTextBox = useRef(); //  input text box
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [numberOfRows, setNumberOfRows] = useState(20);

  const [userDataRow, setUserDataRow] = useState('');
  const [idUnitRow, setIdUnitRow] = useState('')
 
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {field: 'unitCode', headerName : t("Unit Code"), width:100},
    {field: 'unitName', headerName : t("Unit Name"), width:300},
    {field: 'unitType', headerName : t("Unit Type"), width:150, 
      cellRenderer: function(params) {
        return t(params.data.unitType)
      }
    },
    {field: 'country', headerName : t("Governorate"), width:150, 
      cellRenderer: function(params) {
        return t(params.data.country)
      }
    },
    {field: 'bedrooms', headerName : t("Rooms No."), width:100},
    {field:  'costInterval', headerName : t("Cost Duration"), width:100, 
    cellRenderer: function(params) {
      return params.data.costInterval === "night" ? t("Per Night") : t("Per Month")
    }},
    {field: 'cost', headerName : t("Cost"), width:100, editable : true, 
      valueParser: params => Number(params.newValue)
    },
    {field: '', headerName : t("Settings"), width:150, 
     cellRenderer: function(params) {
      return <span> 
        <Link to={"/units/edit-unit"}><FontAwesomeIcon className='icon-settings-table cursor-pointer fs-6' onClick={() => {onSelectionChanged();}} icon={faPenToSquare} /></Link>
        <Link to={"/units/available-time/" + params.data._id}><FontAwesomeIcon className='mx-2 icon-settings-table cursor-pointer fs-6' onClick={() => {onSelectionChanged();}} icon={faCalendarDays} /></Link>
        <FontAwesomeIcon className='icon-settings-table fs-6 cursor-pointer' icon={faTrashCan} onClick={() => confirmRemove(params.data._id, setIdUnitRow)}/>
      </span>
    }},
  ]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({
      // flex: 1,
      minWidth: 100,
      sortable: true,
      // filter: true,
      resizable: true,
    }),[]);

  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
    ////('cellClicked', event);
  }, []);

    // selection rows
    const onSelectionChanged = useCallback(() => {
      let selectedRows = gridRef.current.api.getSelectedRows();
      ////("fen", selectedRows[0]);
      localStorage.setItem("data", JSON.stringify(selectedRows[0]))
      // setDataRow(selectedRows[0]);
    }, []);

    // filter table
    const onFilterTextBoxChanged = useCallback((e) => {
      gridRef.current.api.setQuickFilter(
        e.target.value
      );
    }, []);

   // Example load data from server
  useEffect(() => {
    // add title page
    document.title = t("Units");
    // get data
    getData(setRowData, 'unit');
  }, []);

  const saveStatus = (id, newData) => {
    quickEditTable('unit', newData, id);
  };

  const onCellValueChanged = useCallback((params) => {
    let newData = {}
    newData[params.column.getId()] = params.newValue;
    saveStatus(params.data._id, newData);
  }, []);

  const handleChangeOfNumbers = (event) => {
    setNumberOfRows(event.target.value);
  };

  return (
    <>
      <section className='bg-babyblue-50 big-card container-fluid'>
        
        <div className="header-card d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h2 className='fw-bolder'>{t("Units")}</h2>
          </div>
          <div>
            <Link to={"/units/add-unit"}><Button variant="contained" className='btn-request'><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>{t("Add unit")}</Button></Link>
          </div>
        </div>
        <div className='table-unites pt-4 pb-3 px-2 mx-3'>
          {/* Example using Grid's API */}

          {/* Search table and inputs number of rows */}
        { rowData ? <>
          <div className="header-table mb-3 d-flex justify-content-between align-items-center">
            <ThemeProvider theme={theme}>
              <div className="inputs-number-rows">
              
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">{t("Entries No.")}</InputLabel>
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={numberOfRows}
                    label={t("Entries No.")}
                    onChange={handleChangeOfNumbers}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={70}>70</MenuItem>
                    <MenuItem value={80}>80</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              
              </div>
              <div className='header-table-search'>
                <TextField id="filter-text-box" label={t("Search...")} variant="standard" ref={filterTextBox} onInput={onFilterTextBoxChanged}  />
              </div>
            </ThemeProvider>
          </div>

          {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
          <div className="ag-theme-alpine ag-root-wrapper" style={tableContainerStyle}>

            <AgGridReact
                enableRtl={i18n.language === 'ar' ? true : false}
                ref={gridRef} // Ref for accessing Grid's API
          

                rowData={rowData} // Row Data for Rows
                onCellValueChanged={onCellValueChanged}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties

                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='multiple' // Options - allows click selection of rows

                // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                paginationPageSize={numberOfRows}
                pagination={true}
                rowHeight={30}
                />
          </div>
          </> : <div className=''>
            <div className='d-flex justify-content-between mb-2'>
              <Skeleton variant="rounded" width={"150px"} height={"50px"} />
              <Skeleton variant="rounded" width={"200px"} height={"50px"} />
            </div>
            <Skeleton variant="rounded" width={"100%"} height={"70vh"} />
          </div>}
          

        </div>

      </section>

    <div className='add-confirm'>
      <ConfirmedMessage customTheme={theme} togglePopUp={togglePopUp} popupClose={popupClose} id={idUnitRow} endPoint={"unit"} setRowData={setRowData} />
    </div>

    
    </>
  )
}
