import React, { useState } from "react";
import AvailableTimeCalender from "../AvailableTimeCalender/AvailableTimeCalender";
import {
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Button
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import joi from "joi";
import { enqueueSnackbar } from "notistack";
import { constantsErrorMessage, validationErrorMessage } from "../../constants/global";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { DateRangePicker } from "react-dates";
import { useTranslation } from "react-i18next";

export default function AvailableTime({ baseUrl }) {
  
  const params = useParams();
  const {t, i18n} = useTranslation();

  const [availableTimeList, setAvailableTimeList] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);

  const [data, setData] = useState({
    available: true,
    price: 0,
    startDate: "",
    endDate: "",
    unit: params.id,
  });

  function handleChange(e, id, newValue) {
    let myData = { ...data };
    if (id === "available") {
        myData[id] = (e.target.value === 'true')
    }else if (id === "date") {
        console.log(e);
        myData["startDate"] =  moment(e.start).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
        myData["endDate"] =  moment(e.end).subtract(1, "day").utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
    }else if (id === "pickCalenderDate") {
      console.log(e);
      myData["startDate"] =  moment(e.startDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
      myData["endDate"] =  moment(e.endDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
    } else {
      myData[e.target.id] = e.target.value;
    }
    setData(myData);
    console.log(myData);
  }

  function validationUnitData(newData) {

    const schema = joi.object().required().keys({
      available : joi.boolean().required(),
      price : joi.number().required(),
      startDate : joi.string().required(),
      endDate : joi.string().required(),
      unit : joi.string().length(24).required(),
    });
    return  schema.validate(newData, {abortEarly : false})
  }

  async function addAvailableTime() {
    const token = localStorage.getItem("token");
    const validation = validationUnitData(data)
    if (validation) {
      const res = await axios({
        method : "post",
        url : `${baseUrl}availableTime`,
        headers : {
          'Content-Type': 'application/json', 
          "authorization" : `Bearer ${token}`,
        },
        data : data,
      })
      setEvents()
      if (res.data.message === "success") {
        return res.data.results
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      }
    } else {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
    }
  }

  async function getAllAvailableTime() {
    const token = localStorage.getItem("token");
    const {data} = await axios({
      method : "get",
      url : `${baseUrl}availableTime/${params.id}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    
    return data.results
  }

  async function setEvents() {
    const data = await getAllAvailableTime();
    console.log(data);
    const myEventsList = []
    data.forEach(availableTime => {
      myEventsList.push({
        title: !availableTime.available ? `${availableTime.price ? availableTime.price + " - " : ""}  ${t("Closed")}` : `${availableTime.price ? availableTime.price + " - " : ""}  ${t("Available")}`,
        start: new Date(availableTime.startDate),
        end: new Date(availableTime.endDate),
        type: 'appointment',
        colorEvento : !availableTime.available ? "#CC3F0C" : "#0FAC83"
      })
    });
    setAvailableTimeList(myEventsList)
  }

  useEffect(() => {
    document.title = t("Available Time");
    setEvents()
  }, [])

  return (
    <>
      <section id="availableTimeCalender" className="bg-babyblue-50 big-card container-fluid">
        <div className="header-card d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h2 className="fw-bolder">{t("Available Time")}</h2>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-9">
            <div className=" calender-table m-3">
              <AvailableTimeCalender baseUrl={baseUrl} handleChange={handleChange} getAllAvailableTime={getAllAvailableTime} availableTimeList={availableTimeList}/>
            </div>
          </div>
          <div className="col-3 settings-calender">
            <div className="p-2 my-2 settings-calender-card">
              <div id='date' dir={t("dir")}  className='me-3 w-100 ' >
                <DateRangePicker
                  isRTL={i18n.language === "ar" ? true : false }
                  startDate={data?.startDate ? moment(data.startDate) : null}
                  endDate={data?.endDate ? moment(data.endDate) : null  }
                  onDatesChange={(date) => { handleChange(date, "pickCalenderDate");}}
                  focusedInput={focusedInput}
                  startDateId='startDate'
                  endDateId='endDate'
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  startDatePlaceholderText={t("From")}
                  endDatePlaceholderText={t("To")}
                  displayFormat="YYYY/MM/DD"
                  // customArrowIcon={"-"}
                  minimumNights={1}
                  numberOfMonths={window.innerWidth > 700 ? 2 : 1}
                  withPortal
                  isOutsideRange={() => {}}
                  hideKeyboardShortcutsPanel
                  reopenPickerOnClearDates
                  noBorder 
                  block
                />
              </div>
            </div>
            <div className="p-2 my-2 settings-calender-card">
                <div>
                    
                </div>
                <div>
                    <FormControl>
                      <RadioGroup
                          id="available"
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={data?.available}
                          onChange={(e) => handleChange(e, "available")}
                      >
                          <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t("Available")}
                          />
                          <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t("Not Available")}
                          />
                      </RadioGroup>
                    </FormControl>
                </div>

            
                <div className="p-2">
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">
                    {t("Total Cost")}
                    </InputLabel>
                    <Input
                    type="number"
                    inputProps={{ min: 0 }}
                    id="price"
                    onChange={handleChange}
                    value={data?.price}
                    endAdornment={
                        <InputAdornment position="end"> {t("EGP")} </InputAdornment>
                    }
                    />
                </FormControl>
                </div>
                <div className='text-start pt-4 pb-3'>
                    {data.endDate ? <Button variant="contained" onClick={addAvailableTime} className='btn-myPrimary' >{t("Confirm")}</Button> : <Button variant="contained" className='btn-myPrimary opacity-50' >{t("Confirm")}</Button>}
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
