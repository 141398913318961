const validationErrorMessage = "Please enter the data correctly.";
const addSuccessMessage = "Successfully added.";
const editSuccessMessage = "Successfully modified.";
const removeSuccessMessage = "Successfully deleted.";
const constantsErrorMessage = "Error";
const constantsErrorSupportMessage = "Please contact the support team at support@egarhub.com.";
const constantsErrorServerDataMessage = "Please verify the data or contact for assistance.";
const percentageValidationMessage = "Percentage values must add up to 100%."
const changeSuccess = "Successfully changed."
const changeError = "Change failed."

export {
    validationErrorMessage,
    addSuccessMessage,
    editSuccessMessage,
    removeSuccessMessage,
    constantsErrorMessage,
    constantsErrorSupportMessage,
    constantsErrorServerDataMessage,
    percentageValidationMessage,
    changeSuccess,
    changeError
}