import { ThemeProvider,TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import joi from 'joi';
import axios from 'axios';
import $ from 'jquery';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ErrorMessageServer from '../ErrorMessageServer/ErrorMessageServer';
import { useApi } from '../Store/ApiContext';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, validationErrorMessage } from '../../constants/global';
import { enqueueSnackbar } from 'notistack';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

export default function AddClient({customTheme, togglePopUp, popupClose, dataRow, setDataRow, setRowData}) {
  
  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();

  const [clientData, setClientData] = useState({
    clientName : '',
    clientPhone : '',
    paragraph : '',
  })

  function handleChange(e, id, newValue) {
      let myData
      dataRow ? myData = {...dataRow} : myData = {...clientData}
      if (id === "clientPhone") {
        myData[id] = newValue;
      }else {
        myData[e.target.id] = e.target.value;
      }
      ////(myData);
      dataRow ? setDataRow(myData) : setClientData(myData)
  }

  function clearInputsDataRow() {
    if (dataRow) {
      setDataRow('');
    }else {
      setClientData({
        clientName : '',
        clientPhone : '',
        paragraph : '',
      })
    }
  }

  function validationClientData(newData) {
    const schema = joi.object().required().keys({
      clientName :  joi.string().regex(/^([a-zA-Zأ-يء]+\s[a-zA-Zأ-يء]+){2,}$/).required(),
      clientPhone : joi.string().length(13).required(),
      paragraph : joi.string().min(0).max(50),
    });
    return  schema.validate(newData, {abortEarly : false})
  }


  async function addData() {
    const validationData = validationClientData(clientData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('post', clientData, "client", dataRow);
      if (res.message === "success") {
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "client");
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }
  async function editData() {
    const newData = {
        clientName : dataRow.clientName,
        clientPhone : dataRow.clientPhone,
        paragraph : dataRow.paragraph,
    }
    const validationData = validationClientData(newData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('put', newData, "client", dataRow._id );
      if (res.message === "success") {
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "client");
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }


  
  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
        }
      }}>
        <div className='lightContainer scrollbar-popup-style' >
        <ThemeProvider theme={customTheme} >
          <ul className=' row'>
          <li className="col-12">
                <div className='p-2 '>
                  <TextField id='clientName' className='textfield-input' label={t("Full Name")} value={dataRow ? dataRow.clientName : clientData.clientName } onChange={handleChange} variant="standard"  type="text" />
                </div>
                <div className="text-helper-invalid d-none" >{t("Please enter the full name.")}</div>
              </li>
              <li className="col-12">
                <div className='p-2 '>
                  <MuiTelInput defaultCountry='EG' dir='ltr' MenuProps={{dir : "ltr"}} disableFormatting id='clientPhone' className='textfield-input' label={t("Phone Number")} value={dataRow ? dataRow.clientPhone : clientData.clientPhone }  onChange={(newValue) => handleChange("", "clientPhone", newValue)} variant="standard"  type="tel"/>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the phone number. Maximum 13 digits.")}</div>
              </li>
              <li className="col-12">
                <div className='p-2 '>
                  <TextField id='paragraph' className='textfield-input' value={dataRow ? dataRow.paragraph : clientData.paragraph } label={t("Additional Information")}  onChange={handleChange} variant="standard"  type="text" />
                </div>
              </li>
          </ul>
          <div className='text-start mt-2'>
            <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() =>{ togglePopUp("add-popup"); clearInputsDataRow(); showInvalidFeedback([])}}>{t("Back")}</Button>
            <Button variant="contained" className='btn-myPrimary' onClick={ () => dataRow ? editData()  : addData() }>{t("Confirm")}</Button>
          </div>
        </ThemeProvider>
    </div>
    </div>
    </>
  )
}
