import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ToggleButton({setNewClient, newClient}) {
  const {t, i18n} = useTranslation();

  return (
    <>
    <div className="btn-container my-2">
	
    <label className="switch btn-color-mode-switch">
        <input value="1" id="color_mode" name="color_mode" type="checkbox" checked={newClient} onClick={(e) => {setNewClient(e.target.checked)}}/>
        <label className="btn-color-mode-switch-inner" data-on={t("New customer")} data-off={t("Old customer")}  for="color_mode"></label>
    </label>

    </div>
    </>
  )
}
