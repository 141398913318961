import { faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoMobile from '../../img/LogoSmallColored-nav.png';
import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import SearchPopup from '../SearchPopup/SearchPopup';
import { Autocomplete, TextField, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export default function NavBar({theme ,hideMainMenu, decodeToken}) {

  const {t, i18n} = useTranslation()

  const [orgPhone, setOrgPhone] = useState("")
    const pages = [
      {label : t("Home"), value : "dashboard"},
      {label : t("Renting Timeline"), value : "calender"},
      {label : t("Units"), value : "units"},
      {label : t("Requests"), value : "requests"},
      {label : t("Cost"), value : "costs"},
      {label : t("Tasks"), value : "tasks"},
      {label : t("Owners"), value : "owners"},
      {label : t('Customers'), value : "clients"},
      {label : t("Users"), value : "users"},
      {label : t("Settings"), value : "settings"},
    ];
    const [pageValue, setPageValue] = useState('');
    const [pageInputValue, setPageInputValue] = useState('');
    const navigate = useNavigate()

  function showMainMenu() {

    $(".main-menu-mobile").removeClass( i18n.language === "ar" ? " animate__animated animate__faster animate__slideOutRight d-inline-block " : " animate__animated animate__faster animate__slideOutLeft d-inline-block ");
    $(".main-menu-mobile").addClass(i18n.language === "ar" ? " animate__animated animate__faster animate__slideInRight d-inline-block " : " animate__animated animate__faster animate__slideInLeft d-inline-block ");
    $(".layer-mobile").removeClass("d-none");
  }

  function showSearchPopup() {
    $(".search-popup").removeClass("d-none")
  }

  function navigatePages(page) {
    if (page) {
      navigate(`/${page}`);
      setPageValue("")
      setPageInputValue("")
    }
  }
  function handleChange(e) {
    ////(e.target.value);
    setPageValue(e.target.value);
  }

  useEffect(() => {
    setOrgPhone(decodeToken()?.orgPhone?.slice(2,));
  }, [])
  
  return (
    <>
    
      {localStorage.getItem("token") ? <><nav>
      <ThemeProvider theme={theme}>
        <div className='bg-darkPrimary parent-nav-bar'>
            <div className="nav-bar d-flex justify-content-between align-items-center py-3">
              <div className='mainMenu-logo-mobile d-flex d-xl-none ' onClick={showMainMenu}>
                <div className='my-auto me-2 menu-icon'>
                  <FontAwesomeIcon className='text-mySecondry fs-5' icon={faEllipsisVertical} />
                </div>
                <div className=''>
                  <img src={logoMobile} alt="logo" className='w-75' />
                </div>
              </div>
              <div className='search d-none d-sm-inline-block' onClick={hideMainMenu}>
                
                  <Autocomplete
                    freeSolo
                    disableClearable
                    value={pageValue}
                    onChange={(event, newValue) => {
                      handleChange(event);
                      navigatePages(newValue?.value)
                      
                    }}
                    isOptionEqualToValue={(option, value) => 
                      option.label === value.label
                    }
                    inputValue={pageInputValue}
                    onInputChange={(event, newInputValue) => {
                      setPageInputValue(newInputValue);
                    }}
                    id="pages"
                    options={pages}
                    sx={{ width: "250px", backgroundColor : "#EFF3FB", paddingInlineStart : "40px", borderRadius : "8px", }}
                    renderInput={(params) => <TextField type='text'  className='' sx={{padding : "0px",}}  placeholder={t("Search")}  {...params}/>}
                  />
                
                
                  {/* <input type="text" className='search-bar' placeholder='بحث' /> */}
                  <div className='icon-input'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
              </div>
              <ul className='d-flex align-items-center justify-content-center'>
                <li className='icon-primary me-3 d-inline-block d-sm-none cursor-pointer' onClick={() => {showSearchPopup();}}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </li>
                <li className='me-4 cursor-pointer'> 
                <a href={`https://wa.me/${orgPhone}`} target="blank"><i className="fa-brands fa-whatsapp icon-primary"></i> </a>
                  
                </li>
              </ul>
            </div>
            
        </div>
      </ThemeProvider>
    </nav>

    <div className='search-popup d-none'>
      <SearchPopup pages={pages}/>
    </div>
    
    </> : <></>}
    </>
    
  )
}
