import React from 'react'
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useState } from 'react';
import { useEffect } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function SuccessMessage({message, showSuccess, setShowSuccess}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }else if (reason === 'timeout') {
        setShowSuccess(false)
    }

    setOpen(false);
  };

  useEffect(() => {
    if (showSuccess) {
        handleClick()
    }
  }, [showSuccess])
  

  return (
    <div className='successMessage'>
    <Stack spacing={2} sx={{ width: '100%' }}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{vertical : "top" , horizontal  : "left"}}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%'}}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
    </div>
    
  );
}