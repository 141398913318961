import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTranslation } from 'react-i18next';
// @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');


export default function UnitsCostsAnalysis({data, chartsDashContainerStyles}) {

    const {t, i18n} = useTranslation()
    useLayoutEffect(() => {
      ////(data);
    if (data.length) {
    ////("hii");
    let root = am5.Root.new("chartdiv");            
    root.setThemes([
        am5themes_Animated.new(root)
    ]);
        // create charts
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "panX",
        layout: root.verticalLayout,
      }));

      const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fontFamily:"'Cairo', sans-serif",
      direction: 'rtl'
    });
    root._logo.dispose();

    root.setThemes([
      myTheme
    ]);


    // chart.colors.set(am5.ColorSet.new({ colors: ["#FF0000", "#00FF00", "#0000FF"] }));

    //   root.container.set('fontFamily', 'Cairo');

      // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  var legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
    })
  );
legend.itemContainers.template.setAll({
  reverseChildren : true,
})
//   var data = [{
//     "unitCode": "2021",
//     "europe": 2.5,
//     "namerica": 2.5,
//     "asia": 2.1,
//     "lamerica": 1,
//     "meast": 0.8,
//     "africa": 0.4
//   }, {
//     "unitCode": "2022",
//     "europe": 2.6,
//     "namerica": 2.7,
//     "asia": 2.2,
//     "lamerica": 0.5,
//     "meast": 0.4,
//     "africa": 0.3
//   }, {
//     "unitCode": "2023",
//     "europe": 2.8,
//     "namerica": 2.9,
//     "asia": 2.4,
//     "lamerica": 0.3,
//     "meast": 0.9,
//     "africa": 0.5
//   }]
  

    // // Add cursor
    // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    //     behavior: "zoomX"
    // }));
    // cursor.lineY.set("visible", false);

    // Create axes
  var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance : 15
    })
  
  var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "unitCode",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
  }));

  xRenderer.grid.template.setAll({
      location: 1,
  })
  xRenderer.labels.template.setAll({
    rotation: -90,
    centerY : am5.p50,
  })
  

  xAxis.data.setAll(data);

  var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    })
  }));

       // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function makeSeries(name, fieldName) {
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: fieldName,
      fill : name === "مدخلات" ? am5.color("#074acc") : am5.color("#FF9E01") ,
      categoryXField: "unitCode",
    }));
    ////(name);


    series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(100),
        tooltipY: 0,
        strokeOpacity: 0,
        cornerRadiusTL: 20,
        cornerRadiusTR: 20,
    });

    series.data.setAll(data);
    // series.set("fill", am5.color(0xff0000));

    // root.defaultTheme.rule("ColorSet").set("colors", [
    //   am5.color(0x095256),
    //   am5.color(0x087f8c),
    //   am5.color(0x5aaa95),
    //   am5.color(0x86a873),
    //   am5.color(0xbb9f06)
    // ]);


    // chart.get("colors").set("colors", [
    //   am5.color("#074acc"),
    //   am5.color("#FF9E01"),
    //   am5.color("#0FAC83"),
    //   am5.color("#CC3F0C"),
    //   am5.color("#18BECB"),
    // ]);

    
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear(1000);

  series.bullets.push(function() {
    return am5.Bullet.new(root, {
      locationY: 0,
      sprite: am5.Label.new(root, {
        text: "{valueY}",
        centerY: 0,
        centerX: am5.p50,
        populateText: true,
        fontFamily : "cairo, sans-serif",
      })
    });
  });
  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 10,
    cornerRadiusTR: 10,
    cornerRadiusBL: 10,
    cornerRadiusBR: 10,
  });
  
  // legend.data.setAll(series.dataItems);
  legend.data.push(series);
  }

    makeSeries("مدخلات", "costIn");
    makeSeries("مصروفات", "costOut");

         // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    //   chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    // }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      if (root) {
          root.dispose();
      }
    }
  }

    
}, [data])

  return (
    <>
    <section>
        <div className="container h-100">
            <div id="chartdiv" style={chartsDashContainerStyles}></div>
        </div>
    </section>
    </>
  )
}
