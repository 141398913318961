import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect } from 'react';
import CountUp from 'react-countup';
// @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');


export default function RentalUnitAnalysis({data, percentVacant, chartsDashContainerStyles}) {

  useEffect(() => {
    ////(data);
    if (data.length) {
    ////("hii");
    let rootPie = am5.Root.new("chartPieRentalDiv");            
    rootPie.setThemes([
        am5themes_Animated.new(rootPie)
    ]);

  var chart = rootPie.container.children.push(
    am5percent.PieChart.new(rootPie, {
    radius: am5.percent(90),
    innerRadius: am5.percent(50),
  })
  );

  const myTheme = am5.Theme.new(rootPie);

    myTheme.rule("Label").setAll({
      fontFamily:"'Cairo', sans-serif",
      direction: 'rtl'
    });
  
    rootPie.setThemes([
      myTheme
    ]);

  // Create series
  var series = chart.series.push(
  am5percent.PieSeries.new(rootPie, {
    name: "Series", 
    valueField: "unitsCount",
    categoryField: "rental",
    // fill : am5.color("#18BECB"),
    innerRadius : 20,
  })
  );

  series.get("colors").set("colors", [
    am5.color("#074acc"),
    am5.color("#FF9E01"),
  ]);

  series.data.setAll(data);
  rootPie._logo.dispose();
  // Play initial series animation
  // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
  series.appear(1000, 100);

  // Disabling labels and ticks
  series.labels.template.set("visible", false);
  series.ticks.template.set("visible", false);

  return () => {
      if (rootPie) {
          rootPie.dispose();
      }
  }
      }

    
}, [data])

  return (
    <>
    <section>
        <div className="container h-100 position-relative" dir='ltr'>
            <div id="chartPieRentalDiv" style={chartsDashContainerStyles}></div>
            <div className='count-chart'><CountUp end={percentVacant} duration={percentVacant / 10}/>%</div>
        </div>
    </section>
    </>
  )
}
