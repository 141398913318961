import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, Trans } from "react-i18next";
import i18n from "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap-utilities.rtl.min.css'
// import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import "@fortawesome/fontawesome-free/css/all.min.css"
import 'jquery/dist/jquery.js';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './css/style.css';
import "./css/media.css";


import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBloI5pzqx5peRFk43T6vNq59svofigSpQ",
  authDomain: "prokfy-admin.firebaseapp.com",
  projectId: "prokfy-admin",
  storageBucket: "prokfy-admin.appspot.com",
  messagingSenderId: "267007780437",
  appId: "1:267007780437:web:907cc1dba7a8ac6fd896dc",
  measurementId: "G-RSD6J7Q1BJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Trans>
        <App />
      </Trans>
    </I18nextProvider>
  </BrowserRouter>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


serviceWorkerRegistration.register();
