import React, { useEffect, useState } from 'react';
import ImageUploader from 'react-images-upload';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useApi } from '../Store/ApiContext';

const UploadImages = ({unitCode, data, setData}) => {

  const {getSlug} = useApi()
  const [uploadedMediaUrls, setUploadedMediaUrls] = useState([]);

  const handleImageUpload = async (images) => {
    const files = (images.target.files);
    // try {
      const slug = getSlug();
      const storage = getStorage();
      const storageRef = ref(storage, `unitsMedia/${slug}/${unitCode}`); //unitsMedia is the folder we will upload media to it
      const uploadedMediaUrl = [];

      for (let i = 0; i < files.length; i++) {
        if (files[i].size <= 5242880) {
          const image = files[i];
          const imageRef = ref(storageRef, image.name);
          await uploadBytes(imageRef, image);

          const downloadURL = await getDownloadURL(imageRef);
          // Store the downloadURL in your database
          // console.log('Download URL:', downloadURL.split('?')[0] + "?alt=media");
          uploadedMediaUrl.push(downloadURL.split('?')[0] + "?alt=media")
        }
        setUploadedMediaUrls([...uploadedMediaUrls, ...uploadedMediaUrl,]);
      }
      // setUploadedMediaUrls([...uploadedMediaUrls, ...uploadedMediaUrl,]);
    // } catch (error) {
    // }
  };

  async function handleImageRemoveStorage(image) {
    const imageName = image.split('%2F').reverse()[0].split("?")[0]
    const storage = getStorage();
    const slug = getSlug();
    const desertRef = ref(storage, `unitsMedia/${slug}/${unitCode}/${imageName}`);

    // Delete the file
    try {
      await deleteObject(desertRef)
    } catch (error) {
      // error
    }
    

    const myUrls = [...data.media]
    for (let i = 0; i < myUrls.length; i++) {
      if (myUrls[i].split('%2F').reverse()[0].split("?")[0] === imageName) {
        myUrls.splice(i, 1);
      }
    }
    setUploadedMediaUrls(myUrls);
  }

  // const handleImageRemove = (image) => {
  //   console.log(image.split('%2F').reverse()[0].split("?")[0]);
  //   const storage = getStorage();
  //   const slug = getSlug();
  //   console.log(slug);
  //   const desertRef = ref(storage, `unitsMedia/${slug}/${unitCode}/${image.name}`);

  //   // Delete the file
  //   deleteObject(desertRef).then(() => {
  //   // File deleted successfully
  //   const myFiles = [...uploadedImages]
  //   for (let i = 0; i < myFiles.length; i++) {
  //     if (myFiles[i].name === image.name) {
  //       myFiles.splice(i, 1);
  //     }
  //   }
  //   setUploadedImages(myFiles)
  //   const myUrls = [...uploadedMediaUrls]
  //   for (let i = 0; i < myUrls.length; i++) {
  //     if (myUrls[i].split('%2F').reverse()[0].split("?")[0] === image.name) {
  //       console.log(myUrls[i]);
  //       myUrls.splice(i, 1);
  //     }
  //   }
  //   setUploadedMediaUrls(myUrls)
  //   }).catch((error) => {
  //   // Uh-oh, an error occurred!
  //   });
  // };
  
  useEffect(() => {
    if (data.media.length) {
      setUploadedMediaUrls(data.media)
    }
  }, [])

  useEffect(() => {
      let myData = {...data}
      myData.media = uploadedMediaUrls
      setData(myData)
      console.log(myData.media);
  }, [uploadedMediaUrls])
  
  return (
    <div>
      <div className='text-center '>
        <p className='label-upload'>الحد الأقصى لحجم الملف: 5 ميغا بايت</p>
        <input id='uploadMedia' multiple accept="video/*, image/*"  type='file' className='inputfile' onChange={handleImageUpload}/>
        <label htmlFor="uploadMedia" className='py-3'><FontAwesomeIcon className='icon-upload-image' icon={faUpload} /></label>
      </div>
      
      {/* <ImageUploader
        withIcon={false}
        // onChange={(e) => {handleFiles(e)}}
        withPreview={true}
        accept="video/*, image/*"
        imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.mp4', ".webp"]}
        maxFileSize={5242880}
        label="الحد الأقصى لحجم الملف: 5 ميغا بايت ، مقبول: jpg | jpeg | png | mp4"
        buttonText={<FontAwesomeIcon className='icon-upload-image' icon={faUpload} />}
        buttonClassName="btn-upload-files"
      /> */}
      {/* <div className='row'>
        {uploadedImages.map((image, index) => (
          <div className='col-md-3 image-preview' key={index}>
            <img className='w-100' src={URL.createObjectURL(image)} alt="uploaded" />
            <button className='icon-delete-image' onClick={() => handleImageRemove(image)}><FontAwesomeIcon icon={faXmark} /></button>
          </div>
        ))}
      </div> */}
      <div className='row g-4'>
        {data?.media?.map((url, index) => (
          <div className='col-md-3 image-preview' key={index}>
            <img className='w-100' src={url} alt="uploaded" />
            <button className='icon-delete-image' onClick={() => handleImageRemoveStorage(url)}><FontAwesomeIcon icon={faXmark} /></button>
          </div>
        ))}
      </div>
      {/* <button onClick={handleImageSubmit}>Submit Images</button> */}
    </div>
  );
};

export default UploadImages;