import i18n from "i18next";
import XHR from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "./locales/en/translation.json";
import translationAr from "./locales/ar/translation.json";
import jwt_decode from "jwt-decode";

let token = localStorage.getItem("token")
if (token) {
  var languageForce = localStorage.getItem("languageForce")
  var {userLang, orgLang} = jwt_decode(token);
};

i18n.use(XHR).init({
  // we init with resources
  resources: {
    en: {
      translations: translationEn,
    },
    ar: {
      translations: translationAr,
    },
  },
  lng: languageForce ? languageForce : userLang ? userLang : orgLang,
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    // formatSeparator: ","
  },

  // react: {
  //   wait: true
  // }
});

export default i18n;
