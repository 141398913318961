import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import xmlIcon from '../../img/icons/xml.png';
import { useTranslation } from "react-i18next";

export default function ConvertToXLSX({rowData}) {

  const {t, i18n} = useTranslation()

  /* get state data and export to XLSX */
  const exportFile = useCallback(() => {
    if (rowData) {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(rowData);
      // Set the width of a specific column (e.g., column B)
      const colWidth = { wpx: 100 }; // Width in pixels
      ws['!cols'] = [ colWidth, colWidth, colWidth, colWidth, colWidth, colWidth ]; // Set width for the first column
      utils.sheet_add_aoa(ws, [[t("Trans Number"), t("Trans Nature"), t("Trans Type"), t("Trans Value"), t("Execution Date"), t("Date Added")]], { origin: "A1" });
      utils.book_append_sheet(wb, ws, "unit costs");

      writeFileXLSX(wb, "report-unit-cost.xlsx");
    }
  }, [rowData]);

  return (
    <>
    <div className="mx-2">
      <Button variant="contained" className="report-button" onClick={exportFile}><img className="reports-button-icon" src={xmlIcon} alt={t("Download XML")} /> {t("Download XML")} </Button>
    </div>
    </>
  )
}