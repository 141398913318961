import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CountUp from 'react-countup';

export default function TotalCostsAnalysisData({data, percentCostIn, chartsDashContainerStyles}) {

    useLayoutEffect(() => {
      ////(data);
    if (data.length) {
    ////("hii");
    let rootPie = am5.Root.new("chartPieDiv");            
    rootPie.setThemes([
        am5themes_Animated.new(rootPie)
    ]);
    rootPie._logo.dispose();


var chart = rootPie.container.children.push(
    am5percent.PieChart.new(rootPie, {
    radius: am5.percent(90),
    innerRadius: am5.percent(50)
  })
);

const myTheme = am5.Theme.new(rootPie);

    myTheme.rule("Label").setAll({
      fontFamily:"'Cairo', sans-serif",
      direction: 'rtl'
    });
  
    rootPie.setThemes([
      myTheme
    ]);


// // Define data
// var data = [{
//   transType: "مصروفات",
//   totalCosts: 100000
// }, {
//   transType: "مدخلات",
//   totalCosts: 160000
// }];

// Create series
var series = chart.series.push(
  am5percent.PieSeries.new(rootPie, {
    name: "Series", 
    // tooltipText: "{name}: {valueY}% (2020)",
    valueField: "totalCosts",
    categoryField: "transType",
    alignLabels: false, 
    innerRadius : 20,
  })
);

series.get("colors").set("colors", [
  am5.color("#FF9E01"),
  am5.color("#074acc"),
]);

series.data.setAll(data);

// series.data.setIndex(2000, 2000);

// Play initial series animation
// https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
series.appear(1000, 100);

// Disabling labels and ticks
series.labels.template.set("visible", false);
series.ticks.template.set("visible", false);

  return () => {
    if (rootPie) {
        rootPie.dispose();
    }
  }
      }

    
}, [data])

  return (
    <>
    <section>
        <div className="container h-100 position-relative" dir='ltr'>
            <div id="chartPieDiv" style={chartsDashContainerStyles}></div>
            <div className='count-chart'><CountUp end={percentCostIn} duration={percentCostIn / 10}/>%</div>
        </div>
    </section>
    </>
  )
}
