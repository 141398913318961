import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import $ from 'jquery';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function SearchPopup({pages}) {
    
    const [pageValue, setPageValue] = useState('');
    const [pageInputValue, setPageInputValue] = useState('');
    const navigate = useNavigate();

    function handleChange(e) {
        ////(e.target.value);
        setPageValue(e.target.value);
    }
    function navigatePages(page) {
        if (page) {
            navigate(`/${page}`);
            setPageValue("")
            setPageInputValue("")
            hideSearchPopup()
        }
    }
  
    function hideSearchPopup(params) {
        $(".search-popup").addClass("d-none")

    }
  return (
    <div className='containerBox-search'>
        <div className='close-popup-icon cursor-pointer' onClick={hideSearchPopup}>
            <FontAwesomeIcon icon={faXmark} />
        </div>
        <div className='light-search-box' >
            <div className='search' dir='rtl'>
                <Autocomplete
                    clearIcon={false}
                    freeSolo
                    value={pageValue}
                    onChange={(event, newValue) => {
                        handleChange(event);
                        navigatePages(newValue?.value)
                    }}
                    isOptionEqualToValue={(option, value) => 
                        option.label === value.label
                    }
                    inputValue={pageInputValue}
                    onInputChange={(event, newInputValue) => {
                        setPageInputValue(newInputValue);
                    }}
                    // getOptionLabel={() => {

                    // }}
                    id="pages"
                    options={pages}
                    sx={{ width: "250px", backgroundColor : "#EFF3FB", paddingInlineStart : "40px", borderRadius : "8px" }}
                    renderInput={(params) => <TextField  className='' margin='none' placeholder={t("Search")}  {...params}/>}
                />
                <div className='icon-input'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
            </div>
        </div>
    </div>
    
    )
}
