import React, { useState, useEffect } from 'react'
import {  GoogleMap, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import pin from '../../img/pin.png';
import { BeatLoader } from 'react-spinners';
import Reload from '../Reload/Reload';
import { useTranslation } from 'react-i18next';
const apiKeyGoogleMaps = 'AIzaSyBgxFNej5RYec_G0S-UzjMmh_ky5Z6iELg';

Geocode.setApiKey(apiKeyGoogleMaps);
Geocode.enableDebug();

const containerStyle = {
  width: '100%',
  height: '500px',
  borderRadius : "20px",
};
const mapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      "featureType": "water", // المياه
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#98d0eb"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",   // مكعبات السكنية
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ebe9e9"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway", // الطرق الكبيره 
      "elementType": "geometry.fill", 
      "stylers": [
        {
          "color": "#0a2f49"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway", // الطرق الكبير
      "elementType": "geometry.stroke", // خطوط
      "stylers": [
        {
          "color": "#d3d3d3"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.3
        }
      ]
    },
    {
      "featureType": "road.arterial", // النقط اللي في الماب لما تكبر الزوم
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 10
        }
      ]
    },
    {
      "featureType": "road.local", // الشوارع الصغيرة
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#fffffc"
        },
        {
          "lightness": 10
        },
      ]
    },
    {
      "featureType": "poi",  // حاجات صغيره مش عارفها 
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park", // حاجات صغيره مش عارفها  و غير مؤثره
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dedede"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke", // خطوط كلمات العلامات التجارية
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill", //  كلمات العلامات التجارية
      "stylers": [
        {
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon", //   icon العلامات التجارية
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "saturation": 30
        },
        {
          "color": "#cf9400"
        },
        {
          "lightness": 10
        }
      ]
    },
    {
      "featureType": "transit", // الخطوط الطرق الصغيره
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        },
      ]
    }
  ]
}

function MapPlace({center, handleChange}) {

  const { t, i18n} = useTranslation()

    const centerPosition = {
      lat: center?.lat,
      lng: center?.lng
    };


  

  const [autoComplete, setAutocomplete] = useState(null)
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10)

  let refMap = null;

  //  ================== S T A R T   M  E  R  G ========================


  const [address, setAddress] = useState('');
  const [markerPosition, setMarkerPosition] = useState(centerPosition);
  const [type, setType] = useState('marker');
  const saveAddress = (address) => {
    // localStorage.setItem('address', address);
    handleChange({}, "location", address)
  };
    
  useEffect(() => {
    if (markerPosition !== centerPosition) {
      setZoom(16);
      ////("zoom");
    }
  }, [address]);


  const onMarkerDragEnd = (center) => {
    Geocode.fromLatLng(center.lat, center.lng).then(
      (response) => {
        const address = getAddress(response);
        saveAddress(address);
        setType('marker');
        setAddress(address || '');
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const accessGeolocation = async () => {
    if ('geolocation' in navigator) {
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          displayCurrentLocation(latitude, longitude);
        });
      } catch (error) {
        // ////(error);
        // console.error(error);
      }
    }
  };
  
  const displayCurrentLocation = async (lat, lng) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      const address = getAddress(response);
      ////(response);
      saveAddress(address);
      const center = {
        lat: lat,
        lng: lng
      }
      onMarkerDragEnd(center)
      setMarkerPosition(center);
      // ////(lat,lng);
      setType('current');
      setAddress(address || '');
    } catch (error) {
      console.error(error);
    }
  };
  

  //  ================== E  N  D    M  E  R  G ========================
const getAddress = ( addressArray ) => {
  ////(addressArray.plus_code.compound_code);
    let Address = addressArray.plus_code.global_code;
    // let Address = addressArray.plus_code.compound_code;
    
    // for( let i = 0; i < addressArray.length; i++ ) {
    //   if ( addressArray[ i ].types[0] && 'street_address' === addressArray[ i ].types[0] ) {
    //    Address = addressArray[ i ].formatted_address;
    //    return Address;
    //   }else if( addressArray[ i ].types[0] && 'route' === addressArray[ i ].types[0] ) {
    //     Address = addressArray[ i ].formatted_address;
    //     return Address;
    //   }
    //  }
    // Address = addressArray[ 0 ].formatted_address;
    return Address;
   };
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(centerPosition);
    map.fitBounds(bounds);

    setMap(map)
  }, [])
        // M A R K E R 
//    const loadMarker = marker => {
//     //  ////('marker: ', marker);
//      setMarkDrag(marker)     
//   }


  // A U T O  C O M P L E T E
  
   function loadAutocomplete (autocomplete) {
    //  ////('autocomplete: ', autocomplete)
    setAutocomplete(autocomplete)
  }

   function onPlaceChanged () {
    // ////("what this");
    if (autoComplete !== null) {
      const autocompeleteValue = autoComplete.getPlace()
       onPlaceSelected(autocompeleteValue)
    } else {
    //   ////('Autocomplete is not loaded yet!')
    }
  }
  
  const onPlaceSelected = (place) => {
    const address = place.formatted_address;
    const latValue = place.geometry?.location.lat();
    const lngValue = place.geometry?.location.lng();
    saveAddress(address);
    const center = {
      lat: latValue,
      lng: lngValue
    }
    // ////(center);
    onMarkerDragEnd(center)
    setMarkerPosition(center);

    setType('autocompelete');
    setAddress(address || '');
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  function handleCenter() {
    // ////("hiiiii");
    // setType("marker")
    if (refMap?.state.map) {
      const mapCenter = refMap.state.map.center
      const newLat = mapCenter.lat()
      const newLng = mapCenter.lng()
    //   ////('googleMap2', refMap, mapCenter);
      // ////("mapCenter", newLat, newLng);
      const center = { 
        lat :newLat,
        lng : newLng
      }
      onMarkerDragEnd(center)
      setMarkerPosition(center)
    }
  }
  function mapPosition() {
    if (type === "current") {
        // ////("mapPosition current");
        return centerPosition
    }else if (type === "autocompelete") {
        // ////("mapPosition autocompelete");
        return markerPosition
    }
  }

const [ libraries ] = useState(['places']);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey : apiKeyGoogleMaps,
    libraries ,
  })

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return <GoogleMap
    id='aboney-map'
    ref={(ref) => {refMap = ref}}
    mapContainerStyle={containerStyle}
    center={mapPosition()}
    onLoad={onLoad}
    onUnmount={onUnmount}
    zoom = {zoom}
    onCenterChanged={() => {handleCenter();}}
    // options = {mapOptions}
    gestureHandling={"greedy"} // or "cooperative" || "greedy" Set this to "greedy" to enable single-finger panning
  >
    { /* Child components, such as markers, info windows, etc. */ }
    <>
    <Autocomplete

        onLoad={loadAutocomplete}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          type="text"
          placeholder={t("Search...")}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `280px`,
            height: `42px`,
            padding: `0 20px 0 10px`,
            borderRadius: `10px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `16px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginTop : "16px" ,
            marginLeft: "-140px"
          }}
        />
      </Autocomplete>
    </>
    
  </GoogleMap>
  }
  return  (
    <>
    <div>
        <div className='position-relative' style={containerStyle}>
          {/* ============ START MERG ================ */}
        <div className='position-relative d-block '>
        {isLoaded ? renderMap() : <div className=" d-flex align-items-center"><BeatLoader className="mx-auto my-auto" color="#074acc"/></div>}
    </div>
          {/* ============  E  N  D     M  E  R  G  ================ */}
          {isLoaded ? <img className="pin-marker-map" src={pin} alt="pin" /> : <></>}
          
          {/* <button className='currentLocation-tap' onClick={accessGeolocation}><i className="fa-solid fa-location-crosshairs fs-5 m-2"></i></button> */}
        </div>
      </div>
    </>


  )
}

export default MapPlace;