import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');


export default function RentalActivityAnalysis({data, chartsDashContainerStyles}) {

    useLayoutEffect(() => {

    if (data.length) {
      console.log(data);
    let root = am5.Root.new("chartRentalActivityDiv");            
    root.setThemes([
        am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

        // create charts
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "panY",
        layout: root.verticalLayout,
      }));
      const myTheme = am5.Theme.new(root);

      myTheme.rule("Label").setAll({
        fontFamily:"'Cairo', sans-serif",
        direction: 'rtl',
      });
    
      root.setThemes([
        myTheme
      ]);

      // Add legend
// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
var legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    })
  );

    var xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance : 30,
        
      })
    
    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({
        location: 1,
    })
    let xRenderer2 = xAxis.get("renderer");
    xRenderer2.labels.template.setAll({
      rotation: window.innerWidth >= 800 ? -30 : -90,
      centerY : am5.p50
    })

    xAxis.get("renderer").labels.template.setAll({
      maxWidth: 100,
      textAlign: "center"
    })
    xAxis.data.setAll(data);

    

  var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));

       // Add series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "date",
    }));
    series.columns.template.setAll({
        tooltipText: name === "تسكين" ? `{name}: {valueY} \n {unitCodeIn}` : `{name}: {valueY} \n {unitCodeOut}` ,
        width: am5.percent(100),
        fill :name === "تسكين" ? am5.color("#074acc") : am5.color("#FF9E01") ,
        tooltipY: 0,
        strokeOpacity: 0,
        cornerRadiusTL: 20,
        cornerRadiusTR: 20,
    });

    series.data.setAll(data);
    // series.data.setAll(data);

    
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear(1000);

  series.bullets.push(function() {
    return am5.Bullet.new(root, {
      locationY: 0,
      sprite: am5.Label.new(root, {
        text: "{valueY}",
        fill: root.interfaceColors.get("alternativeText"),
        centerY: 0,
        centerX: am5.p50,
        populateText: true,
        fontFamily : "'Cairo', sans-serif"
      })
    });
  });

  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 10,
    cornerRadiusTR: 10,
    cornerRadiusBL: 10,
    cornerRadiusBR: 10
  });
  
  legend.data.push(series);
}

    makeSeries("تسكين", "in");
    makeSeries("مغادرة", "out");


    legend.itemContainers.template.setAll({
      reverseChildren : true,
    })
      // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
  //   behavior: "zoomX"
  // }));

//     // Add scrollbar
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
//   chart.set("scrollbarX", am5.Scrollbar.new(root, {
//     orientation: "horizontal"
// }));
// cursor.lineY.set("forceHidden", true);
// cursor.lineX.set("forceHidden", true);


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
chart.appear(1000, 100);

    return () => {
        if (root) {
            root.dispose();
        }
    }
        }

    
}, [data])

  return (
    <>
    <section>
        <div className="container h-100" >
            <div id="chartRentalActivityDiv" style={{width : "100%", height : "300px"}}></div>
        </div>
    </section>
    </>
  )
}
