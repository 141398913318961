import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
  const {t, i18n} = useTranslation()
  return (
    <section className="page_404">
        <div className="container ">
          <div className="row justify-content-center"> 
          <div className="col-sm-12 ">
          <div className="col-sm-10  text-center">
          <div className="four_zero_four_bg">
            <h1 className="text-center ">404</h1>
          </div>
          <div className="contant_box_404">
          <h3 className="h2">{t("You seem lost")}</h3>
          
          <p>{t("The page you're looking for is not available!")}</p>
          
          {/* <button  className="link_404" > {role ? "أذهب إلى ملفك الشخصي" : "أذهب الي تسجيل الدخول" }</button> */}
          {/* <button  className="link_404" > { "أذهب الي تسجيل الدخول" }</button> */}
        </div>
          </div>
          </div>
          </div>
        </div>
      </section>
  )
}
