import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ThemeProvider, FormControl, Input,InputLabel, MenuItem, Select, TextField, Box, Chip, InputAdornment, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import joi from 'joi';
import { useApi } from '../Store/ApiContext';
import { useSnackbar } from 'notistack';
import { addSuccessMessage, constantsErrorMessage, constantsErrorServerDataMessage, editSuccessMessage, validationErrorMessage } from '../../constants/global';
import { MuiTelInput } from 'mui-tel-input'
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";


export default function AddUser({customTheme, togglePopUp, popupClose, userDataRow, setUserDataRow, baseUrl, decodeToken, setSuccessMessage, setShowSuccess, setRowData }) {

  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();
  const {orgLang} = decodeToken()
  
  console.log(decodeToken());

  const { enqueueSnackbar } = useSnackbar();

  const [role, setRole] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userData, setUserData] = useState({
    userName : '',
    userEmail : '',
    userPhone : '',
    userPassword : '',
    userLang :  orgLang,
    role : role,
  })
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const roleName = [
    {label : t('General Manager'), id : "General Manager"},
    {label : t('System Admin'), id : "System Admin"},
    {label : t('Unit Manager'), id : "Unit Manager"},
    {label : t('Cost Manager'), id : "Cost Manager"},
    {label : t('Task Manager'), id : "Task Manager"},
    {label : t('Follow-up Manager'), id : "Follow-up Manager"},
  ];

  const handleChangeRole = (event) => {
    const {
      target: { value },
    } = event;
    setRole(
      // On autofill we get a stringified value.
      value
    );
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleChange(e, id = "", newValue) {
    let myData = {}
    userDataRow ?  myData = {...userDataRow} : myData = {...userData}
      if (id === "role") {
        const {
          target: { value },
        } = e;
        myData['role'] = value;
      }else if (id === "userPhone") {
        myData[id] = newValue;
      }else if(id === "userLang") {
        myData[id] = e.target.value;
      }else {
        myData[e.target.id] = e.target.value;
      }
    userDataRow ? setUserDataRow(myData) : setUserData(myData)
  }
  function validationUserData(newData) {
    const schema = joi.object().required().keys({
      userName :  joi.string().required(),
      userPhone : joi.string().length(13).required(),
      userEmail : joi.string().email({tlds : false, minDomainSegments : 2}).required(),
      userPassword : userDataRow  ? joi.string().regex(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/) :  joi.string().regex(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/).required(),
      userLang :  joi.string().required(),
      role : joi.array().min(1).required(),
  });
  return  schema.validate(newData, {abortEarly : false})
  }
  
  function clearInputsDataRow() {
    if (userDataRow) {
      setUserDataRow('');
    }else {
      setRole([]);

      setUserData({
        userName : '',
        userEmail : '',
        userPhone : '',
        userPassword : '',
        userLang : orgLang,
        role : [],
      })
    }
  }

  async function addData() {
    const validationData = validationUserData(userData);
    console.log(validationData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('post', userData, "user", userDataRow);
      ////(res);
      if (res.message === "success") {
        ////(res);
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "user");
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        showInvalidFeedback(res.validationError[0].details)
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        return 0;
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
      showInvalidFeedback([])
    }
  }
  async function editData() {
    const newData = {
        userName : userDataRow.userName,
        userPhone : userDataRow.userPhone,
        userEmail : userDataRow.userEmail,
        userPassword : userDataRow?.userPassword && userDataRow?.userPassword,
        userLang : userDataRow.userLang,
        role : userDataRow.role,
      }
    const validationNewData = validationUserData(newData);
    if (validationNewData.error) {
      showInvalidFeedback(validationNewData.error.details);
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      return 0;
    }else {
      // const res = await responseUser('put', newData);
      const res = await responseApi('put', newData, "user", userDataRow._id);
      if (res.message === "success") {
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "user");
        clearInputsDataRow()
        redirectUserLanguage()        
      }else if(res.message === "validation error") {
        showInvalidFeedback(res.validationError[0].details)
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        return 0;
      }else if (res.message === "user doesn't exist in organization") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}<br/>{t(constantsErrorServerDataMessage)}</>, {variant : "error"});
      } else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
      showInvalidFeedback([])
    }
  }

  function redirectUserLanguage() {
    const {userLang, userEmail} = decodeToken();
    const languageForce = localStorage.getItem("languageForce");
    if (userLang !== userDataRow.userLang && userEmail === userDataRow.userEmail) {
      localStorage.setItem("languageForce", userDataRow.userLang);
      window.location.reload();
    } else if (languageForce !== userDataRow.userLang && userEmail === userDataRow.userEmail) {
      localStorage.removeItem("languageForce");
      window.location.reload();
    }
  }
  
  // function changeUserLanguageToken(newLang) {
  //   i18n.changeLanguage(newLang);
  //   let token = localStorage.getItem("token");
  //   const decoded = jwt_decode(token);
  //   decoded.userLang = newLang;
  //   localStorage.setItem("token", decoded)
  // }

  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
        }
      }}>
        <div className='lightContainer scrollbar-popup-style' >
        <ThemeProvider theme={customTheme} >
          <ul className=' row'>
          <li className="col-12">
                <div className='p-2 '>
                  <TextField id='userName' className='textfield-input' label={t("Name")} variant="standard"  value={userDataRow?.userName ? userDataRow?.userName : userData.userName} onChange={(e) => handleChange(e)} type="text" />
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the username.")}</div>
              </li>
              <li className="col-12">
                <div className='p-2 '>
                  <TextField id='userEmail' className='textfield-input' label={t("Email")} variant="standard" value={userDataRow?.userEmail ? userDataRow.userEmail : userData.userEmail} onChange={(e) => handleChange(e)} type="text" />
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the email address.")}</div>
              </li>
              <li className="col-12" >
                <div className='p-2 '>
                  <MuiTelInput defaultCountry='EG' dir='ltr' MenuProps={{dir : "ltr",}} disableFormatting id='userPhone' className='textfield-input' label={t("Phone Number")} variant="standard"  value={userDataRow?.userPhone ? userDataRow.userPhone : userData.userPhone} onChange={(e) => handleChange(e, "userPhone", e)} type="tel"/>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the phone number.")}</div>
              </li>
              <li className="col-12">
                <div className='p-2 '>
                  <form>
                    <FormControl sx={{ m: 0, width: '100%' }} variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">{t("Password")}</InputLabel>
                      <Input
                          id="userPassword"
                          onChange={(e) => handleChange(e)}
                          value={userDataRow ? userDataRow?.userPassword : userData.userPassword}
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                          <InputAdornment position="end">
                              <IconButton
                              aria-label="toggle password visibility"
                              
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                          }
                      />
                    </FormControl>
                  </form>
                  
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the password.")}</div>
              </li>
              <li className="col-12">
                <div className='p-2 '>
                  <FormControl className='textfield-input' variant="standard" >
                    <InputLabel id="demo-simple-select-standard-label">{t("Language")}</InputLabel>
                        <Select
                            MenuProps={{ disableScrollLock: true }}
                            labelId="demo-simple-select-standard-label"
                            id="userLang"
                            value={userDataRow?.userLang ? userDataRow.userLang : userData.userLang}
                            onChange={(e) => handleChange(e, "userLang")}
                        >
                            <MenuItem value={"en"}>{t("en")}</MenuItem>
                            <MenuItem value={"ar"}>{t("ar")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="text-helper-invalid d-none">{t("Please choose language")}</div>
              </li>
              
              <li className="col-12">
                <div className='p-2'>
                  <FormControl  fullWidth>
                    <InputLabel>{t("Role")}</InputLabel>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      fullWidth
                      labelId="demo-multiple-chip-label"
                      id="role"
                      multiple
                      value={userDataRow?.role ? userDataRow.role : role}
                      onChange={(e) => {handleChangeRole(e); handleChange(e, 'role');}}
                      input={<Input id="select-multiple-chip"  />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={t(value)} />
                          ))}
                        </Box>
                      )}
                    >
                      {roleName.map((obj) => (
                        <MenuItem
                          key={obj.id}
                          value={obj.id}
                        >
                          {obj.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="text-helper-invalid d-none">{t("Please select the appropriate role for user permissions.")}</div>
              </li>          
          </ul>

          <div className='text-start mt-2'>
            <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() =>{ togglePopUp("add-popup"); clearInputsDataRow(); showInvalidFeedback([])}}>{t("Back")}</Button>
           <Button variant="contained" className='btn-myPrimary' onClick={() => userDataRow ? editData() :  addData()}>{t("Confirm")}</Button>
          </div>
          
        </ThemeProvider>
         

    </div>
    </div>
    </>
  )
}