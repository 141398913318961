// import { Calendar } from '@fullcalendar/core';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {Calendar,  momentLocalizer } from 'react-big-calendar'
import FullCalendar from '@fullcalendar/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function BigCalender({baseUrl, showPopup}) {

  const {t, i18n} = useTranslation()

  // Setup the localizer by providing the moment (or globalize, or Luxon) Object
  // to the correct localizer.
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  const [eventsList, setEventsList] = useState([])

  async function getAllRequestsAccepted() {
    const token = localStorage.getItem("token")
    const {data} = await axios({
      method : "get",
      url : `${baseUrl}request/allRequestsAccepted`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    ////(data);
    return data.results
  }

  async function setEvents() {
    const data = await getAllRequestsAccepted();
    const myEventsList = []
    data.forEach(request => {
      myEventsList.push({
        title: /^[a-zA-Z]/.test(request.reqClientName)  ? `${request.unit.unitCode} - ${request.unit.unitName} | ${request.reqClientName}` : `${request.unit.unitCode} - ${request.reqClientName} | ${request.unit.unitName}` ,
        start: new Date(request.reqFrom),
        end: new Date(request.reqTo),
        type: 'appointment'
      })
    });
    setEventsList(myEventsList)

  }
  const {views} = useMemo(() => ({
    views: {
      month: true,
      week: true,
      day: true,
      agenda : false
    },
    // ... other props
  }), [])

  const eventPropGetter = (myEventsList, start, end, isSelected, view) => {

    const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : '#074acc';
    const color = myEventsList.color ? myEventsList.color : "#fff";
    return { style: { backgroundColor ,color} }
  }

  useEffect(() => {
    if (!showPopup) {
    setEvents()
    }
  }, [showPopup])
  
    

  return (
    <>
      <div className=' calender-table m-4 py-3'>
      {/* <FullCalendar
      eventBackgroundColor='#FF9E01'
      eventBorderColor='#FF9E01'
      themeSystem=''
      dayCellClassNames={"text-mySecondry"}
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={[
          { title: 'event 1', date: '2023-04-05',start : '2023-04-05', end :  "2023-04-08"},
          { title: 'event 2', date: '2023-04-09', },
          { title: 'event 3', date: '2023-04-09' },
          { title: 'event 4', date: '2023-04-05',start : '2023-04-09', end :  "2023-04-12"},
        ]}
      /> */}
      <div className="myCustomHeight month-view" dir='ltr'>
            <Calendar
              culture={i18n.language}
              localizer={localizer}
              events={eventsList}
              startAccessor="start"
              endAccessor="end"
              views={views}
              defaultView='month'
              messages={{
                next: t("Next"),
                previous: t("Back"),
                today: t("Current"),
                month: t("Month"),
                week: t("Week"),
                day: t("Day"),
                agenda : t("Schedule"),
                event : t("Unit"),
                date: t("Date"),
                time: t("Time"),
              }}
              rtl={false}
              eventPropGetter={eventPropGetter}
            />
          </div>
      </div>

    </>
  )
}
