import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import MapPlace from "../MapPlace/MapPlace";
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyBgxFNej5RYec_G0S-UzjMmh_ky5Z6iELg');
Geocode.enableDebug();

function CurrentLocationMap({handleChange, dataRow}) {

    const [currentPosition, setCurrentPosition] = useState({
        lat : "",
        lng : ""
    })

      async function currentLocation() {
        navigator.geolocation.getCurrentPosition(function(position) {
            // ////("Latitude is :", position.coords.latitude);
            // ////("Longitude is :", position.coords.longitude);
            let positionCurrentLocation = {...currentPosition}
            positionCurrentLocation = {
              lat : position.coords.latitude,
              lng : position.coords.longitude
            }
            // ////("locationmap", positionCurrentLocation);
            setCurrentPosition(positionCurrentLocation)
          });
      }
    function accessGeolocation() {
        if ("geolocation" in navigator) {
            // ////("Available");
            currentLocation()
            
          } else {
            // ////("Not Available");
          }
    }
    async function displayLocation(address) {
      try {
        const response = await Geocode.fromAddress(address);
        const center = response.results[0].geometry.location;
        ////(center);
        let positionLocation = {...currentPosition};
        positionLocation = {
          lat : center.lat,
          lng : center.lng
        }
        ////(positionLocation);
        ////("locationmap", address);
        setCurrentPosition(positionLocation);

      } catch (error) {
        console.error(error);
      }
      
    }
    useEffect(() => {
      if (dataRow) {
        displayLocation(dataRow?.location)
      }else {
        accessGeolocation()
      }
    }, [])
    return ( 
        <>
        {currentPosition?.lat && currentPosition?.lng ? <div className="">
            <div className="position-relative ">
            <MapPlace center={currentPosition} handleChange={handleChange} /> 
            </div>
          </div> : <BeatLoader className="mx-auto my-5" speedMultiplier={1} color="#074acc" />}
        </>
     );
}

export default CurrentLocationMap;