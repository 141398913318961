import { faCheck, faPenToSquare, faPlus, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, FormControl,  Input,  InputAdornment,  InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useApi } from '../Store/ApiContext';
import joi from 'joi';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, successMessage, validationErrorMessage } from '../../constants/global';
import axios from 'axios';
import ToggleButton from '../ToggleButton/ToggleButton';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

export default function AddRequest({theme, togglePopUp, popupClose, token, baseUrl, requestDataRow, setRequestDataRow, setRowData, showPopup, setShowPopup}) {

  const {showInvalidFeedback, getData, responseApi} = useApi();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();


  const [unitResponse, setUnitResponse] = useState([]);
  const [units, setUnit] = useState('');
  const [unitValue, setUnitValue] = useState(units[0]?.label || null);
  const [unitInputValue, setUnitInputValue] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);
  const [requestsAccepted, setRequestsAccepted] = useState([]);
  const [availableTimeUnit, setAvailableTimeUnit] = useState([]);
  const [transaction, setTransaction] = useState([])
  const [unitId, setUnitId] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  const [availableTime, setAvailableTime] = useState(null);
  // const [dateRange, setDateRange] = useState({
  //   startDate: null,
  //   endDate:  null,
  // });

  // const [showError, setShowError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [showErrorServer, setShowErrorServer] = useState(false);
  // const [errorServerMessage, setErrorServerMessage] = useState('');
  const [clientOrRentingUserValue, setClientOrRentingUserValue] = useState()
  const [clientResponse, setClientResponse] = useState([])
  const [rentingUserResponse, setRentingUserResponse] = useState([])
  const [client, setClient] = useState('');
  const [clientAndRentingUserValue, setClientAndRentingUserValue] = useState(null);
  const [clientAndRentingUserInputValue, setClientAndRentingUserInputValue] = useState('');
  const [unitCost, setUnitCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [infoUnit, setInfoUnit] = useState({});
  const [editTotalCost, setEditTotalCost] = useState(true)
  const [newClient, setNewClient] = useState(false)
  const [requestData, setRequestData] = useState({
    reqClientName : "",
    client : clientAndRentingUserValue,
    clientPhone : "",
    reqFrom : null,
    reqTo : null,
    reqGuests : "",
    reqStatus : "Pending",
    reqNights : 0,
    deposit : 0,
    insurance : 0,
    reqTotalCost : 0,
    notes : "",
    unit : unitValue,
  });
  // add new client in edit request
  const [newClientData, setNewClientData] = useState({
    reqClientName : "",
    clientPhone : "",
  });

  async function getRequestsAccepted(unitId) {
    const token = localStorage.getItem("token")
    const {data} = await axios({
      method : "get",
      url : `${baseUrl}request/acceptedRequestsUnit?q=${unitId}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    console.log(data);
    if (data.message === "success") {
      setRequestsAccepted(data.results);
    }
  }
  
  async function getAvailableTimeUnit() {
    const token = localStorage.getItem("token")
    const {data} = await axios({
      method : "get",
      url : `${baseUrl}availableTime/availableTimeUnit/${requestDataRow ? requestDataRow.unit._id : unitId }?startDate=${requestDataRow ? requestDataRow.reqFrom : requestData.reqFrom}&endDate=${requestDataRow ? requestDataRow.reqTo : requestData.reqTo}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    console.log(data);
    if (data?.message === "success") {
      calcPriceNightsPerAvailableTime(data?.results, {startDate : requestDataRow ? requestDataRow.reqFrom : requestData.reqFrom, endDate : requestDataRow ? requestDataRow.reqTo : requestData.reqTo } )
    }
    // setNewPrice(data.results.allAvailableTime[0].price);
    // setAvailableTime(data.results.allAvailableTime[0].available);
  }
  
  function calcPriceNightsPerAvailableTime(availableTimes, date) {
    console.log(date);
    let totalCostPerNights = 0
    let diffDaysCollect = 0;
    let diff
    let transaction = [];
    const inputStartDate = moment(date.startDate);
    const inputEndDate = moment(date.endDate);
    availableTimes?.forEach((availableTime, i) => {
      const availableTimeStartDate = moment(availableTime.startDate);
      const availableTimeEndDate = moment(availableTime.endDate);
     
      if (availableTime?.available) {
        setAvailableTime(true)
        if (i === 0 && inputStartDate.isSameOrAfter(availableTimeStartDate)) {
          if (availableTimeEndDate.isAfter(inputEndDate)) {
            diff = inputEndDate.diff(inputStartDate, "days");
          } else {
            diff = availableTimeEndDate.diff(inputStartDate, "days");
          }
          console.log(diff); // 2
          diffDaysCollect += diff
          console.log(availableTime.price);
            // totalCostPerNights += diff * availableTime.price;
            // console.log(totalCostPerNights);
        } else if (i === (availableTimes.length - 1) && inputEndDate.isSameOrBefore(availableTimeEndDate)) {
            diff = inputEndDate.diff(availableTimeStartDate, "days");
            console.log(diff); // 5
            diffDaysCollect += diff
            console.log(availableTime.price);
            // totalCostPerNights += diff * availableTime.price;
            // console.log(totalCostPerNights);
        } else {
          diff = availableTimeEndDate.diff(availableTimeStartDate, "days")
          console.log(diff);
          diffDaysCollect += diff;
          // totalCostPerNights += diff * availableTime.price;
          // console.log(totalCostPerNights);
        }
        transaction.push({nights : diff, price : availableTime.price})
      } else if (availableTime.available === false) {
        // enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{" غير متاح " + availableTimeStartDate.format("L") + " - " + availableTimeEndDate.format("L") }</>, {variant : "warning"});
        setAvailableTime(false)
        return 0 ;
      }
    })
    const allNights = inputEndDate.diff(inputStartDate, "days");
    console.log(allNights);
    console.log(diffDaysCollect);
    const diffDays = allNights - diffDaysCollect
    if (diffDays) {
      console.log("eh");
      // totalCostPerNights += diffDays * infoUnit.cost
      // console.log( diffDays * infoUnit.cost);
      // console.log(totalCostPerNights);
      // console.log(requestDataRow?.unit.cost);
      transaction.push({nights : diffDays, price : requestDataRow ? requestDataRow?.unit.cost : infoUnit.cost })
    }
    console.log(transaction);
    setTransaction(transaction);
    calculateRequestNights(transaction, allNights)
  }

  function calculateRequestNights(priceList, nights) {
    const {cost, costInterval} = requestDataRow?.unit ? infoUnit?.costInterval ? infoUnit : requestDataRow?.unit : infoUnit
    console.log(nights, costInterval, cost);
    // let nights = requestDataRow ? moment(requestDataRow.reqTo).diff(moment(requestDataRow.reqFrom), 'day') : moment(requestData.reqTo).diff(moment(requestData.reqFrom), 'day');
    // setUnitCost(cost)
    let totalCost = 0
    if (costInterval === "night") {
      priceList.forEach( ele => {
        totalCost += ele.nights * ele.price
        console.log(totalCost);
      });
    }else if (costInterval === "month") {
      console.log(costInterval);
      priceList.forEach(ele => {
        let months = ele.nights / 30.5
        totalCost += months * ele.price
        console.log(totalCost);
      });
    }
    setTotalCost(totalCost?.toFixed(2))
    let myData = {}
    requestDataRow ? myData = {...requestDataRow} : myData = {...requestData}
    // ////(myData);
    myData.reqNights = nights
    ////(totalCost.toFixed(2));
    if (!requestDataRow) {
      myData.reqTotalCost = totalCost.toFixed(2);
    }
    
    // requestDataRow ? <></> : myData.reqTotalCost = totalCost.toFixed(2);
    requestDataRow ? setRequestDataRow(myData) : setRequestData(myData)
  }

  // function getRequestsAccepted() {
  //   let requests = []
  //   if (requestsData?.length) {
  //     for (let i = 0; i < requestsData.length; i++) {
  //       if (requestsData[i].reqStatus === "Accepted") {
  //         requests.push({start : moment(requestsData[i].reqFrom).format("YYYY-MM-DD"), end : moment(requestsData[i].reqTo).format("YYYY-MM-DD")})
  //       }
  //     }
  //   }
    
  //   ////(requests);
  //   setRequestsAccepted(requests);
  // }


  const isDayBlocked = (day) => {
    // console.log(moment(day).utc(true).format());
    // const now =  moment(day).utc(false).set({hour:12,minute:0,second:0,millisecond:0})
    // console.log(now);
    for (let i = 0; i < requestsAccepted?.length; i++) {
      const { reqFrom, reqTo } = requestsAccepted[i];
      // const middleDate = requestsAccepted.find(ele => moment(ele.reqFrom).isSame(requestsAccepted[i].reqTo, "day"));
      // if(middleDate) {
      //   if (moment(middleDate).isSame(moment(day).utc(true).format(), "day")) {
      //     return true
      //   }
      // }
      if (moment(day).isBetween(reqFrom, reqTo, "day")) {
        return true;
      }
      
    }
    let result = false
    requestsAccepted?.forEach(ele => {
      requestsAccepted?.forEach(ele2 => {
        if (moment(ele.reqFrom).isSame(ele2.reqTo, "day")) {
          if (moment(ele.reqFrom).isSame(moment(day).utc(true).format(), "day")) {
            result = true
          }
        }
      });
    });
    return result;
   }

  //  const isDayBlocked = (day: moment.Moment) => {
  //   // Check if the day falls within any of the blocked periods
  //   for (let i = 0; i < blockedPeriods.length; i++) {
  //     const { startDate, endDate } = blockedPeriods[i];
  //     if (day.isBetween(startDate, endDate, 'day', '[]')) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  //  const isOutsideRange = (day) => {
  //   return requestsData.some(event => {
  //     if (event.status === "Accepted") {
  //       const start = moment(event.startDate).format("DD-MM-YYYY");
  //       const end = moment(event.endDate).format("DD-MM-YYYY");
  //       return day.utc(true).isBetween(start, end);
  //     }
  //     return false;
  //   });
  // };

  function handleChangeNewClient(e, id, newValue) {
    let myData = {...newClientData}
    if (id === "clientPhone") {
      myData[id] = newValue;
    }else {
      myData[e.target.id] = e.target.value;
    }
    myData[id] = newValue;
    setNewClientData(myData)
  }

  function handleChange(e, id, newValue) {
    // deep copy
    let myData;
    requestDataRow ? myData = {...requestDataRow} : myData = {...requestData};
           // update data
      if (id === "unit" || id === "client") {
        ////(e);
        myData[id] = newValue?.id;
        if (id === "unit" && newValue?.id) {
          setUnitId(newValue?.id)
          getRequestsAccepted(newValue?.id)
        }else {
          setRequestsAccepted([]);
        }
        if (id === "client") {
          myData['reqClientName'] = newValue?.clientName;
        }
      
      }else if (id === "date") {
        ////(e);
        moment(e.startDate).format() === "Invalid date"  ?   <></> : myData["reqFrom"] = moment(e.startDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
        moment(e.endDate).format() === "Invalid date"  ?  <></> :   myData["reqTo"] = moment(e.endDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
      }else if (id === "reqStatus") {
        myData[id] = e.target.value;
      }else if (id === "clientPhone") {
        myData[id] = newValue;
      } else {
        ////(e.target.id);
        myData[e.target.id] = e.target.value;

      }
      // set new data
    console.log(myData);
      requestDataRow ? setRequestDataRow(myData) : setRequestData(myData);
  }

  function getOptionUnits(myData, setData) {
    // const data = getData(setClientAndRentingUserData, "client");
    ////(myData);
    const options = myData.map((ele) => {
      return (
        {
          label : ele.unitCode + " - " + ele.unitName ,
          id : ele._id,
          costInterval : ele.costInterval ,
          cost : ele.cost
        }
      ) 
    })
    ////(options);
    setData(options);
  }

  function getOptionClient(myData, setData) {
    const options = myData.map((ele) => {
      return (
        {
          label : ele.clientName + " - " + ele.clientPhone.slice(1),
          id : ele._id,
          clientName : ele.clientName,
        }
      ) 
    })
    return options
  }

  function getRentingUser(myData, setData) {
    const options = myData.map((ele) => {
      return (
        {
          label : ele.email.split("@")[0] + " - " + ele.phoneNumber,
          id : ele._id,
          clientName : ele.firstName + " " + ele.lastName,
        }
      ) 
    })
    return options
  }

  function handleOptionClientsAndRentingUsers() {
    const clients = getOptionClient(clientResponse);
    const rentingUser = getRentingUser(rentingUserResponse);
    setClient([...clients, ...rentingUser]);
  }

  function invalidMediaAndLocation(array) {
    array?.forEach(ele => {
      if (ele.context.key === "reqFrom" || ele.context.key === "reqTo" ) {
        $(".date-invalid").removeClass("d-none");
      }
    });
  }
  
  function clearInputsDataRow() {
    ////(requestDataRow);
    if (requestDataRow) {
      setUnitCost(0);
      setNewPrice(0);
      setTotalCost(0);
      setTransaction([])
      setAvailableTime(null);
      setRequestDataRow('');
      setNewClientData({
        reqClientName : "",
        clientPhone : "",
      })
    }else {
      setUnitValue(null)
      setClientAndRentingUserValue(null)
      setUnitCost(0)
      setNewPrice(0)
      setTotalCost(0);
      setTransaction([]);
      setAvailableTime(null);
      setRequestsAccepted([]);
      setRequestData({
        reqClientName : "",
        clientPhone : "",
        reqFrom : null,
        reqTo : null,
        reqGuests : "",
        reqStatus : "Pending",
        reqNights : 0,
        deposit : 0,
        insurance : 0,
        reqTotalCost : 0,
        notes : ""
      })
    }
  }

  function validationReqData(newData) {
    const schema = joi.object().required().keys({
      reqClientName : joi.string().pattern(/^([a-zA-Zأ-يء]+\s[a-zA-Zأ-يء]+){2,}$/).required(),
      clientPhone : joi.string().length(13),
      client : joi.string().length(24).allow(null),
      rentingUser :  joi.string().length(24).allow(null) ,
      reqFrom : joi.date().required(),
      reqTo : joi.date().required(),
      reqGuests : joi.number().required(),
      reqStatus : joi.string().max(10).required(),
      reqNights : joi.number().required(),
      deposit : joi.number().required(),
      insurance : joi.number().required(),
      reqTotalCost : joi.number().required(),
      notes : joi.string().min(0),
      unit : joi.string().required(),
    });
    return  schema.validate(newData, {abortEarly : false})
  }
  
  async function addRequestData() {
    if (!editTotalCost) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      return 0;
    }
    console.log(newClient);
    if (newClient) {
      delete requestData.client;
    }else {
      delete requestData.clientPhone;
    }
    const validationData = validationReqData(requestData);
    console.log(validationData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      invalidMediaAndLocation(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('post', requestData, "request");
      if (res.message === "success") {
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "request");
        if (requestData.reqStatus === "Accepted") {
          setShowPopup(false); 
          setNewClient(false)
        }
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
        invalidMediaAndLocation(res.validationError[0].details)
      }else if (res.message === "The customer is already here.") {
        setNewClient(false)
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }

  async function editRequestData() {
    if (!editTotalCost) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      return 0;
    }
    const newData = {
      reqClientName : newClient ? newClientData?.reqClientName : requestDataRow?.reqClientName,
      client : typeof(requestDataRow.client) == "string" ? requestDataRow.client : requestDataRow.client !== null ? requestDataRow.client._id : null,
      rentingUser : requestDataRow.rentingUser ? requestDataRow.rentingUser._id : null,
      reqFrom : requestDataRow.reqFrom,
      reqTo : requestDataRow.reqTo,
      reqGuests : requestDataRow.reqGuests,
      reqStatus : requestDataRow.reqStatus,
      reqNights : requestDataRow.reqNights,
      deposit : requestDataRow.deposit,
      insurance : requestDataRow.insurance,
      reqTotalCost : requestDataRow.reqTotalCost,
      notes : requestDataRow.notes,
      unit : typeof(requestDataRow.unit) == "string" ? requestDataRow.unit : requestDataRow.unit._id,
    }
    console.log(newClient);
    if (newClient) {
      newData.clientPhone = newClientData?.clientPhone;
      delete newData.client; 
    }
    console.log(newData);
    const validationData = validationReqData(newData);
    console.log(validationData);
    if (validationData.error) {
      // setErrorMessage(<><br/>يرجى إدخال البيانات بشكل صحيح</>);
      // setShowError(true)
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details);
      invalidMediaAndLocation(validationData.error.details);
      return 0;
    }else {
      const res = await responseApi('put', newData, "request", requestDataRow._id );
      ////(res);
      if (res.message === "success") {
        ////(res);
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "request");
        clearInputsDataRow()
        setNewClient(false);
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details);
        invalidMediaAndLocation(res.validationError[0].details);
      }else if (res.message === "The customer is already here.") {
        setNewClient(false)
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }

  function clientOrRentingUserValueAutoComplete() {
    let nameValue = ""; 
    if (requestDataRow?.client) {
      if ( requestDataRow?.client?.clientName) {
        nameValue = requestDataRow?.client?.clientName + " - " + requestDataRow?.client?.clientPhone.slice(1) 
      } else {
        nameValue = clientAndRentingUserValue;
      }
    } else if (requestDataRow?.rentingUser) {
      nameValue = requestDataRow.rentingUser.email.split("@")[0] + " - " + requestDataRow.rentingUser.phoneNumber
    }
    setClientOrRentingUserValue(nameValue)
  }
  
  useEffect(() => {
    if (showPopup ) {
      getData(setUnitResponse, "unit");
      getData(setClientResponse, "client");
      getData(setRentingUserResponse, "rentingUser");
    }
  }, [showPopup]);
  

  useEffect(() => {
    if (unitResponse) {
      getOptionUnits(unitResponse, setUnit);
    }
  }, [unitResponse]);

  useEffect(() => {
    if (clientResponse && rentingUserResponse) {
      handleOptionClientsAndRentingUsers();
    }
  }, [clientResponse, rentingUserResponse]);

  useEffect(() => {

    if ((requestData?.reqTo && requestData?.reqFrom && requestData?.unit) || ( requestDataRow?.reqTo && requestDataRow?.reqFrom )) {
      ////("ehhhhh");
      // requestDataRow ? setInfoUnit(requestDataRow.unit) : <></>;
      // ////(requestDataRow);
      // calculateRequestNights()
      getAvailableTimeUnit()
    }

  }, [requestData.reqTo, requestData.reqFrom, requestData.unit, requestDataRow?.reqTo, requestDataRow?.reqFrom, requestDataRow?.unit]);

  useEffect(() => {
    if (requestDataRow?.unit?._id) {
      getRequestsAccepted(requestDataRow?.unit?._id);
    }
  }, [requestDataRow?.unit?._id]);  

  useEffect(() => {
    if (requestDataRow || requestDataRow === '') {
      clientOrRentingUserValueAutoComplete();
    }
  }, [requestDataRow])
  

  // useEffect(() => {
  //   if (unitId && requestData.reqFrom && requestData.reqTo ) {
  //     // getRequestsAccepted(unitId);
  //     getAvailableTimeUnit()
  //   }
  // }, [unitId, requestData.reqFrom, requestData.reqTo])
  

  
  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
          setEditTotalCost(true);
          setNewClient(false);
        }
      }}>
      <ThemeProvider theme={theme}>
        {units && client && (clientOrRentingUserValue || (!clientOrRentingUserValue && !requestDataRow)) ? <div className='lightContainer request-popup scrollbar-popup-style'>
          <ul className=' row'>
          <ToggleButton newClient={newClient} setNewClient={setNewClient}/>
            
            {newClient ? <>
              <li className="col-6">
                <div className='p-2'>
                  {/* <TextField id='clientPhone' className='textfield-input' label="رقم التليفون" value={requestDataRow ? newClientData.clientPhone : requestData.clientPhone} onChange={(e) => { requestDataRow ? handleChangeNewClient(e) : handleChange(e)}} variant="standard"  type="tel" /> */}
                  <MuiTelInput defaultCountry='EG' dir='ltr' MenuProps={{dir : "ltr"}} disableFormatting id='clientPhone' className='textfield-input' label={t("Phone Number")} value={requestDataRow ? newClientData.clientPhone : requestData.clientPhone} onChange={(newValue) => { requestDataRow ? handleChangeNewClient("", "clientPhone", newValue) : handleChange("", "clientPhone", newValue)}} variant="standard"  type="tel"/>
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the phone number. Maximum 13 digits.")}</div>
            </li>
            <li className="col-6">
                <div className='px-2 pt-2'>
                  <TextField id='reqClientName' className='textfield-input' label={t("Applicant Name")} value={requestDataRow ? newClientData.reqClientName : requestData.reqClientName} onChange={(e) => { requestDataRow ? handleChangeNewClient(e) : handleChange(e)}} variant="standard"  type="text" />
                </div>
                <div className="text-helper-invalid d-none" >{t("Please enter the applicant's name.")}</div>  
              </li></> : <>
              <li className="col-12">
                  <div className='px-2'>
                    <Autocomplete
                    dir='ltr'
                      value={clientOrRentingUserValue}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setClientAndRentingUserValue(newValue);
                        handleChange(event, "client", newValue);
                      }}
                      isOptionEqualToValue={(option, value) => 
                        option.label === value.label
                      }
                      inputValue={clientAndRentingUserInputValue}
                      onInputChange={(event, newInputValue) => {
                        ////(newInputValue);
                        setClientAndRentingUserInputValue(newInputValue);
                      
                      }}
                      id="client"
                      options={client}
                      sx={{ width: "100%", direction : "ltr" }}
                      renderInput={(params) => <TextField label={t("Customer Information")}   variant="standard"  className='textfield-input'   {...params}/>}
                    />
                    <div className='text-helper-invalid d-none'>{t("Please enter customer information (phone number - name).")}</div>
                  </div>
              </li>
              <li className="col-12">
                <div className='px-2 pt-2'>
                  <TextField id='reqClientName' className='textfield-input' helperText={t("You can modify the applicant's name.")} label={t("Alternate Applicant Name")} value={requestDataRow ? requestDataRow?.reqClientName : requestData.reqClientName} onChange={handleChange} variant="standard"  type="text" />
                </div>
                <div className="text-helper-invalid d-none" >{t("Please enter the applicant's name.")}</div>  
              </li>
              </>}
            
            <li className="col-12">
                <div className='px-2 pt-2'>
                  <Autocomplete
                    value={requestDataRow?.unit?.unitCode ? requestDataRow?.unit?.unitCode + " - " + requestDataRow?.unit?.unitName : unitValue}
                    onChange={(event, newValue) => {
                      setUnitValue(newValue);
                      handleChange(event, "unit", newValue);
                      ////(newValue);
                      setInfoUnit(newValue)
                    }}
                    isOptionEqualToValue={(option, value) => 
                      option?.label === value?.label
                    }
                    inputValue={unitInputValue}
                    onInputChange={(event, newInputValue) => {
                      setUnitInputValue(newInputValue);
                    }}
                    id="unit"
                    disableClearable
                    options={units}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField label={t("Unit")}
                    variant="standard"  className='textfield-input'   {...params}/>}
                  />
                  <div className="text-helper-invalid d-none">{t("Please select the desired unit.")}</div>
                </div>
            </li>
            <li className="col-6">
                <div className='p-2'>
                  <TextField id='reqGuests' className='textfield-input' label={t("Number of Persons")} value={requestDataRow ? requestDataRow?.reqGuests : requestData?.reqGuests } onChange={handleChange} variant="standard"  type="number" />
                </div>
                <div className="text-helper-invalid d-none">{t("Please enter the expected number of persons.")}</div>
            </li>
            <li className="col-6">
                <div className='p-2 d-flex '>
                  <FormControl className='textfield-input' variant="standard" >
                    <InputLabel id="demo-simple-select-standard-label">{t("Status")}</InputLabel>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      labelId="demo-simple-select-standard-label"
                      id="reqStatus"
                      value={requestDataRow?.reqStatus ? requestDataRow?.reqStatus : requestData.reqStatus}
                      onChange={(e) => handleChange(e, "reqStatus")}
                    >
                      <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                      <MenuItem value={"Accepted"}>{t("Accepted")}</MenuItem>
                      <MenuItem value={"Rejected"}>{t("Rejected")}</MenuItem>
                      <MenuItem value={"Started"}>{t("Started")}</MenuItem>
                      <MenuItem value={"Finished"}>{t("Finished")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="text-helper-invalid d-none">{t("Please choose the appropriate status for the request.")}</div>
            </li>
            <hr className='line-report w-100 mt-4 mb-2 mx-auto'  />
          <li className={requestDataRow ? requestDataRow?.unit ? 'col-12' : "col-12 disable-date-picker" : requestData?.unit ? 'col-12' : "col-12 disable-date-picker"}>
              <div className='px-2 mb-3 input-calender-addRequest w-100 '>
                <div id='date' className='w-100' >
                  <DateRangePicker
                    isRTL={i18n.language === "ar" ? true : false }
                    startDate={requestDataRow ? moment(requestDataRow.reqFrom) : moment(requestData.reqFrom).format() === "Invalid date" ? null : moment(requestData.reqFrom)}
                    endDate={requestDataRow ? moment(requestDataRow.reqTo) :  moment(requestData.reqTo).format() === "Invalid date" ? null : moment(requestData.reqTo) }
                    onDatesChange={(date) => { handleChange(date, "date");}}
                    focusedInput={focusedInput}
                    startDateId='startDate'
                    endDateId='endDate'
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                    startDatePlaceholderText={t("From")}
                    endDatePlaceholderText={t("To")}
                    displayFormat="YYYY/MM/DD"
                    customArrowIcon={"-"}
                    minimumNights={1}
                    numberOfMonths={window.innerWidth > 700 ? 2 : 1}
                    withPortal
                    isDayBlocked={isDayBlocked}
                    isOutsideRange={() => {}}
                    hideKeyboardShortcutsPanel
                    reopenPickerOnClearDates
                    disabled={requestDataRow ? requestDataRow?.unit ? false : true : requestData?.unit ? false : true} 
                  />
                </div>
                <div className="text-helper-invalid date-invalid d-none">{t("Please specify the start and end booking dates.")}</div>
              </div>
            </li>
          <li className="col-6">
            <div className='px-2' >
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">{t("Seriousness of reservation Money")}</InputLabel>
              <Input
                type='number'
                inputProps={{ min : 0 }}
                id="deposit"
                onChange={handleChange}
                value={requestDataRow ? requestDataRow.deposit : requestData.deposit}
                endAdornment={<InputAdornment position="end"> {t("EGP")} </InputAdornment>}
                // dir='rtl'
              />
            </FormControl>                
            </div>
            <div className="text-helper-invalid d-none">{t("Please enter the seriousness of reservation money amount.")}</div>
          </li>
          <li className="col-6">
            <div className='px-2 '>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">{t("Insurance")}</InputLabel>
              <Input
                type='number'
                inputProps={{ min : 0 }}
                id="insurance"
                onChange={handleChange}
                value={requestDataRow ? requestDataRow.insurance : requestData.insurance}
                endAdornment={<InputAdornment position="end"> {t("EGP")} </InputAdornment>}
                // dir='rtl'
              />
            </FormControl>                
            </div>
            <div className="text-helper-invalid d-none">{t("Please enter the insurance amount.")}</div>
          </li>
          {/* <li className="col-12">
            <div className='p-2 '>
              <TextField id='notes' className='textfield-input' value={requestDataRow ? requestDataRow.notes : requestData.notes} label="ملاحظات"  onChange={handleChange} variant="standard"  type="text" />
            </div>
          </li> */}
          <li className="col-12">
            <div className='p-2'>
              <TextField id='notes' className='textfield-input' value={requestDataRow ? requestDataRow.notes : requestData.notes}  multiline rows={2} inputProps={{ maxLength: 200 }} label="ملاحظات"  onChange={handleChange} variant="standard"  type="text" />
              {/* <TextField id='notes' value={requestDataRow ? requestDataRow.notes : requestData.notes} multiline rows={3}  inputProps={{ maxLength: 500 }} onChange={handleChange}   className='textfield-input' label="ملاحظات" variant="standard"  type="text" /> */}
            </div>
          </li>
          </ul>

          <div className='mx-4   my-4 report-request'>
            <div className='d-flex justify-content-between '>
              <div>
              {/* {transaction?.map((trans) =>  <p><span>{trans.nights}</span> ليلة *<span> {infoUnit.costInterval === "night" ? trans.price : (trans.price / 30.5).toFixed(2)} </span>{t("EGP")}</p> )} */}
              <p><span>{requestDataRow ? requestDataRow.reqNights : requestData.reqNights}</span> {t("Night")} </p>
              </div>
              <div>
                {/* {transaction && transaction?.map((trans) => <p><span>{infoUnit.costInterval === "night" ? trans.nights * trans.price : (trans.nights * trans.price / 30.5).toFixed(2) }</span> {t("EGP")} </p>)} */}
                <p><span>{requestDataRow.rentingUser ? requestDataRow.reqTotalCost : totalCost }</span> {t("EGP")} </p>
              </div>
            </div>
            <hr className='line-report w-100 my-3 mx-auto'  />
            <div className='d-flex justify-content-between '>
              <div>
              <p>{t("Total")}</p>
              <span style={{fontSize : "10px"}}>{t("(Including earnest money)")}</span>
              </div>
              <div className='d-flex align-items-center'>
                <FontAwesomeIcon className={`me-3 text-myPrimary fs-6 ${requestDataRow.rentingUser ? "opacity-75" : 'cursor-pointer'}`} icon={editTotalCost ? faPenToSquare : faCheck } onClick={() => {requestDataRow.rentingUser ? <></> : setEditTotalCost(!editTotalCost)}} />
                {/* <FontAwesomeIcon icon={faSquareCheck} /> */}
                <Input id='reqTotalCost' style={{width : "90px"}} type='number' disabled={editTotalCost} value={requestDataRow ? requestDataRow.reqTotalCost : requestData.reqTotalCost} onChange={(e) => handleChange(e)} />
                <p className=''> {t("EGP")} </p>
              </div>
            </div>
          </div>
          <div className='text-start'>
            {/* <button className='btn-myPrimaryLight m-3' onClick={() => togglePopUp("add-popup")}>{t("Back")}</button>
            <button className='btn-myPrimary'>{t("Confirm")}</button> */}
            <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() => {showInvalidFeedback([]); clearInputsDataRow(); togglePopUp("add-popup"); setEditTotalCost(true); setNewClient(false); }}>{t("Back")}</Button>
            <Button variant="contained" className={availableTime !== false ? 'btn-myPrimary ' : "btn-myPrimary opacity-75 cursor-notAllowed"}  onClick={() => {!requestDataRow && availableTime !== false ? addRequestData() : editRequestData()}}>{t("Confirm")}</Button>
            <div>
              {availableTime !== false ? "" : <p className=' text-notAvailable'>{t("This period is not available.")}</p>}
            </div>
          </div>

        </div> : <div className='lightContainer' style={{backgroundColor : "transparent"}}>
            <Skeleton variant="rounded" width={"100%"} height={"400px"} style={{borderRadius : "10px"}}/>
          </div>}
      </ThemeProvider>
    </div> 
    {/* <ThemeProvider theme={theme}>
    <ErrorMessage message={errorMessage} showError={showError} setShowError={setShowError}/>
        <ErrorMessage message={errorMessage} showError={showError} setShowError={setShowError}/>
        <ErrorMessageServer message={errorServerMessage} showErrorServer={showErrorServer} setShowErrorServer={setShowErrorServer}/>
    </ThemeProvider> */}
    </>
  )
}
