import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NavBar from './Components/NavBar/NavBar';
import MainMenu from './Components/MainMenu/MainMenu';
import Dashboard from './Components/Dashboard/Dashboard';
import UnitGantt from './Components/UnitGant/UnitGantt';
import Unites from './Components/Unites/Unites';
import Requests from './Components/Requests/Requests';
import Costs from './Components/Costs/Costs';
import Tasks from './Components/Tasks/Tasks';
import Users from './Components/Users/Users';
import ReportsUnitCost from './Components/ReportsUnitCost/ReportsUnitCost';
import ReportsAllCost from './Components/ReportsAllCost/ReportsAllCost';
import Settings from './Components/Settings/Settings';
import NotFound from './Components/NotFound/NotFound';
import { useEffect, useState } from 'react';
import AddUnit from './Components/AddUnit/AddUnit';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, createTheme } from '@mui/material';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import Login from './Components/Login/Login';
import 'animate.css';
import $ from 'jquery';
import Owners from './Components/Owners/Owners';
import jwt_decode from "jwt-decode";
import ApiProvider from './Components/Store/ApiContext';
import Clients from './Components/Clinets/Clients';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import Calender from './Components/Calender/Calender';
import 'moment/locale/ar-ly'

//Start clearCache
import packageJson from "../package.json";
import { getBuildDate } from './utils/utils';
import withClearCache from './ClearCache';
//End clearCache
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SendNotifications from './Components/SendNotifications/SendNotifications';
import AvailableTime from './Components/AvailableTime/AvailableTime';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import { clarity } from 'react-microsoft-clarity';
import axios from 'axios';

const ClearCacheComponent = withClearCache(App);  
export default function MainApp() {
  return <ClearCacheComponent />;
}

// import ReactGA from 'react-ga';
// ReactGA.initialize('G-TKXZWJPJYR');

export function App() {  

  if (process.env.NODE_ENV === 'production') clarity.init('mdvwvyfrd0');

  const navigation = useNavigate();
  const {t, i18n} = useTranslation();

  console.warn = () => {}
  
  if (process.env.NODE_ENV === 'production') {
    var baseUrl = "https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/";
    console.log = () => {} 
 } else {
   var baseUrl = "http://localhost:8080/api/v1/";
  //  var baseUrl = "https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/";

 }

  const tableContainerStyle = {
    width: "100%",
    height: "65vh",
    borderRadius : "5px",
  }
  

   const theme = createTheme({
    direction: t("dir"), // Both here and <html dir="rtl"
    typography: {
      fontFamily: "Cairo"
    },
    palette: {
      primary: {
        light: '#0F5EF7',
        main: '#0F5EF7',
        dark: '#102652',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FF9E01',
        main: '#FF9E01',
        contrastText: '#fff',
      },
      third : {
        main: '#0FAC83',
        contrastText: '#fff',
      },
      fourth : {
        main : '#CC3F0C',
        contrastText: '#fff',
      },
      mediumGray : {
        main : "#808080",
        contrastText: '#fff',
      }
    },
  });
  const [lastPathname, setLastPathname] = useState('')
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState('');
  const [userAuthorization, setUserAuthorization] = useState({});
  const [organizationAuthorization, setOrganizationAuthorization] = useState({});

  async function getData(endPoint) {
    const res = await axios({
      url : `${baseUrl}${endPoint}`,
      method : "get",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      }
    })
    if (res.data) {
      return res.data;
    }
  }

  async function authorizationAccount() {
    const {data} = await getData("organization/authorization");
    console.log(data);
    setUserAuthorization(data.user);
    setOrganizationAuthorization(data.organization);

  }

  function getToken() {
    const token = localStorage.getItem("token");
    return token
  }

  function decodeToken() {
    let token = localStorage.getItem("token")
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000 || !decoded.exp) {
        localStorage.clear();
        navigation("/login")
      }
      return decoded;
    }
  }

  function saveUserName() {
    const decoded = decodeToken();
    setUserName(decoded?.userName)
  }

  function ProtectedRouteLogin(props) {
    if (!localStorage.getItem("token")) {
      return props.children;
    }else {
      return <Navigate to={'/not-found'} />
    }
  }

  // function ProtectedRouteRolesAndPlans(props) { // shield for all routes
  //   if (localStorage.getItem("token")) {
  //     const decoded = decodeToken();
  //     if (props.plans?.filter((ele) => ele === decoded.planName ).length || decoded.planName === "pro") {
  //       for (let i = 0; i < decoded.role.length; i++) {
  //         if (props.roles.filter((ele) => ele === decoded.role[i] ).length) {
  //           return props.children 
  //         }
  //       }
  //       return <Navigate to='/not-found' />
  //     } else {
  //       return <Navigate to='/not-found' />
  //     }
  //   } else {
  //     return <Navigate to='/login' />
  //   }
  // }

  function ProtectedRouteRolesAndPlans(props) { // shield for all routes
    if (localStorage.getItem("token")) {
      if (props.plans?.filter((ele) => ele === organizationAuthorization?.plan?.planName )?.length || organizationAuthorization?.plan?.planName === "pro") {
        for (let i = 0; i < userAuthorization.role.length; i++) {
          if (props.roles.filter((ele) => ele === userAuthorization.role[i] )?.length) {
            return props.children;
          }
        }
        return <Navigate to='/not-found' />
      } else {
        return <Navigate to='/not-found' />
      }
    } else {
      return <Navigate to='/login' />
    }
  }

  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: i18n.language === "ar" ? [prefixer,  stylisRTLPlugin ] : [prefixer, ],
  });
  

  function togglePopUp(className) {
    window.history.replaceState("","Title", " ");
    $(`.${className}`).toggle();
  };

  function popupClose(e, className) {
    if (e.target.classList.contains("containerBox")) {
      togglePopUp(className);
      return true
    };
    return false  
  };

  useEffect(() => {
    moment.locale(i18n.language);
    document.body.dir=t("dir")
  }, [i18n.language]);
  
  useEffect(() => {
    let decoded = decodeToken();
    const languageForce = localStorage.getItem("languageForce");
    if (token && decoded && !languageForce && decoded.userLang !== i18n.language) {
      i18n.changeLanguage(decoded.userLang );
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      authorizationAccount();
    } else {
      navigation("/login")
    }
  }, [token])
  

  return (
    <>
    <ThemeProvider theme={theme}>
      <SnackbarProvider 
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical : "top", horizontal : i18n.language === "en" ? "left" : "right"}} 
        action={(snackbarId) => ( 
          <button className='closeSnackbar' onClick={() => closeSnackbar(snackbarId)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>)}
      >
        {/* <App /> */}
        <CacheProvider value={cacheRtl}>
        <div className='base' dir={t("dir")}>
          <div className='base-mainMenu' >
            {localStorage.getItem("token") && userAuthorization?._id  && organizationAuthorization?._id ? <MainMenu lastPathname={lastPathname} decodeToken={decodeToken} setToken={setToken} userName={userName} organizationAuthorization={organizationAuthorization} userAuthorization={userAuthorization} /> : <></>}
          </div>
          <div className='base-items'>
          <SendNotifications/>
          {localStorage.getItem("token") && userAuthorization?._id  && organizationAuthorization?._id ? <NavBar theme={theme} decodeToken={decodeToken}/> : <></>}
          {/* <ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><Calender theme={theme} togglePopUp={togglePopUp} baseUrl={baseUrl} token={token}/></ProtectedRouteRolesAndPlans> */}
            <div className='component scrollbar-style'>
                <ThemeProvider theme={theme}>
                  <ApiProvider theme={theme} togglePopUp={togglePopUp} baseUrl={baseUrl} token={localStorage.getItem("token")}>
                    <Routes>
                    {localStorage.getItem("token") && userAuthorization?._id  && organizationAuthorization?._id ? 
                      <>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Follow-up Manager"]}><Dashboard theme={theme} baseUrl={baseUrl} token={localStorage.getItem("token")} userName={userName}  /></ProtectedRouteRolesAndPlans>} path="/"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Follow-up Manager"]}><Dashboard theme={theme} baseUrl={baseUrl} token={localStorage.getItem("token")} userName={userName} /></ProtectedRouteRolesAndPlans>} path="/dashboard"/>
                        <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><Calender theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token}/></ProtectedRouteRolesAndPlans>} path="/calender"/>
                        <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><UnitGantt theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token}/></ProtectedRouteRolesAndPlans>} path="/renting-timeline"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Unit Manager"]}><Unites theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle} /></ProtectedRouteRolesAndPlans>} path="/units"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Unit Manager"]}><AddUnit theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} decodeToken={decodeToken} /></ProtectedRouteRolesAndPlans>} path="/units/add-unit"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Unit Manager"]}><AddUnit theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} decodeToken={decodeToken} /></ProtectedRouteRolesAndPlans>} path="/units/edit-unit"/>
                        <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Unit Manager"]}><AvailableTime theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} decodeToken={decodeToken} /></ProtectedRouteRolesAndPlans>} path="/units/available-time/:id"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin', "Follow-up Manager"]}><Requests theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} decodeToken={decodeToken} tableContainerStyle={tableContainerStyle} organizationAuthorization={organizationAuthorization} /></ProtectedRouteRolesAndPlans>} path="/requests"/>
                        <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Cost Manager"]}><Costs theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle} /></ProtectedRouteRolesAndPlans>} path="/costs"/>
                        <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Task Manager"]}><Tasks theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle} /></ProtectedRouteRolesAndPlans>} path="/tasks"/>
                        
                        <Route path="/report">
                          <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><ReportsUnitCost theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle}/></ProtectedRouteRolesAndPlans>} path="unit-cost"/>
                          <Route element={<ProtectedRouteRolesAndPlans roles={['General Manager','System Admin', "Follow-up Manager"]}><ReportsAllCost/></ProtectedRouteRolesAndPlans>} path="all-cost"/>
                        </Route>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><Users theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} decodeToken={decodeToken} tableContainerStyle={tableContainerStyle} organizationAuthorization={organizationAuthorization} /></ProtectedRouteRolesAndPlans>} path="/users"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><Clients theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle} /></ProtectedRouteRolesAndPlans>} path="/clients"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager','System Admin']}><Owners theme={theme} togglePopUp={togglePopUp} popupClose={popupClose} baseUrl={baseUrl} token={token} tableContainerStyle={tableContainerStyle} /></ProtectedRouteRolesAndPlans>} path="/owners"/>
                        <Route element={<ProtectedRouteRolesAndPlans plans={["go"]} roles={['General Manager']}><Settings theme={theme} baseUrl={baseUrl} decodeToken={decodeToken}/></ProtectedRouteRolesAndPlans>} path="/settings"/>
                        <Route element={<NotFound/>} path="*"/>
                      </> : <></>}
                      <Route element={<NotFound/>} path="not-found"/>
                      <Route element={<ProtectedRouteLogin><Login theme={theme} baseUrl={baseUrl} getToken={getToken} setToken={setToken}/></ProtectedRouteLogin>} path="/login"/>

                    </Routes>
                  </ApiProvider>
                </ThemeProvider>
            </div>
          </div>
        </div>
      </CacheProvider>
      </SnackbarProvider>
    </ThemeProvider>

  
    </>
    
    
  );
}