import React, { createContext, useState } from 'react'
import  axios from 'axios';
import $ from 'jquery';
import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { constantsErrorMessage, removeSuccessMessage, changeError, changeSuccess } from '../../constants/global';
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';

export const apiContext = createContext(0);

export default function ApiProvider(props) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

      // decoded token
  function decodeToken() {
    let token = localStorage.getItem("token")
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000 || !decoded.exp) {
        localStorage.clear();
        navigate("/login");
      }
      return decoded;
    }
  }

  function getSlug() {
    const decoded = decodeToken();
    return decoded.slug;
  }

              // get data
  async function getData(setRowData, endPoint) {
    const res = await axios({
      url : `${props.baseUrl}${endPoint}`,
      method : "get",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${props.token}`,
      }
    })
    if (res.data) {
      if (setRowData) {
        setRowData(res.data.results)
      }
      return res.data;
    }
  }

          // add data and edit data
  async function responseApi(method, data={}, endPoint, id) {
  ////(props.token);
    const res = await axios({
      url : method === "put" ? `${props.baseUrl}${endPoint}/${id}` : `${props.baseUrl}${endPoint}`,
      method,
      data,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${props.token}`,
      }
    })
    if (res.data) {
      return res.data;
    }
  }

            // remove data
  async function removeUserApi(id, endPoint, setDataRow) {
    const {data} = await axios({
      method : "patch",
      url : `${props.baseUrl}${endPoint}/${id}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${props.token}`,
      } ,
    })
    ////(data);
    props.togglePopUp('add-confirm');
    if (data.message === "success") {
      enqueueSnackbar(<>{t(removeSuccessMessage)}</>, {variant : "success"});
    }else {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(data.message)}</>, {variant : "error"});
    }
    getData(setDataRow, endPoint)
  }

  function confirmRemove(id, setId) {
    props.togglePopUp('add-confirm');
    setId(id)
  }

        //  show invalid message for validation input
  function showInvalidFeedback(array) {
    ////(array);
    $(`.text-helper-invalid`).addClass('d-none');
    array?.forEach(ele => {
      $(`#${ele.context.key}`).parent().parent().parent().next().removeClass('d-none');
    });
    
  }

  // quick edit in table
  async function quickEditTable(endPoint, dataUser, id) {
    const {data} = await axios({
      url : `${props.baseUrl}${endPoint}/quickEdit/${id}` ,
      method : "patch",
      data : dataUser,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${props.token}`,
      }
    })
    console.log(data);
    if (data.message === "success") {
      enqueueSnackbar(<>{t(changeSuccess)}</>, {variant : "success"});
    }else {
      return data
      
    }
  }

  

    return  <apiContext.Provider value= {{getData, responseApi, removeUserApi, showInvalidFeedback, confirmRemove, quickEditTable, getSlug}}>
                {props.children}
            </apiContext.Provider>
}


export const useApi = ()=> {
  const context = useContext(apiContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserContextProvider.");
  }
  return context;
};