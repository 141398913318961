import FullCalendar from "@fullcalendar/react";
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AvailableTimeCalender({
  baseUrl,
  handleChange,
  availableTimeList,
}) {
  const params = useParams();
  const {t, i18n} = useTranslation()

  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
        agenda: false,
      },
      // ... other props
    }),
    []
  );

  const eventPropGetter = (myEventsList, start, end, isSelected, view) => {
    const backgroundColor = myEventsList.colorEvento
      ? myEventsList.colorEvento
      : "#CC3F0C";
    const color = myEventsList.color ? myEventsList.color : "#fff";
    const borderRadius = "10px"
    return { style: { backgroundColor, color, borderRadius } };
  };

  return (
    <>
      <div
        className="myCustomHeight availableTimeCalender month-view"
        dir="ltr"
      >
        <Calendar
          culture={i18n.language}
          localizer={localizer}
          events={availableTimeList}
          startAccessor="start"
          endAccessor="end"
          views={views}
          defaultView='month'
          messages={{
            next: t("Next"),
            previous: t("Back"),
            today: t("Current"),
            month: t("Month"),
            week: t("Week"),
            day: t("Day"),
            agenda : t("Schedule"),
            event : t("Unit"),
            date: t("Date"),
            time: t("Time"),
          }}
          selectable={true}
          onSelectSlot={(e) => handleChange(e, "date")}
          selected={moment("20-10-2023")}
          rtl={false}

          eventPropGetter={eventPropGetter}
        />
      </div>
    </>
  );
}
