import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import serviceAccount from "../prokfy-admin-firebase-adminsdk-l4v8w-b997716aa7.json"

const firebaseConfig = {
  apiKey: "AIzaSyBloI5pzqx5peRFk43T6vNq59svofigSpQ",
  authDomain: "prokfy-admin.firebaseapp.com",
  projectId: "prokfy-admin",
  storageBucket: "prokfy-admin.appspot.com",
  messagingSenderId: "267007780437",
  appId: "1:267007780437:web:907cc1dba7a8ac6fd896dc",
  measurementId: "G-RSD6J7Q1BJ",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


// export const getTokens = async (setTokenFound) => {
//   // Get registration token. Initially this makes a network call, once retrieved
//   // subsequent calls to getToken will return from cache.
//   getToken(messaging, { vapidKey: 'BKMelcWe1NunQiG6HLtUCZeZrOjUvND6cRxwxPOdsiEQk4e7dbtQO4P8wNaCBiyaH4tYsNm16HMizYvHMe_VbnM' }).then((currentToken) => {
//     console.log(currentToken);
//     if (currentToken) {
//       // Send the token to your server and update the UI if necessary
//       setTokenFound(true)
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false)
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
//   });
// }


// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
// });



export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const serviceWorker = await window.navigator.serviceWorker
      .getRegistration('/');
    if (serviceWorker) {

      return serviceWorker;
    } 
    console.log(serviceWorker);
    return window.navigator.serviceWorker.register('./firebase-messaging-sw.js', {
      scope: '/',
    });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) => 
    {
      console.log(serviceWorkerRegistration);
      return getToken(messaging, { vapidKey: "BKMelcWe1NunQiG6HLtUCZeZrOjUvND6cRxwxPOdsiEQk4e7dbtQO4P8wNaCBiyaH4tYsNm16HMizYvHMe_VbnM", serviceWorkerRegistration })
    });

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));