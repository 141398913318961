import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigCalender from '../BigCalender/BigCalender';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddRequest from '../AddRequest/AddRequest';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Presets from '../Prestes/Prestes';
import CalenderChart from '../CalenderChart/CalenderChart';
import { useApi } from '../Store/ApiContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Calender({theme, togglePopUp, popupClose, baseUrl}) {

  const {t, i18n} = useTranslation()

  const [showPopup, setShowPopup] = useState(false);
  const [acceptedRequests, setAcceptedRequests] = useState([])

          // date Picker
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(30, "day").utc(true).set({hour:0,minute:0,second:0,millisecond:0}),
    endDate:  moment().utc(true).set({hour:0,minute:0,second:0,millisecond:0}),
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({
      startDate : startDate?.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}),
      endDate : endDate?.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}) });
  };
  const isOutsideRange = day => {
    return day.isAfter(moment().endOf("day"));
  }

  async function getCalenderApi() {
    const token = localStorage.getItem("token")
    const {data} = await axios({
      method : "get",
      url : `${baseUrl}request/calender?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    console.log(data);
    if (data.message === "success") {
      setAcceptedRequests(data.data)
    }
    
  }
  
  useEffect(() => {
    console.log(focusedInput);
    if ((focusedInput === false || focusedInput === null) && dateRange.startDate && dateRange.endDate) {
      getCalenderApi()
    }
  }, [focusedInput])

  useEffect(() => {
    // add title page
    document.title = t("Renting Timeline");
  }, []);


  
  return (
    <>
      {/* <section className='bg-babyblue-50 big-card text-center container-fluid'>
        <div className="header-card text-center d-inline-block d-sm-flex justify-content-between align-items-center" >
          <div className="header-title">
            <h2 className='fw-bolder'>تقويم الإيجارات</h2>
          </div>
          <div onClick={() => {togglePopUp("add-popup"); setShowPopup(true);}}>
            <Button variant="contained" className='btn-request' ><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>إضافة طلب</Button>
          </div>
          
        </div>
        <div>
        <div>
          {acceptedRequests ? <CalenderChart requests={acceptedRequests}/> : <></>}
          </div>
        </div>
      </section> */}


    {/* <section className='bg-babyblue-50 big-card-calender container-fluid'>
      <div className="header-card d-flex justify-content-between align-items-center">
        <div className="header-title">
          <h2 className='fw-bolder'>تقويم الإيجارات</h2>
        </div>
        <div onClick={() => {togglePopUp("add-popup"); setShowPopup(true);}}>
        </div>
      </div>
      <div className=' calender-table m-1'>
          <BigCalender baseUrl={baseUrl} showPopup={showPopup}/>
      </div>

      <div className='add-popup ' >
        <AddRequest theme={theme} baseUrl={baseUrl} togglePopUp={togglePopUp} popupClose={popupClose} showPopup={showPopup} setShowPopup={setShowPopup}/>
      </div>
    </section> */}
    <section className='bg-babyblue-50 big-card-calender container-fluid'>
      <div className="header-card d-flex justify-content-between align-items-center">
        <div className="header-title">
          <h2 className='fw-bolder'>{t("Renting Timeline")}</h2>
        </div>
        <div onClick={() => {togglePopUp("add-popup"); setShowPopup(true);}}>
          {/* <Button variant="contained" className='btn-request' ><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>إضافة طلب</Button> */}
        </div>
      </div>
      <div className=' calender-table m-1'>
          <BigCalender baseUrl={baseUrl} showPopup={showPopup}/>
      </div>

      <div className='add-popup ' >
        <AddRequest theme={theme} baseUrl={baseUrl} togglePopUp={togglePopUp} popupClose={popupClose} showPopup={showPopup} setShowPopup={setShowPopup}/>
      </div>
    </section>
    
    {/* <div className='container-fluid'>
    <div className='d-flex justify-content-between mb-2'>
        <Skeleton variant="rounded" width={"250px"} height={"50px"} />
        <Skeleton variant="rounded" width={"250px"} height={"50px"} />
      </div>
      <Skeleton variant="rounded" width={"100%"} height={"85vh"} />
    </div> */}
   
    </>
  )
}
