import { ThemeProvider,TextField, Skeleton } from '@mui/material';
import React, { useEffect, useState, } from 'react'
import Button from '@mui/material/Button';
import joi from 'joi';
import { useApi } from '../Store/ApiContext';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, validationErrorMessage } from '../../constants/global';
import { enqueueSnackbar } from 'notistack';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

export default function AddOwner({customTheme, togglePopUp, popupClose, dataRow, setDataRow, setRowData}) {
  
  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();
  
  const [userData, setUserData] = useState({
    ownerName : '',
    ownerPhone : '',
  })

  function handleChange(e, id, newValue) {
      let myData
      dataRow ? myData = {...dataRow} : myData = {...userData}
      if (id === "ownerPhone") {
        myData[id] = newValue;
      }else {
        myData[e.target.id] = e.target.value;
      }
      console.log(myData);
      dataRow ? setDataRow(myData) : setUserData(myData)
  }

  function clearInputsDataRow() {
    if (dataRow) {
      setDataRow('');
    }else {
      setUserData({
        ownerName : '',
        ownerPhone : '',
        paragraph : '',
      })
    }
  }

  function validationUserData(newData) {
    const schema = joi.object().required().keys({
      ownerName :  joi.string().regex(/^([a-zA-Zأ-يء]+\s[a-zA-Zأ-يء]+){2,}$/).required(),
      ownerPhone : joi.string().length(13).required(),
      paragraph : joi.string().min(0).max(100),
    });
    return  schema.validate(newData, {abortEarly : false})
  }


  async function addData() {
    const validationData = validationUserData(userData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('post', userData, "owner", dataRow);
      if (res.message === "success") {
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "owner");
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }
  async function editData() {
    const newData = {
        ownerName : dataRow.ownerName,
        ownerPhone : dataRow.ownerPhone,
        paragraph : dataRow.paragraph,
    }
    const validationData = validationUserData(newData);
    ////(validationData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      const res = await responseApi('put', newData, "owner", dataRow._id );
      if (res.message === "success") {
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "owner");
        clearInputsDataRow()
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    
    showInvalidFeedback([])
  }


  
  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
        }
      }}>
        <div className='lightContainer scrollbar-popup-style' >
          <ThemeProvider theme={customTheme} >
            <ul className=' row'>
            <li className="col-12">
                  <div className='p-2 '>
                    <TextField id='ownerName' className='textfield-input' label={t("Full Owner Name")} value={dataRow ? dataRow.ownerName : userData.ownerName } onChange={handleChange} variant="standard"  type="text" />
                  </div>
                  <div className="text-helper-invalid d-none" >{t("Please enter the full owner name.")}</div>
                </li>
                <li className="col-12">
                  <div className='p-2 '>
                    <MuiTelInput defaultCountry='EG' dir='ltr' MenuProps={{dir : "ltr"}} disableFormatting id='ownerPhone' className='textfield-input' label={t("Phone Number")} value={dataRow ? dataRow.ownerPhone : userData.ownerPhone }  onChange={(newValue) => handleChange("", "ownerPhone", newValue)} variant="standard"  type="tel"/>

                  </div>
                  <div className="text-helper-invalid d-none">{t("Please enter the phone number. Maximum 13 digits.")}</div>
                </li>
                <li className="col-12">
                  <div className='p-2 '>
                    <TextField id='paragraph' className='textfield-input' value={dataRow ? dataRow.paragraph : userData.paragraph } label={t("Additional Information")}  onChange={handleChange} variant="standard"  type="text" />
                  </div>
                </li>
            </ul>
            <div className='text-start mt-2'>
              <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() =>{ togglePopUp("add-popup"); clearInputsDataRow(); showInvalidFeedback([])}}>{t("Back")}</Button>
              <Button variant="contained" className='btn-myPrimary' onClick={ () => dataRow ? editData()  : addData() }>{t("Confirm")}</Button>
            </div>

          </ThemeProvider>
        </div>
    </div>

    {/* <ThemeProvider theme={customTheme}>
        <ErrorMessage message={errorMessage} showError={showError} setShowError={setShowError}/>
        <ErrorMessageServer message={errorServerMessage} showErrorServer={showErrorServer} setShowErrorServer={setShowErrorServer}/>
    </ThemeProvider> */}
    </>
  )
}
