
import { ThemeProvider, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, Autocomplete, TextField, Skeleton, } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, {  useEffect, useState } from 'react'
import moment from 'moment';
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import joi from 'joi';
import { addSuccessMessage, constantsErrorMessage, editSuccessMessage, validationErrorMessage } from '../../constants/global';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';


export default function AddTasks({customTheme, togglePopUp, popupClose, taskDataRow, setTaskDataRow, setSuccessMessage, setShowSuccess, setRowData}) {

  const {showInvalidFeedback, getData, responseApi} = useApi();
  const {t, i18n} = useTranslation();


  // const [taskItem, setTaskItem] = useState('');
  const [transaction, setTransaction] = useState('');


  const [date, setDate] = useState(moment());
  const [unitResponse, setUnitResponse] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitValue, setUnitValue] = useState(units[0]?.label || null);
  const [unitInputValue, setUnitInputValue] = useState('');
  const [taskTypes, setTaskTypes] = useState([]);
  const [newTaskType, setNewTaskType] = useState(false)
  const [taskData, setTaskData] = useState({
    taskDueDate : date.format(),
    taskType : "",
    taskStatus : '',
    taskTotalCost : '',
    unit : unitValue,
  });

  const handleChangeSet = (event, set) => {
    set(event.target.value);
  };

  function handleChange(e, id, newValue) {
    console.log(taskDataRow);
    // deep copy
    let myData;
    taskDataRow ? myData = {...taskDataRow} : myData = {...taskData};
           // update data
      if (id === "taskDueDate") {
        myData[id] = newValue.utc(true).set({hour:0,minute:0,second:0,millisecond:0}).format();
      }else if (id === "unit") {
        myData[id] = newValue;
      } else if (id === "taskStatus" || id === "taskType" ) {
        console.log(id);
        myData[id] = e.target.value;
      } else {
        myData[e.target.id] = e.target.value;
      }
      // set new data
      taskDataRow ? setTaskDataRow(myData) : setTaskData(myData);
    console.log(myData);
      
  }

  function getOptions(myData, setData) {

    const options = myData.map((ele) => {
      return (
        {
          label : ele.unitCode + " - " + ele.unitName ,
          id : ele._id,
        }
      ) 
    })
    setData(options);
  }

  function clearInputsDataRow() {
    ////(taskDataRow);
    if (taskDataRow) {
      setTaskDataRow("");
    }else {
      setUnitValue(null)
      setTaskData({
        taskDueDate : moment().utc(true).format(),
        taskType : "",
        taskStatus : "",
        taskTotalCost : "",
        unit : "",
      })
    }
  }

  async function addNewTaskType() {
    const myData = {
      taskTypeName : taskDataRow ? taskDataRow?.taskType : taskData.taskType
    }
    const res = await responseApi("post", myData, "taskType");
    console.log(res.message);
    return res;
    // return { message : res.message, taskTypeId : res.taskTypeId };
  }

  function validationReqData(newData) {
    const schema = joi.object().required().keys({
      taskDueDate : joi.date().required(),
      taskType : joi.string().required(),
      taskStatus : joi.string().required(),
      taskTotalCost : joi.number().required(),
      unit : joi.string().required(),
    });
    return  schema.validate(newData, {abortEarly : false})
  }
  
  async function addTaskData() {
    const validationData = validationReqData(taskData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      if (newTaskType) {
        const resAddNewTaskType = await addNewTaskType();
        if (resAddNewTaskType.message !== "success") {
          enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{resAddNewTaskType.message}</>, {variant : "error"});
          return 0;
        }else {
          taskData.taskType = resAddNewTaskType.taskTypeId
          getData(setTaskTypes, "taskType");
        }
      }      
      console.log(taskData);
      const res = await responseApi('post', taskData, "task", taskDataRow);
      if (res.message === "success") {
        togglePopUp("add-popup");
        enqueueSnackbar(<>{t(addSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "task");
        clearInputsDataRow()
        setNewTaskType(false)
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details);
      }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }

  async function editTaskData() {
    const newData = {
      taskDueDate : taskDataRow.taskDueDate,
      taskStatus : taskDataRow.taskStatus,
      taskType : typeof(taskDataRow.taskType) == "object" ? taskDataRow.taskType._id : taskDataRow.taskType,
      taskTotalCost : taskDataRow.taskTotalCost,
      unit : typeof(taskDataRow.unit) == "string" ? taskDataRow.unit : taskDataRow.unit._id,
    }
    const validationData = validationReqData(newData);
    if (validationData.error) {
      enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
      showInvalidFeedback(validationData.error.details)
      return 0;
    }else {
      if (newTaskType) {
        const resAddNewTaskType = await addNewTaskType();
        if (resAddNewTaskType.message !== "success") {
          enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{resAddNewTaskType.message}</>, {variant : "error"});
          return 0;
        }else {
          newData.taskType = resAddNewTaskType.taskTypeId
          getData(setTaskTypes, "taskType");
        }
      }

      const res = await responseApi('put', newData, "task", taskDataRow._id );
      if (res.message === "success") {
        togglePopUp("add-popup")
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setRowData, "task");
        clearInputsDataRow();
        setNewTaskType(false);
      }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details)
      }else {

        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
      }
    }
    showInvalidFeedback([])
  }



  useEffect(() => {
    getData(setUnitResponse, "unit");
    getData(setTaskTypes, "taskType");
  }, []);
  

  useEffect(() => {
    if (unitResponse) {
      getOptions(unitResponse, setUnits)
    }
  }, [unitResponse]);


  return (
    <>
    <div  className='containerBox' onClick={(e) => {
        if (popupClose(e, "add-popup")) {
          showInvalidFeedback([]);
          clearInputsDataRow();
          setNewTaskType(false);
        }
      }}>
        {units ? 
        <div className='lightContainer scrollbar-popup-style' >
          <ThemeProvider theme={customTheme} >
            <ul className=' row'>
                <li className="col-12">
                  <div className='p-2 w-100'>
                    {/* </DirectionProvider> */}
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}> 
                        <DatePicker
                          slotProps={{
                            textField: {
                              id : "taskDueDate",
                              label: t("Task Date"),
                              variant: "standard",
                              style : {
                                width : "100%",                            
                              },
                            },
                          }}
                          format='DD/MM/YYYY'
                          
                          value={taskDataRow ? moment(taskDataRow.taskDueDate) : date}
                          onChange={(newValue) => {setDate(newValue); handleChange('', "taskDueDate", newValue)}}
                          dayOfWeekFormatter={(day) => day}
                        />
                    </LocalizationProvider>
                  </div>
                  <div className="text-helper-invalid d-none">{t("Please enter the task application date.")}</div>
                </li>
                
                {newTaskType ? <>
                  <li className="col-12">
                    <div className='p-2'>
                      <TextField id="taskType" className='textfield-input' label={t("New Task Name")} onChange={(e) => handleChange(e, "taskType")} value={taskDataRow ? taskDataRow?.taskType : taskData.taskType} variant="standard"  type="text" />
                    </div>
                    <div className="text-helper-invalid d-none">{t("Please enter the task item.")}</div>
                  </li>
                </> : <>
                  <li className="col-12">
                    <div className='p-2 d-flex '>
                      <FormControl  className='textfield-input' variant="standard" >
                        <InputLabel id="demo-simple-select-standard-label">{t("Task Type")}</InputLabel>
                        <Select
                          MenuProps={{ disableScrollLock: true }}
                          labelId="demo-simple-select-standard-label"
                          id="taskType"
                          value={taskDataRow ? typeof(taskDataRow.taskType) == "object" ? taskDataRow?.taskType._id : taskDataRow?.taskType : taskData.taskType}
                          onChange={(e) =>{ handleChangeSet(e, setTransaction); handleChange(e, "taskType");}}
                        >
                          {/* <MenuItem value={"نظافة"}>نظافة</MenuItem>
                          <MenuItem value={"صيانة كهرباء"}>صيانة كهرباء</MenuItem>
                          <MenuItem value={"صيانة جهاز"}>صيانة جهاز</MenuItem>
                          <MenuItem value={"صيانة سباكة"}>صيانة سباكة</MenuItem> */}
                          {taskTypes?.map((ele, i) => <MenuItem value={ele._id}>{ele.taskTypeName}</MenuItem>)}
                          <MenuItem onClick={() => setNewTaskType(true)}>{t("New Item")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="text-helper-invalid d-none">{t("Please enter the task type.")}</div>
                  </li>
                </>}
                
                <li className="col-12">
                  <div className='p-2'>
                    <Autocomplete
                      value={taskDataRow.unit?.unitCode ? taskDataRow?.unit?.unitCode + " - " + taskDataRow?.unit?.unitName : unitValue}
                      onChange={(event, newValue) => {
                        setUnitValue(newValue);
                        handleChange(event, "unit", newValue?.id);
                      }}
                      isOptionEqualToValue={(option, value) => 
                        option?.label === value?.label
                      }
                      inputValue={unitInputValue}
                      onInputChange={(event, newInputValue) => {
                        setUnitInputValue(newInputValue);
                      }}
                      id="unit"
                      options={units}
                      sx={{ width: "100%"}}
                      renderInput={(params) => <TextField label={t("Unit")}  placeholder={t("Unit")}
                      variant="standard"  className='textfield-input'   {...params}/>}
                    />
                    <div className="text-helper-invalid d-none">{t("Please select the desired unit.")}</div>
                  </div>
              </li>
              {/* <hr  className='line-card w-75 mx-auto mt-5'/> */}

              <li className="col-12">
                  <div className='p-2 d-flex '>
                    <FormControl className='textfield-input' variant="standard" >
                      <InputLabel id="demo-simple-select-standard-label">{t("Status")}</InputLabel>
                      <Select
                        MenuProps={{ disableScrollLock: true }}
                        labelId="demo-simple-select-standard-label"
                        id="taskStatus"
                        // value={taskItem}
                        value={taskDataRow ? taskDataRow?.taskStatus : taskData.taskStatus }
                        onChange={(e) => {handleChange(e, "taskStatus");}}
                      >
                        <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                        <MenuItem value={"In Progress"}>{t("In Progress")}</MenuItem>
                        <MenuItem value={"Completed"}>{t("Completed")}</MenuItem>
                        <MenuItem value={"Canceled"}>{t("Canceled")}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="text-helper-invalid d-none px-4">{t("Please enter the task status.")}</div>
              </li>

              <li className="col-12">

                  <div className='p-2'>
                    {/* <TextField className='textfield-input' label="القيمة الإجمالية" variant="standard"  type="text" /> */}
                    <FormControl fullWidth  variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">{t("Total Cost")}</InputLabel>
                          <Input
                            type='number'
                            inputProps={{ min : 0 }}
                            id="taskTotalCost"
                            value={taskDataRow?.taskTotalCost ? taskDataRow.taskTotalCost : taskData.taskTotalCost}
                            onChange={handleChange}
                            endAdornment={<InputAdornment position="end"> {t("EGP")} </InputAdornment>}
                          />
                          
                        </FormControl>
                  </div>
                  <div className="text-helper-invalid d-none px-4">{t("Please enter the total cost.")}</div> 
              </li>
                    
            </ul>

            <div className='text-start mt-2'>
              <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() => {togglePopUp("add-popup"); clearInputsDataRow(); showInvalidFeedback([]); setNewTaskType(false);}}>{t("Back")}</Button>
              <Button variant="contained" className='btn-myPrimary' onClick={taskDataRow ? editTaskData : addTaskData}>{t("Confirm")}</Button>
            </div>

          </ThemeProvider>
        </div> : 
        <div className='lightContainer' style={{backgroundColor : "transparent"}}>
          <Skeleton variant="rounded" width={"100%"} height={"400px"} style={{borderRadius : "10px"}}/>
        </div>}
    </div>
    {/* <ThemeProvider theme={customTheme}>
      <ErrorMessage message={errorMessage} showError={showError} setShowError={setShowError}/>
      <ErrorMessageServer message={errorServerMessage} showErrorServer={showErrorServer} setShowErrorServer={setShowErrorServer}/>
    </ThemeProvider> */}
    </>
  )
}
