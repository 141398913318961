import { faPenToSquare, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AddRequest from '../AddRequest/AddRequest';
import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import ConfirmedMessage from '../ConfirmedMessage/ConfirmedMessage';
import { useApi } from '../Store/ApiContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { changeError } from '../../constants/global';
import { useTranslation } from 'react-i18next';

export default function Requests({theme, token, baseUrl, togglePopUp, popupClose, decodeToken, tableContainerStyle, organizationAuthorization }) {

  const {getData, confirmRemove, quickEditTable} = useApi();
  const {t, i18n} = useTranslation()

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const filterTextBox = useRef(); //  input text box

  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
 
  const [numberOfRows, setNumberOfRows] = useState(20);
  const [idRequestRow, setIdRequestRow] = useState();
  const [requestDataRow, setRequestDataRow] = useState(''); // inputs owner for edit
  const [showPopup, setShowPopup] = useState(false)
  
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {field: 'reqCode', headerName : t("Request Code"), width:150},
    {field: 'reqStatus', headerName : t("Request Status"), width:120,
      editable : (params) => params.data.reqStatus ===  "Started" || params.data.reqStatus ===  "Finished" ? false : true,
      cellEditor: 'agSelectCellEditor',
      cellRenderer : params => t(params.data.reqStatus)  ,
      cellEditorParams: {
        values: [t("Pending") ,t("Accepted"), t("Rejected")],
      },
    },
    {field: 'reqClientName', headerName : t("Applicant"), width:200},
    {field: 'clientPhone', headerName :t("Phone Number"), width:150, 
      cellRenderer: function(params) {
        return <a dir="ltr" href={'tel:' +(params.data.client ? params.data.client?.clientPhone : "+" + params.data.rentingUser.phoneNumber)}>{params.data.client ? params.data.client?.clientPhone : "+" + params.data.rentingUser.phoneNumber}</a>
      }
    },
    {field: 'unit.unitCode', headerName : t("Unit"), width:300, 
    cellRenderer: function(params) {
      return params.data.unit.unitCode + " - " + params.data.unit.unitName
    }},
    {field: 'reqFrom', headerName : t("From"), width:180, 
    cellRenderer: function(params) {
      return moment(params.data.reqFrom).utc(false).format("L - LTS a");
    }},
    {field: 'reqTo', headerName : t("To"), width:180,
    cellRenderer: function(params) {
      return moment(params.data.reqTo).utc(false).format("L - LTS a");
    }},
    {field: 'reqNights', headerName : t("Duration (Days)"), width:120},
    // {field: 'unit.cost', headerName : "تكلفة الليلة", width:120, 
    // cellRenderer: function(params) {
    //   if (params.data.unit.costInterval === "month") {
    //     return (params.data.unit.cost / 30.5).toFixed(2)
    //   }else {
    //     return params.data.unit.cost;
    //   }
    // }},
    {field: 'reqTotalCost', headerName : t("Total Cost"), width:125, editable : true},
    {field: 'insurance', headerName : t("Insurance"), width:100},
    {field: 'notes', headerName : t("Notes"), width:200, editable : true},
    {field: 'updatedAt', headerName : t("Last Update"), width:200,
    cellRenderer: function(params) {
      return moment(params.data.updatedAt).utc(true).format("L - LTS a");
    }},
    {field: 'price', headerName : t("Settings"), width:100, 
     cellRenderer: function(params) {
      return <span> 
        {/* {params.data.reqStatus !== "Finished" && params.data.reqStatus !== "Started" ? <FontAwesomeIcon className='mx-2 icon-settings-table cursor-pointer fs-6' onClick={() => {togglePopUp("add-popup"); setShowPopup(true); window.history.pushState("", "Title", "#edit-request"); onSelectionChanged();}} icon={faPenToSquare} /> : <FontAwesomeIcon className='mx-2 fs-6 icon-disable'  icon={faPenToSquare} /> } */}
        <FontAwesomeIcon className='mx-2 icon-settings-table cursor-pointer fs-6' onClick={() => {togglePopUp("add-popup"); setShowPopup(true); window.history.pushState("", "Title", "#edit-request"); onSelectionChanged();}} icon={faPenToSquare} />
        <FontAwesomeIcon className='icon-settings-table cursor-pointer fs-6' icon={faTrashCan} onClick={() => confirmRemove(params.data._id, setIdRequestRow)}/>
      </span>
    }},
  ]);

  // function ProtectedComponentsProPlan(props) { // shield for pro components
  //   if (localStorage.getItem("token")) {
  //     const decoded = decodeToken();
  //     if (decoded.planName === "pro") {
  //       return props.children;
  //     } else {
  //       return <></>
  //     }
  //   }
  // }

  function ProtectedComponentsProPlan(props) { // shield for pro components
    if (localStorage.getItem("token")) {
      if (organizationAuthorization.plan.planName === "pro") {
        return props.children;
      } else {
        return <></>;
      }
    }
  }

    // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    // flex: 1,
    minWidth: 100,
    sortable: true,
    filter: true,
    resizable: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
    ////('cellClicked', event);
  }, []);


    // selection rows
    const onSelectionChanged = useCallback(() => {
      let selectedRows = gridRef.current.api.getSelectedRows();
     console.log(selectedRows);
      setRequestDataRow(selectedRows[0])
    }, []);

    // filter table
    const onFilterTextBoxChanged = useCallback((e) => {
      gridRef.current.api.setQuickFilter(
        e.target.value
      );
    }, []);



  const handleChange = (event) => {
    setNumberOfRows(event.target.value);
  };

  async function changeRequestsStatus() {
    const {data} = await axios({
      url : `${baseUrl}request/changeRequestsAccepted`,
      method : "get",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      }
    })
    console.log(data);
    if (data) {
      getData(setRowData, "request");
    }
  }



  function handleSaveRequestStatusEnglish(requestStatus) {
    let newRequestStatus
    switch (requestStatus) {
      case t("Pending"):
        newRequestStatus = "Pending"
        break;
      case t("Accepted"):
        newRequestStatus = "Accepted"
        break;
      case t("Rejected"):
        newRequestStatus = "Rejected"
        break;        

      default:
        break;
    }
    return {reqStatus : newRequestStatus}
  }

  const saveStatus = async (id, newData, data) => {
    const res = await quickEditTable(`request`, newData, `${id}?startDate=${data.reqFrom}&unit=${data.unit._id}`)
    if (res.message) {
      enqueueSnackbar(<>{changeError}<br/>{t(res.message)}</>, {variant : "error"});
      getData(setRowData, "request");
    }
  };
  const onCellValueChanged = useCallback((params) => {
    let newData = {}
    newData[params.column.getId()] = params.newValue;
    console.log(newData);
    if (params.column.getId() === 'reqStatus') {
      if (i18n.language !== "en") {
        let newRequestStatus = handleSaveRequestStatusEnglish(newData.reqStatus)
        saveStatus(params.data._id, newRequestStatus, params.data)
      } else {
      saveStatus(params.data._id, newData, params.data)
      }
    } 
    
  }, []);

  // Example load data from server
  useEffect(() => {
    // add title page
    document.title = t("Requests");
    getData(setRowData, "request");
  }, []);


  return (
    <>
    <section className='bg-babyblue-50 big-card container-fluid'>
      <div className="header-card d-flex justify-content-between align-items-center">
        <div className="header-title">
          <h2 className='fw-bolder'>{t("Requests")}</h2>
        </div>
        <ProtectedComponentsProPlan>
          <div onClick={() => {togglePopUp("add-popup"); setShowPopup(true); window.history.replaceState("","Title", "#add-request");}}>
            <Button variant="contained" className='btn-request'><span className='me-2 '><FontAwesomeIcon icon={faPlus } /></span>{t("Add request")}</Button>
          </div>
        </ProtectedComponentsProPlan>
      </div>
      {rowData ? <div className='table-unites pt-4 pb-3 px-2 mx-3'>

                  {/* Search table and inputs number of rows */}
        <div className="header-table mb-3 d-flex justify-content-between align-items-center">
          <ThemeProvider theme={theme}>
            <div className="inputs-number-rows">
            
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Entries No.")}</InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numberOfRows}
                  label={t("Entries No.")}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                  <MenuItem value={80}>80</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            
            </div>
            <div className='header-table-search d-flex align-items-end justify-content-end flex-wrap'>
              <Button variant="contained" className='btn-small-request mx-4' style={{fontSize : "12px"}} onClick={changeRequestsStatus}>{t("Update Status")}</Button>
              <TextField id="filter-text-box" label={t("Search...")} variant="standard" ref={filterTextBox} onInput={onFilterTextBoxChanged}  />
            </div>
          </ThemeProvider>
        </div>

        {/* Example using Grid's API */}

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={tableContainerStyle}>

          <AgGridReact
              enableRtl={i18n.language === 'ar' ? true : false}
              ref={gridRef} // Ref for accessing Grid's API

              rowData={rowData} // Row Data for Rows
              onCellValueChanged={onCellValueChanged}
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties

              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection='single' // Options - allows click selection of rows

              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              paginationPageSize={numberOfRows}
              pagination={true}
              rowHeight={30}
              />
        </div>

      </div> : <div className='pt-4 pb-3 px-2 mx-3'>
            <div className='d-flex justify-content-between mb-2'>
              <Skeleton variant="rounded" width={"150px"} height={"50px"} style={{borderRadius : "10px"}} />
              <Skeleton variant="rounded" width={"200px"} height={"50px"} style={{borderRadius : "10px"}}/>
            </div>
            <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
          </div>}
    </section>

    {/* popup */}
      <div className='add-popup'>
        <AddRequest theme={theme} baseUrl={baseUrl} togglePopUp={togglePopUp} popupClose={popupClose} requestDataRow={requestDataRow}  setRequestDataRow={setRequestDataRow} setRowData={setRowData} showPopup={showPopup} setShowPopup={setShowPopup} requestsData={rowData} />
      </div>

        {/* Success Message */}
    {/* <div>
      <ThemeProvider theme={theme}>
        <SuccessMessage message={successMessageRemove} showSuccess={showSuccessRemove} setShowSuccess={setShowSuccessRemove} />
        <ErrorMessage message={errorMessageRemove} showError={showErrorRemove} setShowError={setShowErrorRemove}/>
        <SuccessMessage message={successMessage} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      </ThemeProvider>
    </div> */}

    <div className='add-confirm'>
      <ConfirmedMessage customTheme={theme} token={token} baseUrl={baseUrl} togglePopUp={togglePopUp} popupClose={popupClose} id={idRequestRow} setRowData={setRowData} endPoint={"request"}/>
    </div>
  
  </>
  )
}
