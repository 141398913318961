
import { ThemeProvider } from '@mui/material';
import React from 'react'
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import { useTranslation } from 'react-i18next';

export default function ConfirmedMessage({customTheme, togglePopUp, popupClose, id,  endPoint, setRowData,}) {
  const {removeUserApi} = useApi();
  const {t, i18n} = useTranslation();

  return (
    <>
    <div  className='containerBox' onClick={(e) =>  {popupClose(e, "add-confirm"); }}>
      <div className='lightContainer' >
        <ThemeProvider theme={customTheme} >
          <div className='fs-4 text-center'>{t("Are you sure you want to delete?")}</div>
          <div className='text-center mt-2'>
            <Button variant="contained" className='btn-myPrimaryLight m-3' onClick={() => togglePopUp("add-confirm")}>{t("Back")}</Button>
            <Button variant="contained" className='btn-myPrimary-delete' onClick={() => removeUserApi(id, endPoint, setRowData)}>{t("delete")}</Button>
          </div>
        </ThemeProvider>
      </div>
    </div>
    </>
  )
}
