import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Document,
  Font,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import cairoRegular from "../../assets/fonts/Cairo-Regular.ttf";
import cairoBold from "../../assets/fonts/Cairo-Bold.ttf";
import { useApi } from "../Store/ApiContext";
import logo from "../../img/logoPrimary.png";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import pdfIcon from '../../img/icons/pdf.png';
import { useTranslation } from "react-i18next";


Font.register({ family: "Cairo",  fonts : [{src: cairoRegular}, { src: cairoBold, fontWeight : "bold" }] });

export default function ConvertToPDF({ rowData, unit, costsItem, totalCost }) {
  const { getData } = useApi();
  const {t, i18n} = useTranslation()
  const [organizationData, setOrganizationData] = useState({});

  // Define styles for the PDF
const styles = StyleSheet.create({
  document: {
    fontFamily: "Cairo",
  },
  page: {
    backgroundColor: "white",
    padding : "48px"
  },
  header: {
    flexDirection: "row",
    marginBottom: 5,
    justifyContent : "space-between",
    alignItems : "center"
  },
  logo1: {
    height: "40px",
  },
  logo2: {

    fontSize : "28px",
    color : "#074acc",
    fontWeight :"bold"
  },
  title: {
    alignItems: "center",
    marginBottom: 10,
  },
  titleText: {
    fontSize: "16px",
    fontWeight :"bold"
  },
  unitText: {
    fontSize: "14px",
  },
  body: {
    // margin: 10,
    // padding: 10,
    direction : t("dir"),
    flexDirection : t("dir") === "ltr" ? "row" : "row-reverse"
    // borderColor : "#cfcece"
  },
  reportCostCard : {
    // backgroundColor: "#D3DFF7",
    // borderTopRightRadius: "10px",
    border: "1px solid #D3DFF7",
    padding : "7px",
    marginHorizontal : "10px",
    width : "25%",
    fontSize : "10px",
  },
  infoCost : {
    flexDirection : t("dir") === "ltr" ? "row" : "row-reverse",
    justifyContent : "space-between",
  },
  lineTotalCost : {
    marginTop : "5px",
    marginBottom : "5px",
    borderTop : "0.5px solid #999999",
    width : "100%",
    marginHorizontal : "auto"
  },
  totalCost : {
    flexDirection :  t("dir") === "ltr" ? "row" : "row-reverse",
    justifyContent : "space-between",
  },
  negativeColor : {
    color: "#CC3F0C",
  },
  positiveColor : {
    color: "#0FAC83",
  },
  reportCostTable : {
    width : "75%",
  },
  tableCell: {
    backgroundColor: "#074acc",
    color : "white",
    borderLeft : "solid 1px",
    borderRight : "solid 1px",
    borderColor : "#074acc",
    paddingHorizontal : 5
  },
  dataTableCell: {
    paddingHorizontal: 5,
    // paddingRight: 5,
    borderBottom : "1px",
    // borderRight : "1px",
    // borderLeft : "1px",
    borderColor : "#074acc"
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#999999',
  },
});

  const MyDocument = () => {
    return (
      organizationData  &&
      <Document style={styles.document}>
        {rowData?.map((ele, i) => {
          if (i % 16 === 0) {
            return (
              <Page key={i} size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.header}>
              {/* <Text>Hello, React-PDF!</Text> */}
              <Image style={styles.logo1} src={logo} />
              <Text style={styles.logo2}>{organizationData?.orgName}</Text>
              {/* <Image style={styles.logo1} src="https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg" /> */}
            </View>
            
            {/* <View style={styles.header}> */}
            {/* <Image style={styles.logo2} src="https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-674010.jpg&fm=jpg" /> */}
            {/* <Image style={styles.logo2} src="https://images.unsplash.com/photo-1495344517868-8ebaf0a2044a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2VhcmNofGVufDB8fDB8fHww&w=1000&q=80" /> */}
            {/* <Image style={styles.logo2} src={"https://firebasestorage.googleapis.com/v0/b/prokfy-admin.appspot.com/o/orgLogos%2Flocalhost%2Flogo192.png?alt=media"} /> */}
            {/* <Image style={styles.logo2} src={"https://firebasestorage.googleapis.com/v0/b/proof-of-execution-redington.appspot.com/o/images%2F291b6e11-4af9-4ce9-b72c-373ae08956fd.png?alt=media&token=d04327cb-cedd-4f6d-b2f5-60cea739727c."} /> */}
            {/* <Image src={{ uri: "https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg", method: "GET", headers: {"Cache-Control": "no-cache"}, body: "" }}/> */}
            {/* <Image src={{ uri: "https://firebasestorage.googleapis.com/v0/b/prokfy-admin.appspot.com/o/unitsMedia%2Fmina%2F4301%2FIMG-20230705-WA0062.jpg?alt=media", method: "GET", headers: { 'Access-Control-Allow-Origin': '*', "Access-Control-Allow-Headers" : "*" }, body: "" }}/> */}
            {/* </View> */}
            <View style={styles.title}>
              <Text style={styles.titleText}>
              {t("Financial Units Report")}
              
              </Text>
              <Text style={styles.unitText}>{unit}</Text>
            </View>
            <View style={styles.body}>
            <View style={styles.reportCostTable}>
                <Table data={rowData.slice(Math.ceil(i / 16 * 16),16 * Math.ceil(i / 16 + 1) )} >
                    <TableHeader  textAlign="center"  fontSize={12} includeBottomBorder={false} includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false} >
                      {t("dir") === "ltr" ? <>
                      <TableCell style={styles.tableCell} >{t("Trans Number")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Nature")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Type")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Value")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Execution Date")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Date Added")}</TableCell>
                      </> : <>
                      <TableCell style={styles.tableCell} >{t("Date Added")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Execution Date")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Value")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Type")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Nature")}</TableCell>
                      <TableCell style={styles.tableCell} >{t("Trans Number")}</TableCell>
                      </>}
                      
                    </TableHeader>
                    <TableBody textAlign="left" fontSize={11} even={false} includeBottomBorder={false} includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false} >

                  <DataTableCell
                    style={{...styles.dataTableCell, borderLeft : "1px solid #074acc"}}
                      getContent={(r) => (t("dir") === "ltr" ? r.transCode ? r.transCode : r.reqCode : r.createdAt)}
                    />
                    <DataTableCell
                    style={styles.dataTableCell}
                    
                      getContent={(r) => t("dir") === "ltr" ? t(r.transType) : r.transDate}
                    />
                    <DataTableCell
                    style={styles.dataTableCell}
                    
                      getContent={(r) =>
                        t("dir") === "ltr" ? r.transItem :  r.transTotalCost
                      }
                    />
                    <DataTableCell
                    style={styles.dataTableCell}
                    
                      getContent={(r) =>
                        t("dir") === "ltr" ? r.transTotalCost : r.transItem
                      }
                    />
                    <DataTableCell
                    style={styles.dataTableCell}
                    
                      getContent={(r) =>
                        t("dir") === "ltr" ? r.transDate : t(r.transType)
                      }
                    />
                    <DataTableCell
                    style={{...styles.dataTableCell, borderRight : "1px solid #074acc"}}
                      getContent={(r) => t("dir") === "ltr" ? r.createdAt : r.transCode ? r.transCode : r.reqCode } 
                    />
                  </TableBody>
                  
                </Table>
            </View>
            <View  style={styles.reportCostCard}>
            {costsItem.slice(Math.ceil(i / 17 * 18),17 * Math.ceil(i / 17 + 1) )
              .map((ele, index, array) => (
                <>
                  <View
                    key={index}
                    style={styles.infoCost}
                  >
                    <Text>
                      <Text style={ele.transType === "Expenses" ? styles.negativeColor : styles.positiveColor}>{t(ele.transType)} - {ele.transItem}</Text> 
                    </Text>
                    <Text style={ele.transType === "Expenses" ? styles.negativeColor : styles.positiveColor} >
                      {ele.transType === "Expenses" ? '' : "+"}
                      {parseFloat(ele.cost.toFixed(2)).toLocaleString()}
                    </Text>
                    
                </View>
                {costsItem.length > 17 ? array.length < 17 && array.length === index + 1 && <View >
                    <Text style={styles.lineTotalCost}/>
                    <View style={styles.totalCost}>
                      <Text >{t("Total Cost")}</Text>
                      <Text style={ totalCost < -1 ? styles.negativeColor: styles.positiveColor}>
                        {parseFloat(totalCost.toFixed(2)).toLocaleString()}
                      </Text>
                      
                    </View>
                  </View> : <>
                  { array.length === index + 1 && <View >
                    <Text style={styles.lineTotalCost}/>
                    <View style={styles.totalCost}>
                      <Text >{t("Total Cost")}</Text>
                      <Text style={ totalCost < -1 ? styles.negativeColor: styles.positiveColor}>
                        {parseFloat(totalCost.toFixed(2)).toLocaleString()}
                      </Text>
                    </View>
                  </View>}</>}
                </>
              )) }
            </View>

            </View>
            <Text style={styles.pageNumber} wrap={true} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
              </Page>
            )
          }
        }) 
        }
      </Document>
    );
  };

  useEffect(() => {
    getData(setOrganizationData, "organization");
  }, [getData]);

  return (
    <>
      <div>
        <Button variant="contained" className="report-button">
          <img className="reports-button-icon" src={pdfIcon} alt="" />
          <PDFDownloadLink
            document={<MyDocument />}
            fileName="report-unit-cost.pdf"
            className="text-white"
          >
            {({ blob, url, loading, error }) =>
              loading ? t("Loading pdf...") : t("Download pdf")
            }
          </PDFDownloadLink>
        </Button>
        {/* <PDFViewer>
          <MyDocument />
        </PDFViewer> */}
      </div>
      <div>
      </div>
    </>
  );
}