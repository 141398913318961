import { faUpload, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect , useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import joi from 'joi';
import { constantsErrorMessage, editSuccessMessage, validationErrorMessage } from '../../constants/global';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from 'firebase/storage';
import { enqueueSnackbar } from 'notistack';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { customAlphabet } from 'nanoid'

export default function Settings({theme, decodeToken}) {

    const {showInvalidFeedback, getData, responseApi, getSlug} = useApi();

    const location = useLocation()
    const {t, i18n} = useTranslation()

    const [uploadedLogoUrl, setUploadedLogoUrl] = useState("");
    const [type, setType] = useState('');
    const [file, setFile] = useState();
    const [organizationData, setOrganizationData] = useState({
      orgName : '',
      orgPhone : '',
      orgAddress : '',
      orgLogo : '',
      orgLang : '',
    });


    function handleChangeImage(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    function handleImageDelete(imageSrc) {
        const imageName = imageSrc.split("%2F").reverse()[0].split("?")[0];
        // Create a reference to the file to delete
        const storage = getStorage();
        const slug = getSlug();
        const imageRef = ref(storage, `orgLogos/${slug}/${imageName}`);

        // Delete the file
        deleteObject(imageRef).then(() => {
        // File deleted successfully
        console.log("File deleted successfully");
        }).catch((error) => {
         // Uh-oh, an error occurred!
            console.log("an error occurred!", error);
        });
        const myOrgData = {...organizationData};
        myOrgData.orgLogo = "";
        console.log(myOrgData);
        setOrganizationData(myOrgData)
    }

    const handleImageUpload = async (images) => {
        try {
            if (organizationData.orgLogo) {
                handleImageDelete(organizationData.orgLogo);
            }
            const file = images.target.files[0];
            console.log(file);

            const slug = getSlug();
            const storage = getStorage();
            const storageRef = ref(storage, `orgLogos/${slug}`); //orgLogos is the folder we will upload org logo to it

            if (file.size <= 5242880) {
                const image = file;
                const nanoid = customAlphabet('1234567890abcdefghtklmnoqzx', 16);
                const imageName = nanoid();
                const imageRef = ref(storageRef, imageName);
                await uploadBytes(imageRef, image);
                const downloadURL = await getDownloadURL(imageRef);
                // Store the downloadURL in your database
                console.log('Download URL:', downloadURL.split('?')[0] + "?alt=media");
                let uploadedMediaUrl = downloadURL.split('?')[0] + "?alt=media";
                handleChange('', "orgLogo", uploadedMediaUrl);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function handleChange(e, id, newValue) {
        // deep copy
        let myData;
        myData = {...organizationData};
            // update data
        if (id === "orgLogo" || id === "orgPhone" ) {
            console.log("eehhh");
            myData[id] = newValue;
        } else if (id === "orgLang") {
            myData[id] = e.target.value;
        } else {
            myData[e.target.id] = e.target.value;
        }
        // set new data
        console.log(myData);
        setOrganizationData(myData);
    }

    function clearInputsDataRow() {
        setOrganizationData({
            orgName : '',
            orgPhone : '',
            orgAddress : '',
            orgLogo : '',
            orgLang : '',
        })
    }

    function validationReqData(newData) {
        const schema = joi.object().required().keys({
            orgName: joi.string().min(2).max(20),
            orgPhone: joi.string().length(13),
            orgAddress: joi.string(),
            orgLogo: joi.string().min(0),
            // orgType: joi.string(),
            orgLang: joi.string(),
        });
        return  schema.validate(newData, {abortEarly : false})
    }
    
    async function editOrganizationData() {
    const newData = {
        orgName : organizationData.orgName,
        orgPhone : organizationData.orgPhone,
        orgAddress : organizationData.orgAddress,
        orgLogo : organizationData.orgLogo,
        orgLang : organizationData.orgLang,
        }

    const decoded = decodeToken();
    const validationData = validationReqData(newData);
    console.log(validationData);
    if (validationData.error) {
    enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(validationData.error.details);
        return 0;
    }else {
        const res = await responseApi('put', newData, "organization", decoded.orgId );
        if (res.message === "success") {
        enqueueSnackbar(<>{t(editSuccessMessage)}</>, {variant : "success"});
        getData(setOrganizationData, "organization");
        clearInputsDataRow();
        }else if(res.message === "validation error") {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(validationErrorMessage)}</>, {variant : "error"});
        showInvalidFeedback(res.validationError[0].details);
        }else {
        enqueueSnackbar(<>{t(constantsErrorMessage)}<br/>{t(res.message)}</>, {variant : "error"});
        }
    }
    showInvalidFeedback([]);
    }

    useEffect(() => {
        // add title page
        document.title = t("Settings");
        // get data for table
        getData(setOrganizationData, "organization");
    }, []);
    
  return (
    <>
    <section className='bg-babyblue-50 big-card settings-big-card container-fluid'>
        <div className="header-card d-flex justify-content-between align-items-center">
            <div className="header-title">
            <h2 className='fw-bolder'>{t("Settings")}</h2>
            </div>
        </div>
        <ThemeProvider theme={theme}>
            <div className='info-cards-settings mx-3'>
                {organizationData ?<div className='d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center  px-4'>
                    <div className='inputs-info-settings'>
                        <ul className='w-75 mx-sm-0 mx-auto'>
                            <li className='my-3'>
                                <div>
                                    <TextField id='orgName' className='textfield-input' label={t("company Name")} variant="standard" value={organizationData?.orgName} onChange={handleChange}  type="text" />
                                </div>
                                <div className="text-helper-invalid d-none">{t("Please enter the company name.")}</div>
                            </li>
                            <li className='my-3'>
                                <div>
                                    <TextField id='orgAddress' className='textfield-input' label={t("company Address")} value={organizationData?.orgAddress} onChange={handleChange} variant="standard"  type="text" />
                                </div>
                                <div className="text-helper-invalid d-none">{t("Please enter the company address.")}</div>
                            </li>
                            <li className='my-3'>
                                <div>
                                    {/* <TextField id='orgPhone' className='textfield-input' label="رقم التليفون الرئيسي" value={organizationData?.orgPhone} onChange={handleChange} variant="standard"  type="text" /> */}
                                    <MuiTelInput defaultCountry='EG' dir='ltr' MenuProps={{dir : "ltr",}} disableFormatting id='orgPhone' className='textfield-input' label={t("Main Phone Number")} value={organizationData?.orgPhone} onChange={(newValue) => handleChange('', "orgPhone", newValue)} variant="standard" type="tel"/>
                                </div>
                                <div className="text-helper-invalid d-none">{t("Please enter the main phone number of the company along with the country code. Maximum 13 digits.")}</div>
                            </li>
                            {/* <li className='my-3'>
                                <div>
                                    <FormControl className='textfield-input' variant="standard" >
                                        <InputLabel id="demo-simple-select-standard-label">{t("Type")}</InputLabel>
                                            <Select
                                                MenuProps={{ disableScrollLock: true }}
                                                labelId="demo-simple-select-standard-label"
                                                id="orgType"
                                                value={organizationData?.orgType}
                                                onChange={(e) => handleChange(e, "orgType")}
                                            >
                                                <MenuItem value={"Sole company"}>{t("Sole company")}</MenuItem>
                                                <MenuItem value={"Real Estate Investment Company"}>{t("Real Estate Investment Company")}</MenuItem>
                                            </Select>
                                    </FormControl>
                                </div>
                                <div className="text-helper-invalid d-none">{t("Please choose the appropriate company type.")}</div>
                            </li> */}
                            <li className='my-3'>
                                <div>
                                    <FormControl className='textfield-input' variant="standard" >
                                        <InputLabel id="demo-simple-select-standard-label">{t("Language")}</InputLabel>
                                            <Select
                                                MenuProps={{ disableScrollLock: true }}
                                                labelId="demo-simple-select-standard-label"
                                                id="orgLang"
                                                value={organizationData?.orgLang}
                                                onChange={(e) => handleChange(e, "orgLang")}
                                            >
                                                <MenuItem value={"en"}>{t("en")}</MenuItem>
                                                <MenuItem value={"ar"}>{t("ar")}</MenuItem>
                                            </Select>
                                    </FormControl>
                                </div>
                                <div className="text-helper-invalid d-none">{t("Please choose language")}</div>
                            </li>
                        </ul>
                        {/* <Skeleton variant="rounded" width={"100%"} height={"300px"} style={{borderRadius : "10px"}}/> */}
                    </div>
                    <div className='image text-center'>
                        <div className='profile-image mx-auto'>
                            {organizationData?.orgLogo?.length ? <img className={`w-100 ${organizationData?.orgLogo.length ? "h-100" : ''}  border-2` } src={organizationData.orgLogo} alt='logo' /> : <></>}
                            <div className='cursor-pointer'>
                                <div>
                                    <input id='orgLogo' className='d-none'  type="file" accept="image/*"  onChange={(e) => {handleImageUpload(e)}}/>
                                    <label htmlFor="orgLogo" className='cursor-pointer'><FontAwesomeIcon className='icon-upload-image-profile' icon={faUpload} /></label>
                                </div>
                            </div>
                            { organizationData?.orgLogo?.length ? <div className='icon-xmark-image-profile cursor-pointer' onClick={() => handleImageDelete(organizationData.orgLogo)}>
                                <FontAwesomeIcon icon={faXmark}  />
                            </div> : <></>}
                        </div>
                    </div>
                </div> : <div className='d-flex justify-content-between align-items-center px-4'>
                    <div className='w-50 my-3'>
                        
                        <Skeleton variant="rounded" width={"100%"} height={"300px"} style={{borderRadius : "10px"}}/>
                    </div>

                    <div className='image'>
                        <div className='profile-image' style={{backgroundColor : "transparent"}}>
                            <Skeleton variant="rounded" width={"100%"} height={"100%"} style={{borderRadius : "20px"}}/>
                        </div>
                    </div>
                </div>}
            </div>
        </ThemeProvider>
        
        <div className='text-start mt-2'>
            <Button variant="contained" className='btn-myPrimary  m-3 my-5' onClick={editOrganizationData}>{t("Save Changes")}</Button>
        </div>

    </section>
  </>
  )
}
