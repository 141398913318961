import React, { useEffect, useLayoutEffect, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import moment from 'moment';

export default function CalenderChart({requests, units, dateRange}) {
  const [requestsByUnit, setRequestsByUnit] = useState([])
//   const requests = [
//     {
//         "reqFrom": "2023-05-31T12:00:00.000Z",
//         "reqTo": "2023-06-03T12:00:00.000Z",
//         "unit": {
//             "unitCode": "33333"
//         }
//     },
//     {
//       "reqFrom": "2023-06-03T12:00:00.000Z",
//       "reqTo": "2023-06-07T12:00:00.000Z",
//       "unit": {
//           "unitCode": "33333"
//       }
//   },
//   {
//     "reqFrom": "2023-06-07T12:00:00.000Z",
//     "reqTo": "2023-06-11T12:00:00.000Z",
//     "unit": {
//         "unitCode": "33333"
//     }
// },
//     {
//         "reqFrom": "2023-05-31T12:00:00.000Z",
//         "reqTo": "2023-06-02T12:00:00.000Z",
//         "unit": {
//             "unitCode": "44411"
//         }
//     },
//     {
//         "reqFrom": "2023-06-14T11:53:52.000Z",
//         "reqTo": "2023-06-17T11:53:52.000Z",
//         "unit": {
//             "unitCode": "33311"
//         }
//     },
//     {
//         "reqFrom": "2023-06-17T13:51:33.000Z",
//         "reqTo": "2023-06-19T13:51:33.000Z",
//         "unit": {
//             "unitCode": "33311"
//         }
//     },
//     {
//         "reqFrom": "2023-06-19T14:10:54.000Z",
//         "reqTo": "2023-06-22T14:10:54.000Z",
//         "unit": {
//             "unitCode": "33311"
//         }
//     }
//     ]

    function collectObjectsByUnitCode() {
      const collectedObjects = [];
      
      for (const request of requests) {
        const unitCode = request.unit.unitCode;
        
        const existingObject = collectedObjects.find(obj => obj.unit.unitCode === unitCode);
        if (existingObject) {
          existingObject.requests.push(request);
        } else {
          collectedObjects.push({
            unit: {
              unitCode: unitCode
            },
            requests: [request]
          });
        }
      }
      return collectedObjects;
    }
    
    
    

    useEffect(() => {
      console.log(requests);
      if (requests) {
        const collectedRequestsByUnit = collectObjectsByUnitCode();
        console.log(collectedRequestsByUnit);
        setRequestsByUnit(collectedRequestsByUnit)  
      }
    }, [requests])
    

  useLayoutEffect(() => {
    if (requestsByUnit) {
    ////("hii");
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("chartdiv");
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX", "openValueX"]
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

      

      const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fontFamily:"'Cairo', sans-serif",
      direction: 'ltr'
    });
    root._logo.dispose();

    root.setThemes([
      myTheme
    ]);




        // root.defaultTheme.rule("ColorSet").set("colors", [
        //   am5.color(0x095256),
        //   am5.color(0x087f8c),
        //   am5.color(0x5aaa95),
        //   am5.color(0x86a873),
        //   am5.color(0xbb9f06)
        // ]);


    chart.get("colors").set("colors", [
      am5.color("#074acc"),
    ]);

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }))

    var colors = chart.get("colors");
    legend.itemContainers.template.setAll({
      reverseChildren : true,
    })


    let data = []
    requestsByUnit.forEach((ele, i) => {
      ele.requests.forEach((request, z) => {
        data.push({
          category : request.unit.unitCode,
          start : moment(request.reqFrom).subtract(moment().utcOffset(), "minute").valueOf(),
          end : moment(request.reqTo).subtract(moment().utcOffset(), "minute").valueOf(),
          columnSettings: {
              fill: am5.Color.brighten(colors.getIndex(0), z / 3),
          },
          code: request.reqCode,
        })
      });
    
  })
  console.log(data);
      // Data
    // var data = [ {
    //   category: "Module #1",
    //   start: new Date(2016, 0, 16).getTime(),
    //   end: new Date(2016, 0, 27).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(0), 0.4)
    //   },
    //   task: "Producing specifications"
    // },
    // {
    //   category: "Module #1",
    //   start: new Date(2016, 0, 1).getTime(),
    //   end: new Date(2016, 0, 14).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(0), 0)
    //   },
    //   task: "Gathering requirements"
    // }, {
    //   category: "Module #1",
    //   start: new Date(2016, 1, 5).getTime(),
    //   end: new Date(2016, 3, 18).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(0), 0.8)
    //   },
    //   task: "Development"
    // }, {
    //   category: "Module #1",
    //   start: new Date(2016, 3, 18).getTime(),
    //   end: new Date(2016, 3, 30).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(0), 1.2)
    //   },
    //   task: "Testing and QA"
    // }, {
    //   category: "Module #2",
    //   start: new Date(2016, 0, 8).getTime(),
    //   end: new Date(2016, 0, 10).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(2), 0)
    //   },
    //   task: "Gathering requirements"
    // }, {
    //   category: "Module #2",
    //   start: new Date(2016, 0, 12).getTime(),
    //   end: new Date(2016, 0, 15).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(2), 0.4)
    //   },
    //   task: "Producing specifications"
    // }, {
    //   category: "Module #2",
    //   start: new Date(2016, 0, 16).getTime(),
    //   end: new Date(2016, 1, 5).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(2), 0.8)
    //   },
    //   task: "Development"
    // }, {
    //   category: "Module #2",
    //   start: new Date(2016, 1, 10).getTime(),
    //   end: new Date(2016, 1, 18).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(2), 1.2)
    //   },
    //   task: "Testing and QA"
    // }, {
    //   category: "Module #3",
    //   start: new Date(2016, 0, 2).getTime(),
    //   end: new Date(2016, 0, 8).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(4), 0)
    //   },
    //   task: "Gathering requirements"
    // }, {
    //   category: "Module #3",
    //   start: new Date(2016, 0, 8).getTime(),
    //   end: new Date(2016, 0, 16).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(4), 0.4)
    //   },
    //   task: "Producing specifications"
    // }, {
    //   category: "Module #3",
    //   start: new Date(2016, 0, 19).getTime(),
    //   end: new Date(2016, 2, 1).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(4), 0.8)
    //   },
    //   task: "Development"
    // }, {
    //   category: "Module #3",
    //   start: new Date(2016, 2, 12).getTime(),
    //   end: new Date(2016, 3, 5).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(4), 1.2)
    //   },
    //   task: "Testing and QA"
    // }, {
    //   category: "Module #4",
    //   start: new Date(2016, 0, 1).getTime(),
    //   end: new Date(2016, 0, 19).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(6), 0)
    //   },
    //   task: "Gathering requirements"
    // }, {
    //   category: "Module #4",
    //   start: new Date(2016, 0, 19).getTime(),
    //   end: new Date(2016, 1, 3).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(6), 0.4)
    //   },
    //   task: "Producing specifications"
    // }, {
    //   category: "Module #4",
    //   start: new Date(2016, 2, 20).getTime(),
    //   end: new Date(2016, 3, 25).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(6), 0.8)
    //   },
    //   task: "Development"
    // }, {
    //   category: "Module #4",
    //   start: new Date(2016, 3, 27).getTime(),
    //   end: new Date(2016, 4, 15).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(6), 1.2)
    //   },
    //   task: "Testing and QA"
    // }, {
    //   category: "Module #5",
    //   start: new Date(2016, 0, 1).getTime(),
    //   end: new Date(2016, 0, 12).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(8), 0)
    //   },
    //   task: "Gathering requirements"
    // }, {
    //   category: "Module #5",
    //   start: new Date(2016, 0, 12).getTime(),
    //   end: new Date(2016, 0, 19).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(8), 0.4)
    //   },
    //   task: "Producing specifications"
    // }, {
    //   category: "Module #5",
    //   start: new Date(2016, 0, 19).getTime(),
    //   end: new Date(2016, 2, 1).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(8), 0.8)
    //   },
    //   task: "Development"
    // }, {
    //   category: "Module #5",
    //   start: new Date(2016, 2, 8).getTime(),
    //   end: new Date(2016, 2, 30).getTime(),
    //   columnSettings: {
    //     fill: am5.Color.brighten(colors.getIndex(8), 1.2)
    //   },
    //   task: "Testing and QA"
    // }];

  

    // // Add cursor
    // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    //     behavior: "zoomX"
    // }));
    // cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance : 20
    });
    yRenderer.grid.template.set("location", 1);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    const category = []

    units?.map((ele) => category.push({category : ele.unitCode}) )

    // yAxis.data.setAll([
    //   { category: "Module #1" },
    //   { category: "Module #2" },
    //   { category: "Module #3" },
    //   { category: "Module #4" },
    //   { category: "Module #5" }
    // ]);
    yAxis.data.setAll(category);
    console.log(dateRange.startDate.subtract(moment().utcOffset(), "minute"),dateRange.endDate.subtract(moment().utcOffset(), "minute"));
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1, minGridDistance: 100 }),
        min : dateRange.startDate.valueOf(),
        max : dateRange.endDate.valueOf(),
        strictMinMax : true
      })
    );
    // xAxis.zoomToDates(new Date(2023, 4, 29), new Date(2023, 5, 10)); // for range date

    // xAxis.onPrivate("selectionMin", function(value, target) {
    //   var start = new Date("5/1/2023");
    //   console.log("Start date changed:", start);
    // });
    
    // xAxis.onPrivate("selectionMax", function(value, target) {
    //   var end = new Date("6/1/2023");
    //   console.log("End date changed:", end);
    // });


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      openValueXField: "start",
      valueXField: "end",
      categoryYField: "category",
      sequencedInterpolation: true,
      // tooltip: am5.Tooltip.new(root, {
      //   keepTargetHover : true
      // })
      
    }));

    series.columns.template.setAll({
      templateField: "columnSettings",
      strokeOpacity: 0, 
      tooltipText: "{code} : \n[bold]{openValueX}[/] - [bold]{valueX}[/]",
    });

    // series.events.once("datavalidated", function(ev) {
    //   ev.target.get("xAxis").zoomToDates(new Date(2021, 0, 5), new Date(2022, 0, 5));
    // });

    series.data.setAll(data);
    // series.set("fill", am5.color(0xff0000));

    // root.defaultTheme.rule("ColorSet").set("colors", [
    //   am5.color(0x095256),
    //   am5.color(0x087f8c),
    //   am5.color(0x5aaa95),
    //   am5.color(0x86a873),
    //   am5.color(0xbb9f06)
    // ]);


    // chart.get("colors").set("colors", [
    //   am5.color("#074acc"),
    //   am5.color("#FF9E01"),
    //   am5.color("#0FAC83"),
    //   am5.color("#CC3F0C"),
    //   am5.color("#18BECB"),
    // ]);

    // Add scrollbars
  chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();
    chart.appear(1000, 100);

    
  // // Make stuff animate on load
  // // https://www.amcharts.com/docs/v5/concepts/animations/
  // series.appear(1000);

  // series.bullets.push(function() {
  //   return am5.Bullet.new(root, {
  //     locationY: 0,
  //     sprite: am5.Label.new(root, {
  //       text: "{valueY}",
  //       centerY: 0,
  //       centerX: am5.p50,
  //       populateText: true,
  //       fontFamily : "cairo, sans-serif",
  //     })
  //   });
  // });
  // legend.markerRectangles.template.setAll({
  //   cornerRadiusTL: 10,
  //   cornerRadiusTR: 10,
  //   cornerRadiusBL: 10,
  //   cornerRadiusBR: 10,
  // });
  
  // // legend.data.setAll(series.dataItems);
  // legend.data.push(series);
  // }

  //   makeSeries("مدخلات", "costIn");
  //   makeSeries("مصروفات", "costOut");

          // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    //   chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    // }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      if (root) {
          root.dispose();
      }
    }
  }

    
}, [requestsByUnit])

  return (
    <>
    <section>
        <div className="container h-100">
            <div id="chartdiv" style={{width : "100%", height : "75vh"}}></div>
        </div>
    </section>
    </>
  )
}
