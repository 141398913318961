import React from 'react'
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useState } from 'react';
import { useEffect } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ErrorMessage({message, showError, setShowError}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }else if (reason === 'timeout') {
      setShowError(false)
    }

    setOpen(false);
  };
  useEffect(() => {
    if (showError) {
      handleClick();
    }
  }, [showError])
  

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical : "top" , horizontal  : "left"}}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <div className='fw-semibold'>خطأ</div>
          {message}
        </Alert>

      </Snackbar>
    </Stack>
  );
}