import React, { useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { faExpand } from '@fortawesome/free-solid-svg-icons';
// @import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');


export default function CostsItemAnalysis({data, chartsDashContainerStyles}) {

    useLayoutEffect(() => {
      ////(data);
    if (data.length) {
    ////("hii");
    let root = am5.Root.new("chartCostsItemDiv");            
    root.setThemes([
        am5themes_Animated.new(root)
    ]);

    root._logo.dispose();

// Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
var chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "panX",
      layout: root.verticalLayout,
      // arrangeTooltips: false,
    })
  );

  const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fontFamily:"'Cairo', sans-serif",
      direction: 'rtl'
    });
    root.setThemes([
      myTheme
    ]);
  
  // Use only absolute numbers
  chart.getNumberFormatter().set("numberFormat", "#.#s");
  
  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  var legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
    })
  );
  legend.itemContainers.template.setAll({
    reverseChildren : true,
  })

  var xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: "transItem",
      tooltip: am5.Tooltip.new(root, {}),
      renderer: am5xy.AxisRendererX.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance : 30
      })
    })
  );
  xAxis.get("renderer").labels.template.setAll({
    oversizedBehavior: "wrap",
    maxWidth: 100,
    textAlign: "center",
    rotation : -90
  })
  
  xAxis.data.setAll(data);
  
  var yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    })
  );
  
  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function createSeries(name,field, labelCenterY, pointerOrientation, rangeValue) {
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: name,
        yAxis: yAxis,
        xAxis: xAxis,
        valueYField: field,
        categoryXField: "transItem",
        fill : name === "مدخلات" ? am5.color("#074acc") : am5.color("#FF9E01") ,
        sequencedInterpolation: true,
        clustered: false,
        // legendLabelText : "{categoryX}",
        // legendValueText : "{valueY}",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: pointerOrientation,
          labelText: "{name}: {valueY}",
        })
      })
    );
    ////(pointerOrientation);
  
    series.columns.template.setAll({
      tooltipText: "{name}: {valueY}",
      height: am5.p100,
      strokeOpacity: 0,
      cornerRadiusTL: 20,
      cornerRadiusTR: 20,
      tooltipY: 0,
      // fillOpacity: 0.8,
      fill : field === "costIn" ? am5.color("#074acc") : am5.color("#FF9E01"),
    });
  
    // series.bullets.push(function() {
    //   return am5.Bullet.new(root, {
    //     locationX: 1,
    //     locationY: 2,
    //     sprite: am5.Label.new(root, {
    //       centerY: am5.p50,
    //       text: "{valueY}",
    //       populateText: true,
    //       centerX: labelCenterY
    //     })
    //   });
    // });
  
    series.data.setAll(data);
    series.appear();
  
    var rangeDataItem = xAxis.makeDataItem({
      value: rangeValue
    });
    xAxis.createAxisRange(rangeDataItem);
    rangeDataItem.get("grid").setAll({
      strokeOpacity: 1,
      stroke: series.get("stroke")
    });
  
    var label = rangeDataItem.get("label");
    label.setAll({
      text: field.toUpperCase(),
      fontSize: "1.1em",
      fill: series.get("stroke"),
      paddingTop: 10,
      isMeasured: false,
      centerX: labelCenterY
    });
    label.adapters.add("dy", function() {
      return chart.plotContainer.height();
    });

  
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10
    });
    
    legend.data.push(series);
  
    return series;
  }
  
  createSeries("مدخلات","costIn", am5.p100, "down", 1);
  createSeries("مصروفات","costOut", 0, "up", 4);
  
  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

  //  // Add scrollbar
  //   // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  //   chart.set("scrollbarX", am5.Scrollbar.new(root, {
  //       orientation: "horizontal"
  //   }));
  // cursor.lineY.set("forceHidden", false);
  // cursor.lineX.set("forceHidden", true);
  
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
chart.appear(1000, 100);
    return () => {
        if (root) {
            root.dispose();
        }
    }
       
  
        }

    
}, [data])

  return (
    <>
    <section>
        <div className="container h-100" dir='ltr'>
            <div id="chartCostsItemDiv" style={chartsDashContainerStyles}></div>
        </div>
    </section>
    </>
  )
}
