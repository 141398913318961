import React, { useEffect, useState } from 'react'
import { getFirebaseToken, getTokens, onForegroundMessage, onMessageListener } from '../../utils/firebase';
import { enqueueSnackbar } from 'notistack';
// import {initializeApp} from 'firebase/app';
// import firebase, { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyBloI5pzqx5peRFk43T6vNq59svofigSpQ",
//   authDomain: "prokfy-admin.firebaseapp.com",
//   projectId: "prokfy-admin",
//   storageBucket: "prokfy-admin.appspot.com",
//   messagingSenderId: "267007780437",
//   appId: "1:267007780437:web:907cc1dba7a8ac6fd896dc",
//   measurementId: "G-RSD6J7Q1BJ"
// };

// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

export default function SendNotifications() {

  const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});


  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          console.log("firebaseToken", firebaseToken);
          setTokenFound(true)
          setShowNotificationBanner(false);
        }
      })
      .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
  }

  useEffect(() => {
    handleGetFirebaseToken()
    onForegroundMessage()
      .then((payload) => {
        console.log('Received foreground message: ', payload);
        const { notification: { title, body } } = payload;
        pushNotification(title, body)
      })
      .catch(err => console.log('An error occured while retrieving foreground message. ', err));
  }, []);



  function pushNotification(title, body) {
    enqueueSnackbar(<>{title}<br/>{body}</>, {variant : "info"});
  }


  // onMessageListener().then(payload => {
  //   pushNotification()
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));


  // useEffect(() => {
  //   getTokens(setTokenFound);
  // }, [])
  

  // Request permission and get the FCM token
  // const getNotificationPermission = async () => {
  //   try {
  //     // const messaging = messaging();
  //     await messaging.requestPermission();
  //     const token = await messaging.getToken();
  //     console.log('FCM token:', token);
  //     // Send this token to your server for sending notifications to this device.
  //   } catch (error) {
  //     console.error('Error getting FCM token:', error);
  //   }
  // };

  // useEffect(() => {
  //   getNotificationPermission();
  // }, []);




// inside the jsx being returned:


    return (
      <>
      {/* {isTokenFound ? <div>Notification permission enabled 👍🏻 </div> : <div>Need notification permission ❗️ </div>}
      
      <div className="App">
          <button onClick={() => pushNotification() }>Show Toast</button>
      </div> */}
      </>
    )
}
