import { faMagnifyingGlass, faPenToSquare, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, ThemeProvider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AddTasks from '../AddTask/AddTask';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Button from '@mui/material/Button';
import { useApi } from '../Store/ApiContext';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import ConfirmedMessage from '../ConfirmedMessage/ConfirmedMessage';
import axios from 'axios';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';

export default function Tasks({theme, togglePopUp, popupClose, baseUrl, tableContainerStyle}) {

  const {getData, confirmRemove, showSuccessRemove, setShowSuccessRemove, successMessageRemove, showErrorRemove, setShowErrorRemove, errorMessageRemove, quickEditTable} = useApi();
  const {t, i18n} = useTranslation()

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const filterTextBox = useRef(); //  input text box

  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  // const [status, setStatus] = useState('جاري');
  const [numberOfRows, setNumberOfRows] = useState(20)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('');
  const [idTaskRow, setIdTaskRow] = useState('');
  const [taskDataRow, setTaskDataRow] = useState(''); // inputs owner for edit
  const [editStatus, setEditStatus] = useState({
    id : '',
    taskStatus : "",
  })



  // const cellCellEditorParams = (params) => {
  //   const selectedCountry = 'USA';
  //   const allowedCities = countyToCityMap(selectedCountry);
  //   return {
  //     values: allowedCities,
  //     formatValue: (value) => `${value} (${selectedCountry})`,
  //   };
  // };

  // const countyToCityMap = (match) => {
  //   const map = {
  //     Ireland: ['Dublin', 'Cork', 'Galway'],
  //     USA: ['New York', 'Los Angeles', 'Chicago', 'Houston'],
  //   };
  //   return map[match];
  // };
  

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {field: 'taskCode', headerName : t("Task Code") , width:100},
    {field: 'taskType.taskTypeName', headerName : t("Task Type"), width:150},
    
    {field: 'taskDueDate', headerName : t("Execution Date"), width:120, 
    cellRenderer: function(params) {
     return moment(params.data.taskDueDate).format("L")
   }},
    {field: 'unit.unitCode', headerName : t("Unit"), width:300, 
    cellRenderer: function(params) {
     return params.data.unit.unitCode + " - " + params.data.unit.unitName 
   }},
   {field: 'taskTotalCost', headerName : t("Cost"), width:100},
   {
    field: 'taskStatus',
    headerName : t("Status"), width:100,
    editable : true,
    cellEditor: 'agSelectCellEditor',
    cellRenderer : params => t(params.data.taskStatus),
    cellEditorParams: {
      values: [t("Pending") ,t("In Progress"), t("Completed"), t("Canceled") ],
    },
  },
  //   { headerName : "الحالة",
  //   cellRendererParams: {
  //     sparklineOptions: {
  //       type: 'column',
  //       fill: '#fac858',
  //       highlightStyle: {
  //         stroke: '#fac858',
  //       },
  //       padding: {
  //         top: 10,
  //         bottom: 10,
  //       },
  //       label: {
  //         enabled: true,
  //         placement: 'outsideEnd',
  //       },
  //     },
  //   },
  //   cellRenderer: function(params) {
  //     return <div className=''>
  //       <ToggleButtonGroup
  //         size='small'
  //         value={params.data.taskStatus}
  //         exclusive
  //         aria-label="Platform"
  //       >
  //         <ToggleButton color='mediumGray' value="Pending">Pending</ToggleButton>
  //         <ToggleButton color='secondary' value="In Progress">In Progress</ToggleButton>
  //         <ToggleButton color='third' value="تم">تم</ToggleButton>
  //         <ToggleButton color='fourth' value="Canceled">Canceled</ToggleButton>
  //       </ToggleButtonGroup>
  //     </div>
  //   },
  //   cellEditor: 'agSelectCellEditor',
  //   cellEditorPopup: true,
    
  //   cellEditorParams: {
  //     cellHeight: 50,
  //     values: ['تم', 'Canceled', "In Progress"],
  //     cellRenderer: function(params) {
  //       return value => `${value}`
  //     },
  //   },
  // },
  {field: 'createdBy.userName', headerName : t("Task Provider"), width:200},
    {field: 'price', headerName : t("Last Update"), width:200, 
    cellRenderer: function(params) {
      return moment(params.data.updatedAt).utc(true).format("L - LTS a");
    }},
    {field: "", headerName : t("Settings"), width:100, 
     cellRenderer: function(params) {
      return <span> 
        <FontAwesomeIcon className='mx-2 icon-settings-table cursor-pointer fs-6' onClick={() => {togglePopUp("add-popup"); onSelectionChanged(); window.history.replaceState("","Title", "#edit-task");}} icon={faPenToSquare} />
        <FontAwesomeIcon className='icon-settings-table cursor-pointer fs-6' icon={faTrashCan} onClick={() => confirmRemove(params.data._id, setIdTaskRow)} />
      </span>
    }},
  ]);

    // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    // flex: 1,
    minWidth: 100,
    sortable: true,
    // cellEditorPopup: true,
    // filter: true,
    resizable: true,
  }));
  

  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
  // ////('cellClicked', event);
  }, []);


    // selection rows
    const onSelectionChanged = useCallback(() => {
      let selectedRows = gridRef.current.api.getSelectedRows();
      ////(selectedRows);
      setTaskDataRow(selectedRows[0])
    }, []);

    // filter table
    const onFilterTextBoxChanged = useCallback((e) => {
      gridRef.current.api.setQuickFilter(
        e.target.value
      );
    }, []);

                  // edit status task
  //   async function editStatusTask(method, dataUser, endPoint, id) {
  //   const {data} = await axios({
  //     url : `${baseUrl}${endPoint}/editStatusTask/${id}` ,
  //     method,
  //     data : dataUser,
  //     headers : {
  //       'Content-Type': 'application/json', 
  //       "authorization" : `Bearer ${localStorage.getItem("token")}`,
  //     }
  //   })
  //   ////(data);
  //   if (data) {
  //     return data;
  //   }
  // }



  const handleChangeOfNumbers = (event) => {
    setNumberOfRows(event.target.value);
  };

  function handleSaveStatusEnglish(taskStatus) {
    let newTaskStatus
    switch (taskStatus) {
      case t("Pending"):
        newTaskStatus = "Pending"
        break;
      case t("In Progress"):
        newTaskStatus = "In Progress"
        break;
      case t("Completed"):
        newTaskStatus = "Completed"
        break;
      case t("Canceled"):
        newTaskStatus = "Canceled"
        break;        

      default:
        break;
    }
    return {taskStatus : newTaskStatus}
  }

  const saveStatus = (id, newStatus) => {
    const myData = {...editStatus};
    myData.taskStatus = newStatus;
    setEditStatus(myData);
    quickEditTable('task', newStatus, id);
  };

  const onCellValueChanged = useCallback((params) => {
    let newData = {}
    newData[params.column.getId()] = params.newValue;
    console.log(newData);
    if (params.column.getId() === 'taskStatus') {
      if (i18n.language !== "en") {
        let newTaskStatus =  handleSaveStatusEnglish(newData.taskStatus)
        saveStatus(params.data._id, newTaskStatus)
      } else {
        saveStatus(params.data._id, newData)
      }
    }
  }, []);

  useEffect(() => {
    // add title page
    document.title = t("Tasks");
    // get data for table
    getData(setRowData, "task");
  }, []);


  return (
    <>
    <section className='bg-babyblue-50 big-card container-fluid'>
      <div className="header-card d-flex justify-content-between align-items-center">
        <div className="header-title">
          <h2 className='fw-bolder'>{t("Tasks")}</h2>
        </div>
        <div onClick={() => {togglePopUp("add-popup"); window.history.replaceState("","Title", "#add-task");}}>
          <Button variant="contained" className='btn-request'><span className='me-2 '><FontAwesomeIcon icon={faPlus} /></span>{t("Add Task")}</Button>
        </div>
      </div>
           
      {rowData ?<div className='table-unites pt-4 pb-3 px-2 mx-3'>

                  {/* Search table and inputs number of rows */}
        <div className="header-table mb-3 d-flex justify-content-between align-items-center">
          <ThemeProvider theme={theme}>
            <div className="inputs-number-rows">
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Entries No.")}</InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numberOfRows}
                  label={t("Entries No.")}
                  onChange={handleChangeOfNumbers}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                  <MenuItem value={80}>80</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='header-table-search'>
              <TextField id="filter-text-box" label={t("Search...")} variant="standard" ref={filterTextBox} onInput={onFilterTextBoxChanged}  />
            </div>
          </ThemeProvider>
        </div>

        {/* Example using Grid's API */}

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={tableContainerStyle}>
          <ThemeProvider theme={theme}>

              <AgGridReact
                enableRtl={i18n.language === 'ar' ? true : false}
                ref={gridRef} // Ref for accessing Grid's API

                rowData={rowData} // Row Data for Rows

                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties

                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='multiple' // Options - allows click selection of rows

                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                paginationPageSize={numberOfRows}
                pagination={true}
                
                onCellValueChanged={onCellValueChanged}
                rowHeight={30}
              />
            </ThemeProvider>
         </div>

      </div> : <div className='pt-4 pb-3 px-2 mx-3'>
            <div className='d-flex justify-content-between mb-2'>
              <Skeleton variant="rounded" width={"150px"} height={"50px"} style={{borderRadius : "10px"}} />
              <Skeleton variant="rounded" width={"200px"} height={"50px"} style={{borderRadius : "10px"}}/>
            </div>
            <Skeleton variant="rounded" width={"100%"} height={"70vh"} style={{borderRadius : "10px"}}/>
          </div>}


    </section>
    {/* popup */}
    <div className='add-popup'>
      <AddTasks customTheme={theme} togglePopUp={togglePopUp} popupClose={popupClose} taskDataRow={taskDataRow}  setTaskDataRow={setTaskDataRow} setRowData={setRowData} setSuccessMessage={setSuccessMessage} setShowSuccess={setShowSuccess}/>
    </div>

        {/* Success Message */}
    <div>
      <ThemeProvider theme={theme}>
        <SuccessMessage message={successMessageRemove} showSuccess={showSuccessRemove} setShowSuccess={setShowSuccessRemove} />
        <ErrorMessage message={errorMessageRemove} showError={showErrorRemove} setShowError={setShowErrorRemove}/>
        <SuccessMessage message={successMessage} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      </ThemeProvider>
    </div>

    <div className='add-confirm'>
      <ConfirmedMessage customTheme={theme} togglePopUp={togglePopUp} popupClose={popupClose} id={idTaskRow} setRowData={setRowData} endPoint={"task"}/>
    </div>
  
  </>
  )
}
